import { API, apiCall } from "../../utils/APIFunctions";

export function getCompanySettings(companyId) {
  return apiCall(`${API}/companies/${companyId}/settings`,"get");
}
export function updateCompanySettings(params) {
  return apiCall(`${API}/companies/${params.companyId}/settings`,"put", JSON.stringify(params));
}

export function getCompanyIntegrations(companyId) {
  return apiCall(`${API}/companies/${companyId}/integrations`,"GET");
}
export function enableIntegrationsApi(companyId) {
  return apiCall(`${API}/companies/${companyId}/integrations/api/enable`,"POST");
}
export function disableIntegrationsApi(companyId) {
  return apiCall(`${API}/companies/${companyId}/integrations/api/disable`,"POST");
}

export function enableIntegrationsTampuuri(companyId) {
  return apiCall(`${API}/companies/${companyId}/integrations/tampuuri/enable`,"POST");
}
export function disableIntegrationsTampuuri(companyId) {
  return apiCall(`${API}/companies/${companyId}/integrations/tampuuri/disable`,"POST");
}
export function updateIntegrationsTampuuri(params) {
  return apiCall(`${API}/companies/${params.companyId}/integrations/tampuuri`,"PUT", JSON.stringify(params.settings));
}
export function startIntegrationsTampuuriSync(companyId) {
  return apiCall(`${API}/companies/${companyId}/integrations/tampuuri/sync`,"POST");
}