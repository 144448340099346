import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './statisticsAPI';
import {dateHelper} from '../../../utils/dateHelpers';

const initialState = {
  fetchUserDocumentStatisticsStatus: null,
  documentStatisticsData: [],
  measurementsStatisticsData: [],
  documentFilters: [],
  metricFilters: [],
  counts: {
    document: {
      filtered: 0,
      total: 0
    },
    measurement: {
      filtered: 0,
      total: 0
    }
  },
  initialData: {
    userMeasurements:null,
    userDocuments:null,
    documentChart:null,
    metricChart:null,
    documentDatesCount: null,
    metricDatesCount: null
  },
  documentChartTemplateData: null,
  userDocumentStatistics: null,
  metricsChartData: null,
  userMeasurementsStatistics: null,
  dataByUser: {
    measurements: null,
    documents: null
  },
  timeSelectOption: {},
  timeRange: 
  {
    startDateTimestamp: null,
    endDateTimestamp: null
  },
  selectedDocumentData: {
    selectedTemplateIds: [],
    selectedLabelObjects: [],
  },
  documentSelectOption: {value: "all", label: ""},
  documentDatesCount: {},
  metricDatesCount: {},
  selectedChartType: "document",
  chartTypeDateRange:{
    metric: {minDate: null, maxDate: null},
    document: {minDate: null, maxDate: null}
  },
  translations: {
    all: "All",
    severalFilters: "Several filters",
    selectAll: "Select all",
  }
};

export const fetchUserDocumentStatistics = createAsyncThunk(
  'statistics/fetchUserDocumentStatistics',
  async () => {
    const response = await api.fetchUserDocumentStatistics();
    return response.body;
  }
);

export const fetchUserMeasurementsStatistics = createAsyncThunk(
  'statistics/fetchUserMeasurementsStatistics',
  async () => {
    const response = await api.fetchUserMeasurementsStatistics();
    return response.body;
  }
);

const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}`;
};

function addToDocumentsByUser(documentsByUser, user, dataRow) {
  if (!documentsByUser[user]) {
    documentsByUser[user] = {
      docs: [],
      ids: []
    };
  }
  
  if (!documentsByUser[user].ids.includes(dataRow.object_id)) {
    documentsByUser[user].ids.push(dataRow.object_id);
    documentsByUser[user].docs.push(dataRow);
  }
}

function processDocumentRow(dataRow, templateNameCounts, documentsByUser, filterObjects, filteredDocumentsCount, documentDatesCount) {
  
  const { template_name, template_id, created_ts, completed_ts, creator, completer } = dataRow;
  const trimmedTemplateName = template_name.trim();
  const { creatorFilter, documentFilter, timeFilter } = filterObjects;

  const templateData = templateNameCounts[template_id] || (templateNameCounts[template_id] = { count: 0, name: trimmedTemplateName });
  const dateKey = convertTimestampToDate(created_ts);
  const timeFilterMatch = !timeFilter || dateHelper.isInTimeRange(created_ts, timeFilter);
  const creatorFilterMatch = !creatorFilter || creatorFilter.filterValue === creator;

  if (creatorFilterMatch && timeFilterMatch) {
    templateData.count += 1;
  }

  const shouldIncrement =
    (!creatorFilter && !documentFilter && !timeFilter) ||
    (creatorFilterMatch && (!documentFilter || documentFilter.filterValue.includes(template_id.toString())) && timeFilterMatch);
  
  if (shouldIncrement) {
    filteredDocumentsCount++;
    
    if (!documentDatesCount[dateKey]) {
      documentDatesCount[dateKey] = 1;
    } else {
      documentDatesCount[dateKey] += 1;
    }

  }
      
  dataRow.created_date = new Date(created_ts * 1000).getTime();
  dataRow.template_name = trimmedTemplateName;
  dataRow.completed = completed_ts ? 1 : 0;

  if (creator) {
    addToDocumentsByUser(documentsByUser, creator, dataRow);
  }
    
  if (completer) {
    addToDocumentsByUser(documentsByUser, completer, dataRow);
  }

  return filteredDocumentsCount;
}

function processDocumentDataRows(dataRows, filters = []) {
  const templateNameCounts = {};
  const documentsByUser = {};
  const documentDatesCount = {};

  let filteredDocumentsCount = 0;
  const totalDocumentsCount = dataRows.length;

  const { creator: creatorFilter, document: documentFilter, time: timeFilter } = {
    creator: filters.find(filterObj => filterObj.filter === "creator"),
    document: filters.find(filterObj => filterObj.filter === "document"),
    time: filters.find(filterObj => filterObj.filter === "time")
  };

  for (const dataRow of dataRows) {
    filteredDocumentsCount = processDocumentRow(dataRow, templateNameCounts, documentsByUser,{creatorFilter,documentFilter, timeFilter}, filteredDocumentsCount, documentDatesCount);
  }

  const sortedTemplateNameCountsArray = Object.entries(templateNameCounts)
    .map(([template_id, data]) => ({
      template_id,
      template_name: data.name,
      count: data.count
    }))
    .sort((a, b) => b.count - a.count);

  const objectEntries = Object.entries(documentsByUser);

  let userDocumentStatistics;

  if (filters?.length > 0) {

    const matchesCreatorFilterForCreation = (doc, creatorEmail) => 
      !creatorFilter || (creatorFilter.filterValue === creatorEmail && doc.creator === creatorEmail);

    const matchesCreatorFilterForCompletion = (doc, creatorEmail) => 
      !creatorFilter || (creatorFilter.filterValue === doc.creator && doc.completer === creatorEmail);

    const matchesDocumentFilterForCreation = (doc, creatorEmail) => 
      !documentFilter || (documentFilter.filterValue.includes(doc.template_id.toString()) && doc.creator === creatorEmail);

    const matchesDocumentFilterForCompletion = (doc, creatorEmail) => 
      !documentFilter || (documentFilter.filterValue.includes(doc.template_id.toString()) && doc.completer === creatorEmail);

    const matchesTimeFilter = (doc) => 
      !timeFilter || dateHelper.isInTimeRange(doc.created_ts, timeFilter);

    userDocumentStatistics = objectEntries.map(([creatorEmail, documentData]) => {
      const createdDocuments = documentData.docs.reduce((count, doc) => {

        if (matchesCreatorFilterForCreation(doc, creatorEmail) 
            && matchesDocumentFilterForCreation(doc, creatorEmail) 
            && matchesTimeFilter(doc)) {
          return count + 1;
        }
        return count;
      }, 0);
      
      const completedDocuments = documentData.docs.reduce((count, doc) => {

        if (
          matchesCreatorFilterForCompletion(doc, creatorEmail)
          && matchesDocumentFilterForCompletion(doc, creatorEmail)
          && matchesTimeFilter(doc)
        ) {
          return count + (doc.completed ? 1 : 0);
        }
        return count;
      }, 0);
      
      return {
        creatorName: creatorEmail,
        createdDocuments,
        completedDocuments,
        documentsByUser
      };
    });
      
  } else {
    userDocumentStatistics = objectEntries.map(([creatorEmail, documentData]) => {

      const { createdDocuments, completedDocuments } = documentData.docs.reduce((acc, doc) => {
        if (doc.creator === creatorEmail) {
          acc.createdDocuments += 1;
        }
        if (doc.completer === creatorEmail && doc.completed === 1) {
          acc.completedDocuments += 1;
        }
        return acc;
      }, { createdDocuments: 0, completedDocuments: 0 });

      
      return {
        creatorName: creatorEmail,
        createdDocuments,
        completedDocuments
      };
    });
  }

  const sortedUserDocumentStatistics = userDocumentStatistics.slice().sort((a, b) => b.createdDocuments - a.createdDocuments);
  
  return {
    sortedTemplateNameCountsArray,
    sortedUserDocumentStatistics,
    filteredDocumentsCount,
    totalDocumentsCount,
    documentsByUser,
    documentDatesCount
  };
}

function processMetricDataRow(dataRow, metricNameCounts, measurementsByUser,filterObjects ,filteredMeasurementsCount, metricDatesCount) {
  
  const { title, created_ts, completed_ts, creator_id, full_path, metric_id, email } = dataRow;
  const { creatorFilter, metricFilter, timeFilter } = filterObjects;
  const dateKey = convertTimestampToDate(created_ts);
  const metricName = (title + full_path).trim();

  metricNameCounts[metric_id] = metricNameCounts[metric_id] || {
    count: 0,
    name: metricName
  };

  const creatorFilterMatch = !creatorFilter || creatorFilter.filterValue === email;
  const metricFilterMatch = !metricFilter || metricFilter.filterValue.includes(metric_id.toString());
  const timeFilterMatch = !timeFilter || dateHelper.isInTimeRange(created_ts, timeFilter);
  
  if (creatorFilterMatch && timeFilterMatch) {
    metricNameCounts[metric_id].count += 1;
  }
  
  if ((!creatorFilter && !metricFilter && !timeFilter) 
  || (creatorFilterMatch && metricFilterMatch && timeFilterMatch)) {
    filteredMeasurementsCount++;

    if (!metricDatesCount[dateKey]) {
      metricDatesCount[dateKey] = 1;
    } else {
      metricDatesCount[dateKey] += 1;
    }

  }
        
  dataRow.created_date = new Date(created_ts * 1000).getTime();
  dataRow.metric_name = metricName
  dataRow.completed = completed_ts ? 1 : 0;
  
  if (measurementsByUser[creator_id]) {
    measurementsByUser[creator_id].push(dataRow);
  } else {
    measurementsByUser[creator_id] = [dataRow];
  }
  
  return filteredMeasurementsCount;
}

function processMetricDataRows(dataRows, filters = []) {
  const metricNameCounts = {};
  const measurementsByUser = {};
  let filteredMeasurementsCount = 0;
  const totalMeasurementsCount = dataRows.length;
  
  const creatorFilter = filters.find(filterObj => filterObj.filter === "creator");
  const metricFilter = filters.find(filterObj => filterObj.filter === "metric");
  const timeFilter = filters.find(filterObj => filterObj.filter === "time");

  const metricDatesCount = {};
  
  for (const dataRow of dataRows) {
    filteredMeasurementsCount = processMetricDataRow(dataRow, metricNameCounts, measurementsByUser,{creatorFilter, metricFilter, timeFilter},filteredMeasurementsCount, metricDatesCount);
  }

  const sortedMetricNameCountsArray = Object.entries(metricNameCounts)
    .map(([id, data]) => ({
      id,
      metric_name: data.name,
      count: data.count
    }))
    .sort((a, b) => b.count - a.count);

  const objectEntries = Object.entries(measurementsByUser);

  let userMeasurementStatistics;

  function matchesAllFilters(metric, creatorName, creatorFilter, metricFilter, timeFilter) {
    const timeFilterMatch = !timeFilter || dateHelper.isInTimeRange(metric.created_ts, timeFilter);
    const creatorFilterMatch = !creatorFilter || creatorFilter.filterValue === creatorName;
    const metricFilterMatch = !metricFilter || metricFilter.filterValue.includes(metric.metric_id.toString());
    
    return timeFilterMatch && creatorFilterMatch && metricFilterMatch;
  }

  if (filters?.length > 0) {

    userMeasurementStatistics = objectEntries.map(([, metricArray = []]) => {
      if (metricArray.length === 0) return { creatorName: "", totalMeasurements: 0, creatorId: null };
      
      const { email = "", creator_id: creatorId } = metricArray[0];
      const creatorName = (email || "").trim();

      const totalMeasurements = metricArray.reduce((count, metric) => {
        return count + (matchesAllFilters(metric, creatorName, creatorFilter, metricFilter, timeFilter) ? 1 : 0);
      }, 0);

      return {
        creatorName,
        totalMeasurements,
        creatorId
      };
    });
  } else {
    userMeasurementStatistics = objectEntries.map(([, metricArray = []]) => {
      if (metricArray.length === 0) return { creatorName: "", totalMeasurements: 0, creatorId: null };
      
      const { email = "", creator_id: creatorId } = metricArray[0];
      const creatorName = (email || "").trim();

      return {
        creatorName,
        totalMeasurements : metricArray.length,
        creatorId
      };
    });
  }

  const sortedUserMeasurementStatistics = userMeasurementStatistics.slice().sort((a, b) => b.totalMeasurements - a.totalMeasurements);
    
  return {
    sortedMetricNameCountsArray,
    sortedUserMeasurementStatistics,
    filteredMeasurementsCount,
    totalMeasurementsCount,
    measurementsByUser,
    metricDatesCount
  };
}

function updateDocumentStatisticsState(state, filters) {
  const { sortedTemplateNameCountsArray, sortedUserDocumentStatistics, totalDocumentsCount, filteredDocumentsCount, documentDatesCount } = processDocumentDataRows(state.documentStatisticsData, filters);
    
  const documentChartTemplateData = {
    count: sortedTemplateNameCountsArray.map(item => item.count),
    labels: sortedTemplateNameCountsArray.map(item => item.template_name),
    ids: sortedTemplateNameCountsArray.map(item => item.template_id)
  }
  state.documentChartTemplateData = documentChartTemplateData;
  state.userDocumentStatistics = sortedUserDocumentStatistics;
  
  state.counts.document.filtered = filteredDocumentsCount;
  state.counts.document.total = totalDocumentsCount;

  state.documentDatesCount = documentDatesCount;
}

function updateMetricStatisticsState(state,filters) {
  const { sortedMetricNameCountsArray, sortedUserMeasurementStatistics, totalMeasurementsCount, filteredMeasurementsCount, metricDatesCount} = processMetricDataRows(state.measurementsStatisticsData, filters);
    
  const metricsChartData = {
    count: sortedMetricNameCountsArray.map(item => item.count),
    labels: sortedMetricNameCountsArray.map(item => item.metric_name),
    ids: sortedMetricNameCountsArray.map(item => item.id)
  }

  state.metricsChartData = metricsChartData;
  state.userMeasurementsStatistics = sortedUserMeasurementStatistics;

  state.counts.measurement.filtered = filteredMeasurementsCount;
  state.counts.measurement.total = totalMeasurementsCount;

  state.metricDatesCount = metricDatesCount;

}

function updateFilter(stateFilters, filterPayload) {
  const existingFilterIndex = stateFilters.findIndex(filter => filter.filter === filterPayload.filter);
  if (existingFilterIndex !== -1) {
    stateFilters[existingFilterIndex] = filterPayload;
  } else {
    stateFilters.push(filterPayload);
  }
}

function resetTimeState(state) {
  state.timeRange.startDateTimestamp = null;
  state.timeRange.endDateTimestamp = null;
  state.timeSelectOption = { value: "all", label: state.translations.all };
}

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    addDocumentFilter(state, action) {

      updateFilter(state.documentFilters, action.payload);
      if (action.payload.filter === "document") {

        const firstLabelObject = action.payload.labels[0];
        const label = action.payload.filterValue.length > 1 ? state.translations.severalFilters : firstLabelObject.label;

        state.documentSelectOption = {value: firstLabelObject.id, label: label};
      }

      if (action.payload.filter === "time") {
        const { startDateTimestamp, endDateTimestamp } = action.payload.dates;
        state.timeRange.startDateTimestamp = startDateTimestamp;
        state.timeRange.endDateTimestamp = endDateTimestamp;
      }

      updateDocumentStatisticsState(state, state.documentFilters);
    },    
    removeDocumentFilter(state,action) {

      const existingFilterIndex = state.documentFilters.findIndex(filter => filter.filter === action.payload);
      
      if (existingFilterIndex !== -1) {
        const removedFilter = state.documentFilters[existingFilterIndex];

        if (removedFilter?.filter === "time") {
          resetTimeState(state);
        }

        if (removedFilter?.filter === "document") {
          state.selectedDocumentData = {
            selectedTemplateIds: [],
            selectedLabelObjects: []
          }
          state.documentSelectOption = {value: "all", label: state.translations.selectAll};
        }

        state.documentFilters.splice(existingFilterIndex, 1);

        updateDocumentStatisticsState(state, state.documentFilters);
      }
    },
    addMetricFilter(state, action) {
      updateFilter(state.metricFilters, action.payload);

      if (action.payload.filter === "time") {
        const { startDateTimestamp, endDateTimestamp } = action.payload.dates;
        state.timeRange.startDateTimestamp = startDateTimestamp;
        state.timeRange.endDateTimestamp = endDateTimestamp;
      }

      updateMetricStatisticsState(state, state.metricFilters)
    },
    removeMetricFilter(state, action) {
      const existingFilterIndex = state.metricFilters.findIndex(filter => filter.filter === action.payload);
      
      if (existingFilterIndex !== -1) {
        const removedFilter = state.metricFilters[existingFilterIndex];

        if (removedFilter?.filter === "time") {
          resetTimeState(state);
        }

        state.metricFilters.splice(existingFilterIndex, 1);

        updateMetricStatisticsState(state, state.metricFilters);
      }
    },
    removeMetricFilters(state) {
      state.metricFilters = [];
      state.metricsChartData = state.initialData.metricChart;
      state.userMeasurementsStatistics = state.initialData.userMeasurements;
      state.metricDatesCount = state.initialData.metricDatesCount;
    
      state.counts.measurement.filtered = state.measurementsStatisticsData.length;
      state.counts.measurement.total = state.measurementsStatisticsData.length;

      resetTimeState(state);
    },
    removeDocumentFilters(state) {
      state.documentFilters = [];
      state.documentChartTemplateData = state.initialData.documentChart;
      state.userDocumentStatistics = state.initialData.userDocuments;
      state.documentDatesCount = state.initialData.documentDatesCount;

      state.counts.document.filtered = state.documentStatisticsData.length;
      state.counts.document.total = state.documentStatisticsData.length;

      resetTimeState(state);

      state.selectedDocumentData = {
        selectedTemplateIds: [],
        selectedLabelObjects: []
      }

      state.documentSelectOption = {value: "all", label: state.translations.selectAll};
    },
    setTimeSelectOption(state, action) {
      state.timeSelectOption = action.payload;
    },
    clearStatistics(state) { //Add more variables here?
      state.fetchUserDocumentStatisticsStatus = null;
    },
    setTimeRange(state, action) {
      const { timeRange } = state;
      timeRange.startDateTimestamp = action.payload.startDateTimestamp;
      timeRange.endDateTimestamp = action.payload.endDateTimestamp;
    },
    updateSelectedDocumentData(state, action) {
      state.selectedDocumentData = action.payload;
    },
    setDocumentSelectOption(state, action) {
      state.documentSelectOption = action.payload;
    },
    setChartType(state, action) {
      state.selectedChartType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDocumentStatistics.pending, (state) => {
        state.fetchUserDocumentStatisticsStatus = 'loading';
      })
      .addCase(fetchUserDocumentStatistics.fulfilled, (state, action) => {
        state.documentStatisticsData = action.payload;
        state.fetchUserDocumentStatisticsStatus = 'idle';

        if (action.meta.arg?.setChartData) {
          const { sortedTemplateNameCountsArray, sortedUserDocumentStatistics, totalDocumentsCount, documentsByUser, documentDatesCount} = processDocumentDataRows(action.payload);

          const documentChartTemplateData = {
            count: sortedTemplateNameCountsArray.map(item => item.count),
            labels: sortedTemplateNameCountsArray.map(item => item.template_name),
            ids: sortedTemplateNameCountsArray.map(item => item.template_id)
          }
          state.documentChartTemplateData = documentChartTemplateData;
          state.initialData.documentChart = documentChartTemplateData;

          state.userDocumentStatistics = sortedUserDocumentStatistics;
          state.initialData.userDocuments = sortedUserDocumentStatistics;

          const documentCounts = state.counts.document;
          documentCounts.filtered = documentCounts.total = totalDocumentsCount;

          state.dataByUser.documents = documentsByUser;
          state.translations = action.meta.arg.translations;
          state.timeSelectOption = {value: "all", label: state.translations.all};
          state.documentSelectOption = {value: "all", label: state.translations.selectAll};
          state.documentDatesCount = documentDatesCount;
          state.initialData.documentDatesCount = documentDatesCount;

          const sortedKeys = Object.keys(documentDatesCount).sort();
          const minDate = sortedKeys[0];
          const maxDate = sortedKeys[sortedKeys.length - 1];
          state.chartTypeDateRange = {
            ...state.chartTypeDateRange,
            document: { minDate, maxDate }
          };
        }
      })
      .addCase(fetchUserDocumentStatistics.rejected, (state) => {
        state.fetchUserDocumentStatisticsStatus = 'error';
      })
      .addCase(fetchUserMeasurementsStatistics.pending, (state) => {
        state.userMeasurementsStatisticsStatus = 'loading';
      })
      .addCase(fetchUserMeasurementsStatistics.fulfilled, (state, action) => {
        state.userMeasurementsStatisticsStatus = 'idle';

        state.measurementsStatisticsData = action.payload.results;

        const { sortedMetricNameCountsArray, sortedUserMeasurementStatistics, measurementsByUser, metricDatesCount} = processMetricDataRows(state.measurementsStatisticsData);

        const metricsChartData = {
          count: sortedMetricNameCountsArray.map(item => item.count),
          labels: sortedMetricNameCountsArray.map(item => item.metric_name),
          ids: sortedMetricNameCountsArray.map(item => item.id)
        }

        state.metricsChartData = metricsChartData;
        state.initialData.metricChart = metricsChartData;
        state.userMeasurementsStatistics = sortedUserMeasurementStatistics;
        state.initialData.userMeasurements = sortedUserMeasurementStatistics;

        state.counts.measurement.filtered = state.measurementsStatisticsData.length;
        state.counts.measurement.total = state.measurementsStatisticsData.length;

        state.dataByUser.measurements = measurementsByUser;
        state.metricDatesCount = metricDatesCount;
        state.initialData.metricDatesCount = metricDatesCount;

        const sortedKeys = Object.keys(metricDatesCount).sort();
        const minDate = sortedKeys[0];
        const maxDate = sortedKeys[sortedKeys.length - 1];
        state.chartTypeDateRange = {
          ...state.chartTypeDateRange,
          metric: { minDate, maxDate }
        };

      })
      .addCase(fetchUserMeasurementsStatistics.rejected, (state) => {
        state.userMeasurementsStatisticsStatus = 'error';
      })
  }
});

export const {
  addDocumentFilter,
  filterMeasurersChart,
  removeMetricFilter,
  addMetricFilter,
  removeMetricFilters,
  removeDocumentFilters,
  removeDocumentFilter,
  setTimeSelectOption,
  clearStatistics,
  setTimeRange,
  updateSelectedDocumentData,
  setDocumentSelectOption,
  setChartType
} = statisticsSlice.actions

export const selectDocumentStatisticsData = (state) => state.statistics.documentStatisticsData;
export const selectFetchUserDocumentStatisticsStatus = (state) => state.statistics.fetchUserDocumentStatisticsStatus;
export const selectFetchUserMeasurementsStatisticsStatus = (state) => state.statistics.userMeasurementsStatisticsStatus;
export const selectDocumentChartTemplateData = (state) => state.statistics.documentChartTemplateData;
export const selectUserDocumentStatistics = (state) => state.statistics.userDocumentStatistics;
export const selectDocumentFilters = (state) => state.statistics.documentFilters;
export const selectDocumentCounts = (state) => state.statistics.counts.document;
export const selectDocumentFilterByAttribute = (state, attribute) =>
  state.statistics.documentFilters.find(filterObj => filterObj.filter === attribute);
export const selectMetricFilterByAttribute = (state, attribute) =>
  state.statistics.metricFilters.find(filterObj => filterObj.filter === attribute);
export const selectMetricsChartData = (state) => state.statistics.metricsChartData;
export const selectMeasurementCounts = (state) => state.statistics.counts.measurement;
export const selectMetricFilters = (state) => state.statistics.metricFilters;
export const selectSelectedDocumentData = (state) => state.statistics.selectedDocumentData;

export default statisticsSlice.reducer;
