import { idsMatch } from '../../utils/HelperFunctions';
/**
 * Get marking database-id if it exist. 
 * If marking.databaseId is set, use it. If it is not set, database-id is in id-field.
 * @param {*} marking 
 * @returns 
 */
export const getMarkingId = (marking) => {
  return marking?.databaseId || marking?.id;
}

/**
 * Check all combinations of id and dabaseId. If any of those match, these are same markings.
 * Use idsMatch to handle number-string differences.
 * 
 * @param {*} marking1 
 * @param {*} marking2 
 * @returns {boolean}
 */
export const markingIdsMatch = (marking1, marking2) => {
  if (!marking1 || !marking2) {
    return false;
  }

  if (idsMatch(marking1.id, marking2.id)) {
    return true;
  }

  if (idsMatch(marking1.id, marking2.databaseId) && marking2.databaseId) {
    return true;
  }

  if (idsMatch(marking1.databaseId, marking2.id) && marking1.databaseId) {
    return true;
  }

  if (idsMatch(marking1.databaseId, marking2.databaseId) && marking1.databaseId && marking2.databaseId) {
    return true;
  }

  return false;
}

/**
 * Compare marking.id and marking.databaseId to markingId. markingId can be 'cacaca-gagaga-gagag-gaga' or 66.
 * Check all combinations of id and dabaseId. If any of those match, these are same markings.
 * Use idsMatch to handle number-string differences.
 * 
 * @param {*} marking 
 * @param {*} markingId
 * @returns {boolean}
 */
export const markingIdMatch = (marking, markingId) => {
  if (!marking || !markingId) {
    return false;
  }

  if (idsMatch(marking.id, markingId)) {
    return true;
  }

  if (idsMatch(marking.databaseId, markingId)) {
    return true;
  }

  return false;
}

export const updateMarkingDataFromPayload = (state, action) => {
  if (
    state.selectedMarking[action.meta.arg.objectId] &&
    markingIdMatch(state.selectedMarking[action.meta.arg.objectId], action.payload.id || action.meta.arg.marking_id)
  ) {
    state.selectedMarking[action.meta.arg.objectId].content_document_id = action.payload.content_document_id;
    state.selectedMarking[action.meta.arg.objectId].content_node_id = action.payload.content_node_id;
    state.selectedMarking[action.meta.arg.objectId].object_id = action.payload.object_id;
    state.selectedMarking[action.meta.arg.objectId].object_title = action.payload.object_title;
    state.selectedMarking[action.meta.arg.objectId].parent_id = action.payload.parent_id;
  }

  Object.values(state.markings)?.forEach(markings => {
    markings?.forEach(m => {
      if (markingIdMatch(m, action.payload.id || action.meta.arg?.marking_id)) {
        m.content_document_id = action.payload.content_document_id;
        m.content_node_id = action.payload.content_node_id;
        m.object_id = action.payload.object_id;
        m.object_title = action.payload.object_title;
        m.parent_id = action.payload.parent_id || action.meta.arg?.parent_id;
        m.modified_ts = action.payload.modified_ts;
      }
    });
  });
}

export const resetMarkingData = (state, objectId) => {
  delete state.markings[objectId];
  state.selectedMarking[objectId] = {};
}