import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './folderAPI';
import * as objectsSlice from '../objects/objectsSlice';
import * as superfoldersSlice from '../superfolders/superfoldersSlice';

const initialState = {
  status: 'idle',
  folderTreeTargetFolderId: null,
  folderTreeVisible: false,
  folderSearchString: "",
  folderCreateTitle: "",
  quickCreateClosed: false,
  importStatus: 'idle'
};

export const createFolder = createAsyncThunk(
  'folder/createFolder',
  async (params, { dispatch }) => {
    const response = await api.createFolder(params);
    if (!params.noFetch) {
      dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());
      dispatch(superfoldersSlice.selectSuperfolder(undefined));
    }
    return response.body;
  }
);

export const folderSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setFolderTreeTargetFolderId: (state, action) => {
      state.folderTreeTargetFolderId = action.payload;
    },
    setFolderTreeVisible: (state, action) => {
      state.folderTreeVisible = action.payload;
    },
    setFolderCreateTitle: (state, action) => {
      state.folderCreateTitle = action.payload;
    },
    invalidateFolderTree: (state) => {
      state.folderTreeTargetFolderId = null;
      state.folderTreeVisible = false;
    },
    setQuickCreateClosed: (state) => {
      state.quickCreateClosed = true;
    },
    setImportStatus: (state, action) => {
      state.importStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFolder.pending, (state) => {
        state.status = 'creating';
      })
      .addCase(createFolder.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(createFolder.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const {
  setFolderTreeTargetFolderId,
  setFolderTreeVisible,
  setFolderCreateTitle,
  invalidateFolderTree,
  setQuickCreateClosed,
  setImportStatus
} = folderSlice.actions;

export const selectStatus = (state) => state.folder.status;
export const selectFolderTreeTargetFolderId = (state) => state.folder.folderTreeTargetFolderId;
export const selectFolderTreeVisible = (state) => state.folder.folderTreeVisible;
export const selectFolderCreateTitle = (state) => state.folder.folderCreateTitle;
export const selectQuickCreateClosed = (state) => state.folder.quickCreateClosed;
export const selectImportStatus = (state) => state.folder.importStatus;

export default folderSlice.reducer;
