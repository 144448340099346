import * as NodeFunctions from './NodeFunctions';

/**
 * We don't use react-list (infinite-list) with template document, so we don't need to flatten the node structure.
 * But we still need to loop through and set some variables.
 */
export const processNodeStructure = (state) => {
  let firstLevelNodeIds = state.data.nodes.map(n => n.id);
  state.firstLevelNodeIds = firstLevelNodeIds;

  let nodesById = {};

  if (state.data.id) {
    // Reset parent node id to be something other than document_id.
    state.data.id = 0;
  }

  processNodeAsParentRecursively(
    state, nodesById,
    state.data, // first parent
    { level: 1 }
  );

  state.nodesById = nodesById;
};

// Use this when only one node (and possibly its children) is/are changed.
export const processNodeStructuresPartially = (state, node) => {
  processNodeRecursively(
    state, state.nodesById,
    state.nodesById[state.nodesById[node.id].parentNodeId],
    node,
    null, // nodeSectionIndex will be 'copied' from existing node
    null, // levelIndex will be 'copied' from existing node
    { level: state.nodesById[node.id].nodeLevel, partial: true }
  );
};

export const processNodeAsParentRecursively = (state, nodesById, parent, opts) => {
  if (!parent || !parent.nodes) return;

  // Own index counter for each level.
  let sectionIndex = -1; // Start from -1, because it will be incremented before first usage
  let levelIndex = -1; // Sub index for each level

  for (let node of parent.nodes) {
    // Don't skip ghost nodes in template editor. They are needed for correct indexes in node add etc.
    // And they were visible in old template editor too.

    let nodeSectionIndex;
    if (node.type === 'section' || node.type === 'macro-section') {
      sectionIndex++;
      nodeSectionIndex = sectionIndex;
    }

    // Increment node index 'inside one parent'
    levelIndex++;

    processNodeRecursively(state, nodesById, parent, node, nodeSectionIndex, levelIndex, opts);
  }
};

export const processNodeRecursively = (state, nodesById, parent, node, nodeSectionIndex, levelIndex, opts) => {
  const prevNodeState = state?.nodesById[node.id];

  // Don't edit node directly, because it's a reference to the original data
  // Put all additional data to nodesById
  let nodeNodes = node.nodes ? JSON.parse(JSON.stringify(node.nodes)) : node.nodes;
  let isInMacroSection = false;

  if (node?.type === 'macro-section') {
    const macro = JSON.parse(JSON.stringify(state.data.macros[node.macroId]));
    if (!nodeNodes) {
      nodeNodes = [];
    }
    nodeNodes.push(macro.node);
  }

  if (parent?.type === 'macro-section' || nodesById[parent?.id]?.isInMacroSection) {
    isInMacroSection = true;
  }

  // NOTE: parentId is not always "correct", macro-section (and its nodes) can be inside multiple nodes
  // That doesn't matter(?), but it's good to know.

  if (opts.partial && nodesById?.[node?.id]) {
    // When updating partially and the nodesById[node] exists
    nodesById[node.id] = {
      ...prevNodeState, // preserve previous values
      ...node,          // update new ones
      // The rest is 'almost' same as without partial update
      nodes: nodeNodes, // This is whole nodes. I don't know if we need this at all?
      childNodes: nodeNodes?.map(n => n.id) || [], // Maybe we can do this like this simple. If we initialize this first to empty array it causes renders anyway.
      parentNodeId: parent?.id || 0, // node must have some sane parentNodeId, use 0 if parent does not exist
      nodeLevel: opts.level,
      collapsed: prevNodeState ? prevNodeState.collapsed : 0,
      sectionIndex: nodeSectionIndex !== undefined && nodeSectionIndex !== null ? nodeSectionIndex : prevNodeState?.sectionIndex,
      levelIndex: levelIndex !== undefined && levelIndex !== null ? levelIndex : prevNodeState?.levelIndex,
      // isMacroSection: preserve
      isInMacroSection,
    };
  } else {
    nodesById[node.id] = {
      wasCreated: prevNodeState?.wasCreated,
      ...node,
      nodes: nodeNodes,
      childNodes: nodeNodes?.map(n => n.id) || [], // Maybe we can do this like this simple. If we initialize this first to empty array it causes renders anyway. 
      parentNodeId: parent?.id || 0, // node must have some sane parentNodeId, use 0 if parent does not exist
      nodeLevel: opts.level,
      collapsed: prevNodeState ? prevNodeState.collapsed : 0,
      sectionIndex: nodeSectionIndex !== undefined && nodeSectionIndex !== null ? nodeSectionIndex : prevNodeState?.sectionIndex,
      levelIndex: levelIndex !== undefined && levelIndex !== null ? levelIndex : prevNodeState?.levelIndex,
      isMacroSection: opts.isMacroSection,
      isInMacroSection,
    };
  }

  if (nodeNodes) {
    // Dig deeper
    let nextIsMacroSection = (node.type === "macro-section") ? node.id : undefined;
    processNodeAsParentRecursively(
      state,
      nodesById,
      nodesById[node.id],
      {
        ...opts, // it's important to pass all existing opts further (especially 'partial')
        level: node.type === "macro-section" ? opts.level : opts.level + 1,
        isMacroSection: nextIsMacroSection
      }
    );
  }

  // ... At this phase child nodes are processed ...

  // Generate grouping information for file nodes... 
  if (node.type === 'input-files') {
    nodesById[node.id].fileGroupsByFilename = {};
    nodesById[node.id].fileGroups = [];
    for (const childNodeId of nodesById[node.id].childNodes) {
      const childNode = nodesById[childNodeId];
      const filename = childNode.filename;
      if (filename) {
        const created_ts = childNode.created_ts;
        if (!nodesById[node.id].fileGroupsByFilename[filename]) {
          nodesById[node.id].fileGroupsByFilename[filename] = { name: filename, files: [], collapsed: true };
          nodesById[node.id].fileGroups.push(filename);
        }
        nodesById[node.id].fileGroupsByFilename[filename].files.push({ parentNodeId: node.id, nodeId: childNodeId, filename: filename, createdTs: created_ts })
      }
    }
    // Sort node.fileGroupsByFilename[filename].files by created_ts for every filename
    for (const filename in nodesById[node.id].fileGroupsByFilename) {
      nodesById[node.id].fileGroupsByFilename[filename].files.sort((a, b) => {
        if (a.createdTs < b.createdTs) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }
  
  if (node.required) {
    state.requiredNodes.push(node.id);
  }

};

export const processUpdatedNodeIds = (state, nodeIds, nodeData) => {
  nodeIds.forEach((nodeId) => {
    // Node is parent (input-images)
    const node = NodeFunctions.recursiveFindNode(state.data, nodeId);
    const newNode = NodeFunctions.recursiveFindNode(nodeData, nodeId);

    // In some odd cases the nodes is gone. BE will add it, so we should too.
    if (!node.nodes) {
      console.error('processUpdatedNodeIds: invalid node structure in state.');
      return;
    }

    if (!newNode?.nodes) {
      console.error('processUpdatedNodeIds: invalid node structure in nodeData.');
      return;
    }

    node.nodes = JSON.parse(JSON.stringify(newNode.nodes));

    processNodeStructuresPartially(state, node);
    // saveCtx is reseted in TemplateSaver
    node.nodes.forEach(childNode => {
      delete state.newFilesByNodeAndFileId[childNode.id];
    });
  });
}