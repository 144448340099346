import { apiCall, API } from '../../utils/APIFunctions';

export function fetchObjectRights(params) {
  let hideExpired = "";
  if (params.opts?.hideExpired) {
    hideExpired = "&hideExpired=1";
  }

  return apiCall(`${API}/kp_objects/${params.objectId}/users?getCompanyRights=1${hideExpired}`, "GET");
}

export function deleteObjectRight(params) {
  return apiCall(`${API}/kp_objects/${params.objectId}/users/${params.userId}`, "delete");
}

export function saveObjectRights(params) {
  return apiCall(`${API}/kp_objects/${params.objectId}/users`, "post", JSON.stringify(params));
}

export function resendObjectRightsInvite(params) {
  params.newui = 1;
  return apiCall(`${API}/kp_objects/${params.objectId}/users/${params.userId}/invite`, "post", JSON.stringify(params));
}
