import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './documentReviewAPI';

const initialState = {
  status: 'idle',
  description: '',
  timeline: '', // Timeline could be in own table?
  timelineTs: null,
  timelineStatus: 'idle',
  rating: null,
  fillingRatePercent: null,
  id: null,
  modifiedTs: null,
};

export const reviewDocument = createAsyncThunk(
  'documentReview/reviewDocument',
  async (_, { getState }) => {
    const response = await api.reviewDocument(getState().document.documentId);

    return response.body;
  }
);

export const fetchDocumentReview = createAsyncThunk(
  'documentReview/fetchDocumentReview',
  async (documentId) => {
    const response = await api.fetchDocumentReview(documentId);

    return response.body;
  }
);

export const updateTimeline = createAsyncThunk(
  'documentReview/updateTimeline',
  async (_, { getState }) => {
    const response = await api.updateTimeline(getState().document.documentId, getState().documentReview.id);

    return response.body;
  }
);

const documentReviewSlice = createSlice({
  name: 'documentReview',
  initialState,
  reducers: {
    resetDocumentReview(state) {
      state.status = 'idle';
      state.description = '';
      state.timeline = '';
      state.timelineTs = null;
      state.rating = null;
      state.fillingRatePercent = null;
      state.id = null;
      state.modifiedTs = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(reviewDocument.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(reviewDocument.fulfilled, (state, action) => {
        state.status = 'idle';
        state.description = action.payload.description;
        state.rating = action.payload.rating;
        state.fillingRatePercent = action.payload.filling_rate_percent;
        state.id = action.payload.id;
        state.modifiedTs = action.payload.modified_ts;
      })
      .addCase(reviewDocument.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDocumentReview.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDocumentReview.fulfilled, (state, action) => {
        state.status = 'idle';
        state.description = action.payload.description;
        state.timeline = action.payload.timeline;
        state.timelineTs = action.payload.timeline_ts;
        state.rating = action.payload.rating;
        state.fillingRatePercent = action.payload.filling_rate_percent;
        state.id = action.payload.id;
        state.modifiedTs = action.payload.modified_ts;
      })
      .addCase(fetchDocumentReview.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateTimeline.pending, (state) => {
        state.timelineStatus = 'loading';
      })
      .addCase(updateTimeline.fulfilled, (state, action) => {
        state.timelineStatus = 'idle';
        state.timeline = action.payload.timeline;
        state.timelineTs = action.payload.timeline_ts;
      })
      .addCase(updateTimeline.rejected, (state) => {
        state.timelineStatus = 'failed';
      });
  },
});

export const {
  resetDocumentReview
} = documentReviewSlice.actions;

export const selectDescription = (state) => state.documentReview.description;
export const selectRating = (state) => state.documentReview.rating;
export const selectFillingRatePercent = (state) => state.documentReview.fillingRatePercent;
export const selectStatus = (state) => state.documentReview.status;
export const selectId = (state) => state.documentReview.id;
export const selectModifiedTs = (state) => state.documentReview.modifiedTs;
export const selectTimeline = (state) => state.documentReview.timeline;
export const selectTimelineTs = (state) => state.documentReview.timelineTs;
export const selectTimelineStatus = (state) => state.documentReview.timelineStatus;

export default documentReviewSlice.reducer;