import React from 'react';
import Icon from './Icon';

export let templateTagHelper = {
  getTagsInUse: (templates) => {
    let tagsWithCounts = {};
    for (let dt of templates) {
      if (!dt.tags) continue;
      for (let tag of dt.tags) {
        if (tagsWithCounts[tag]) {
          tagsWithCounts[tag] += 1;
        }
        else {
          tagsWithCounts[tag] = 1;
        }
      }
    }

    let tagsInUse = [];
    for (let tag in tagsWithCounts) {
      let tagCount = tagsWithCounts[tag];
      if (tagCount === templates.length) {
        // No need to add a tag which doesn't filter anything
      }
      else {
        tagsInUse.push(tag);
      }
    }
    tagsInUse.sort((a, b) => a.toLowerCase() > b.toLowerCase());

    return tagsInUse;
  },

  templateMatchesToAllFilterTags: (template, activeFilters) => {
    if (!activeFilters || !Object.keys(activeFilters).length) return true;
    if (!template || !template.tags) return false;

    let match = true;

    for (let filterTag in activeFilters) {
      if (template.tags.indexOf(filterTag) === -1) {
        match = false;
      }
    }

    return match;
  },

  getTemplateTagSelectors: (tagInUse, activeFilters, toggleFunction) => {
    return tagInUse.map(t => {
      return (
        <div
          key={t}
          onClick={() => toggleFunction(t)}
          className={"inline-block mr-2 mb-1 px-2 py-0.5 rounded-full cursor-pointer " +
            (activeFilters[t] ? "bg-brandSecondary text-brandTextSecondary fill-brandTextSecondary shadow-lg" : "bg-bgContentPrimary fill-textContentPrimary text-textContentPrimary hover:text-textSecondary hover:fill-textSecondary shadow-md")
          }
        >
          <div className="flex items-center">
            <Icon name="tags" size="4" />
            <div
              className="text-base ml-2 break-all"
              
            >
              {t}
            </div>
          </div>
        </div>
      );
    })
  },

  getUpdatedFilters: (tag, activeFilters) => {
    let newFilters = Object.assign({}, activeFilters);
    if (newFilters[tag]) {
      delete newFilters[tag];
    }
    else {
      newFilters[tag] = true;
    }
    return newFilters;
  }
};
