
export function addHashToUrl(documentActions, hashString, historyObject) {

  return documentActions.addToShowModals(hashString).then(() => {
    historyObject.push({
      pathname: historyObject.location.pathname,
      search: hashString
    })
    return true;
  }).catch(() => {
    return false;
  })
}

export function escapeHtml(text) {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  if (typeof text !== 'string') return text;

  return text.replace(/[&<>"']/g, function (m) { return map[m]; });
}

export function decodeHtmlChars(text) {
  if (!text) {
    return text;
  }

  var map = {
    '&amp;': '&',
    '&#038;': "&",
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&#8217;': "’",
    '&#8216;': "‘",
    '&#8211;': "–",
    '&#8212;': "—",
    '&#8230;': "…",
    '&#8221;': '”'
  };

  if (typeof text !== 'string') return text;

  // eslint-disable-next-line no-useless-escape
  return text.replace(/\&[\w\d\#]{2,5}\;/g, function (m) { return map[m]; });
}

export function setRootHiddenOverflow(value) {
  let root = document.getElementsByTagName('html')[0];
  let body = document.getElementsByTagName('body')[0];

  if (value) {
    if (!root.classList.contains('overflow-hidden')) {
      root.className += ' overflow-hidden';
    }
    if (!body.classList.contains('overflow-hidden')) {
      body.className += ' overflow-hidden';
    }
  } else {
    root.classList.remove('overflow-hidden');
    body.classList.remove('overflow-hidden')
  }
}

export function updateReactListHeight() {
  const reactList = document.getElementById('reactListContainer').children[1];
  const firstChild = reactList.children[0];
  const style = firstChild.style;
  const matrix = style.transform || style.webkitTransform || style.mozTransform;
  let value = matrix.split('(')[1].split(')')[0].split(',');

  if (value[1]) {
    value = Number(value[1].replace('px', ''));
  } else {
    value = 0;
  }

  let newHeight = 0;
  Array.from(reactList.children[0].children[0].children).forEach(c => {
    newHeight += c.scrollHeight;
  });

  const oldHeight = reactList.getBoundingClientRect().height;

  /**
   * If change is smaller than 100, dont update height for safety reasons.
   * We don't want to mess in react-list territory unless there is not any other way
   */
  if (value + newHeight > oldHeight + 100) {
    console.debug("set new height");
    reactList.style.height = value + newHeight + 'px';
  }
}

export function toggleModalFade(toggleFade) {
  if (toggleFade) {

    let newDiv = document.createElement("div");
    newDiv.className = "modal-backdrop show";
    newDiv.id = "backdropElement";

    document.documentElement.appendChild(newDiv);
    document.body.classList.add(...["overflow-hidden", "pr-[15px]", "modal-open"]);


  } else {
    const backdropElement = document.getElementById("backdropElement");

    if (backdropElement) {
      backdropElement.remove();
    }
    document.body.classList.remove(...["overflow-hidden", "pr-[15px]", "modal-open"]);

  }
}

// This fixes backwards compatibility.
// Signer ID was stored at the same level with value. We must keep it there.
// But we are making it here as it would be in node.value
function readNodeValueIncludingSigningRightsId(value, signingRightsId) {

  let nodeValue = value ? Object.assign({}, value) : {};

  if (signingRightsId > 0 && nodeValue === undefined) {
    nodeValue.signingRightsId = signingRightsId;
  }

  return nodeValue;
}

function getUserWithSigningRightsFromAuth(nodeValue, authData) {

  let formattedUser = null;

  const isUserWithSigningRightsLoggedIn = (nodeValue && nodeValue.signingRightsId == authData.userId);

  if (isUserWithSigningRightsLoggedIn) {

    formattedUser = {
      user_id: authData.userId,
      display_name: authData.displayName
    }
  }

  return formattedUser;
}

export const getParticipantsListAndUsersWithRights = (objectRights, signingRightsId, nodeVal, authData) => {

  const nodeValue = readNodeValueIncludingSigningRightsId(nodeVal, signingRightsId);

  let participantsListAndUsersWithRights = {
    loggedUserWithRights: null,
    userWithSigningRights: /*Returns null if the user with signing rights is not found from auth*/ getUserWithSigningRightsFromAuth(nodeValue, authData),
    participantsList: []
  };

  const isSigningRightsSet = signingRightsId > 0;

  for (let i = 0; i < objectRights?.length; i++) {
    const kpObjectUser = objectRights[i];

    const displayName = 
    `${kpObjectUser.first_name || ""} ${kpObjectUser.last_name || ""}` +
    ((kpObjectUser.first_name || kpObjectUser.last_name) && kpObjectUser.email ? ` (${kpObjectUser.email})` : kpObjectUser.email || "");
  

    const formattedUser = {
      user_id: kpObjectUser.user_id,
      display_name: displayName
    };

    if (kpObjectUser.user_id == authData.userId) {
      participantsListAndUsersWithRights.loggedUserWithRights = kpObjectUser;
    }

    if (isSigningRightsSet &&
      nodeValue &&
      kpObjectUser.user_id == signingRightsId
    ) {
      participantsListAndUsersWithRights.userWithSigningRights = formattedUser;
    }


    participantsListAndUsersWithRights.participantsList.push(formattedUser);
  }

  return participantsListAndUsersWithRights;
}

export const chartJsCustomTooltip = (context) => {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; height: 15px';
      style += '; width: 15px';
      style += '; margin-right: 3px';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td style="display:flex; align-items:center;">' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    let tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  //const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  //tooltipEl.style.font = bodyFont.string;
  tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
  tooltipEl.style.pointerEvents = 'none';
}

/**
 * Used to check if marking_ids match. Marking.id can be:
 * -string ("132123jhkkjn31231")
 * -int (123)
 * -string ("123")
 * 
 * @param {*} id1 
 * @param {*} id2 
 * @returns
 */
export const idsMatch = (id1, id2) => {
  return Number(id1) === Number(id2) || id1 === id2;
}

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
export const isFirefoxiOS = navigator.userAgent.indexOf('FxiOS') > -1;

// This is used to hide image download button on iOS Firefox
// because image download is not supported on iOS Firefox
export const isMobileFirefoxOnIOS = () => { 
  const isFirefoxiOS = navigator.userAgent.indexOf('FxiOS') > -1;
  return isFirefoxiOS && isIOS;
}

export const getIsObjectsAddingToRootEnabled = (objectId, isAdmin, enabledRootFolderObjects) => {
  return !(
    objectId === 0 
          && !isAdmin 
          && Array.isArray(enabledRootFolderObjects) 
          && enabledRootFolderObjects.length === 0
  );
}

export const getIsObjectAddingToRootDisabled = (objectId, isAdmin, enabledRootFolderObjects,objectType) => {
  return (
    objectId === 0 
        && !isAdmin 
        && Array.isArray(enabledRootFolderObjects)
        && !enabledRootFolderObjects.includes(objectType) 
  );
}

export const isValidValue = (value) => {
  return !Number.isNaN(value) && value !== 'NaN' && value !== 'undefined';
}

export const getLinkForObjectType = (object) => {
  switch (object?.type) {
    case "folder":
    case "compilationsfolder":
    case "floor_plan_folder":
      return `/folders/${object.id}`;
    case "document":
      return `/documents/${object.id}`;
    case "metric":
      return `/metrics/${object.id}`;
    case "journal":
      return `/journals/${object.id}`;
    case "floor_plan":
      return `/floor_plans/${object.id}`;
    default:
      return;
  }
}
export const sendGTMEvent = (eventData) => {
  console.debug('ae: ' + JSON.stringify(eventData))
  if (window.dataLayer) {
    window.dataLayer.push(eventData)
  }
}

export const formatAISuggestionText = (text) => {
  const textSplitted = text ? text?.match(/<text>([\s\S]*?)<\/text>/) : null;
  return textSplitted ? textSplitted[1].trim().replace(/<\/?add>/g, '') : '';
}

export const getRequiredNodeDetails = (requiredNodeSet, hasEditRights, required, showRequiredNodeHighlight) => {
  const asteriskColor = requiredNodeSet ? "text-success" : "text-error";
  const showAsterisk = hasEditRights && required;
  const showNodeHighlight = showRequiredNodeHighlight && !requiredNodeSet && required;

  return { asteriskColor, showAsterisk, showNodeHighlight };
}

export const handleEnterOrSpaceKeyDown = (e, action) => {
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault();
    if (action)
      action();
  }
}

//This function is used to fix bug when adding new element with template editor
//TemplateNodeAdd tabIndex causes a bug where the focus is not set to the new element
//Because of that we need to remove tabIndex from the nearest templateNodeAdd element
//and set it back after the focus is set to the new element
export const focusInputField = (inputField) => {
  const templateNodeAddElements = document.getElementsByClassName('templateNodeAdd');
  let elementBeforeInput;

  for (let i = 0; i < templateNodeAddElements.length; i++) {
    const position = inputField.current.compareDocumentPosition(templateNodeAddElements[i]);
    
    if (position & Node.DOCUMENT_POSITION_FOLLOWING) {
      elementBeforeInput = templateNodeAddElements[i > 0 ? i - 1 : 0];
      break;
    }
  }

  if (elementBeforeInput) {
    elementBeforeInput.removeAttribute('tabIndex');
  }

  inputField.current.focus();

  if (elementBeforeInput) {
    setTimeout(() => {elementBeforeInput.setAttribute('tabIndex', 0)}, 0);
  }
}

