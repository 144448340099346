import Constants from '../../app/constants.js'

const defaultFolderSettings = { items_order: 'modified_ts', items_order_descending: 1 };

// Bubble up latest modified_ts from object to all parent objects
const bubbleTimestampToParents = (objects, object) => {
  if (typeof objects[object.parent_id] !== 'undefined') {
    const parent = objects[object.parent_id];

    if (object.modified_ts > parent.modified_ts) {
      parent.modified_ts = object.modified_ts;
    }

    bubbleTimestampToParents(objects, parent);
  }
}

const bubbleStatusToParents = (objects, object, status) => {
  if (!status) {
    return;
  }

  if (typeof objects[object.parent_id] !== 'undefined') {
    const parent = objects[object.parent_id];

    if (!parent.statusCounts) {
      parent.statusCounts = {
        all: 0,
        notStarted: 0,
        inProgress: 0,
        ready: 0
      };
    }

    parent.statusCounts.all++;
    parent.statusCounts[status]++;

    bubbleStatusToParents(objects, parent, status);
  }
}

const getObjectStatus = (object) => {
  if (object.completed === 1) {
    return 'ready';
  } else if (object.completed === 0) {
    return 'inProgress';
  } else if (object.completed === null) {
    return 'notStarted';
  }
}

/**
 * This function is used to set counts inside "Shared to me" -folder.
 * @param {*} targetFolder Add counts from child folder to this folder
 * @param {*} countsToAdd Counts of child folder
 * @returns 
 */
const updateFolderStatuses = (targetFolder, countsToAdd) => {
  if (!targetFolder.statusCounts) {
    targetFolder.statusCounts = { all: 0, notStarted: 0, inProgress: 0, ready: 0 };
  }

  if (!countsToAdd) {
    return;
  }

  targetFolder.statusCounts.all += countsToAdd.all;
  targetFolder.statusCounts.notStarted += countsToAdd.notStarted;
  targetFolder.statusCounts.inProgress += countsToAdd.inProgress;
  targetFolder.statusCounts.ready += countsToAdd.ready;
}

export const buildObjectsTree = (objects, opts, folderSettings) => {
  let statusCounts = {
    notStarted: 0,
    inProgress: 0,
    ready: 0,
    all: 0
  };
  
  const templateIdCounts = {};
  const superfolderIdCounts = {};

  const enableSharedToMeFolder = opts.shared_to_me_enabled;

  let shared = {
    id: Constants.SHARED_TO_ME_FOLDER_ID,
    parent_id: 0,
    company_id: 0,
    title: { id: 'folder.sharedToMe' },
    type: 'folder',
    right: 'view',
    modified_ts: 0,
    objects: {}
  };
  let sharedObjectIdsInRoot = [];

  objects[0] = {
    id: 0,
    company_id: opts.company_id,
    title: '',
    right: 'edit',
    objects: {},
    type: 'folder'
  };

  for (const key in objects) {
    if (folderSettings && folderSettings[key]) {
      objects[key].folderSettings = folderSettings[key];
    } else {
      objects[key].folderSettings = defaultFolderSettings;
      if (objects[key].items_order) {
        // Object has "general" items_order. It's possibly copied from superfolder.
        objects[key].folderSettings = { ...folderSettings, items_order: objects[key].items_order };
      }
    }

    if (key === '0') {
      continue;
    }

    if (Object.prototype.hasOwnProperty.call(objects, key)) {
      const object = objects[key];
      const status = getObjectStatus(object);

      if (status) {
        // This is "all objects" count. It also includes shared objects, so it is different than root-status.
        statusCounts.all++;
        statusCounts[status]++;
      }

      if (object.template_id) {
        if (!templateIdCounts[object.template_id]) {
          templateIdCounts[object.template_id] = 0;
        }
        templateIdCounts[object.template_id]++;
      }

      if (object.superfoldertype_id) {
        if (!superfolderIdCounts[object.superfoldertype_id]) {
          superfolderIdCounts[object.superfoldertype_id] = 0;
        }
        superfolderIdCounts[object.superfoldertype_id]++;
      }

      if (!objects[object.parent_id] && object.in_superfolder_id) {
        object.parent_id = object.in_superfolder_id;
      }

      // If there's a parent folder, put this object to parent.objects with same key, otherwise put in root
      if (object.parent_id && objects[object.parent_id]) {
        const parent = objects[object.parent_id];

        if (typeof parent.objects === 'undefined') {
          parent.objects = {};
        }

        parent.objects[key] = object;

        bubbleTimestampToParents(objects, object);
        bubbleStatusToParents(objects, object, status);
      } else {
        if (!enableSharedToMeFolder || object.right === 'owner') {
          objects[0].objects[key] = object;
        } else {
          // This would be in root, but the new way is to put it inside the 'shared to me' folder
          sharedObjectIdsInRoot.push(object.id);
        }
      }
    }
  }

  if (enableSharedToMeFolder) {
    // These 'virtual folders' (shared to me with the company level)
    //   needs to be processed in phases to make ts-bubbling work properly.
    //   (One can't bubble something into something which does not exist yet.)

    // Generate 'shared to me' company level folder structure after iterating and ts-bubbling every other object.
    for (let sharedObjectId of sharedObjectIdsInRoot) {
      const object = objects[sharedObjectId];
      const status = getObjectStatus(object);
      const company_key = 0 - object.company_id; // Utilize negative numbers for shared from company_id's
      if (!shared.objects[company_key]) {
        // Create the company level
        shared.objects[company_key] = {
          id: company_key,
          parent_id: Constants.SHARED_TO_ME_FOLDER_ID, // Company level parent is the 'shared to me' folder.
          company_id: object.company_id,
          title: object.company_name,
          owner_name: object.owner_name,
          type: 'folder',
          right: 'view',
          modified_ts: 0,
          objects: {}
        }
      }
      object.parent_id = company_key; // Set 'correct' parent
      shared.objects[company_key].objects[sharedObjectId] = object; // Add object to the 'correct' shared company folder
      bubbleTimestampToParents(shared.objects, object);
      bubbleStatusToParents(shared.objects, object, status);
      updateFolderStatuses(shared.objects[company_key], object.statusCounts);
    }

    // 'Point' shared objects to the data structure ('shared' is a pointer)
    objects[Constants.SHARED_TO_ME_FOLDER_ID] = shared; // root level (every object is accessible from root)
    objects[0].objects[Constants.SHARED_TO_ME_FOLDER_ID] = shared; // parent (0) level link

    /* We can't set folderSettings for sharedToMe-folder, because its id is "shared". 
     * It should be number. Or we should edit folder_settings table and logic.

    if (folderSettings && folderSettings[Constants.SHARED_TO_ME_FOLDER_ID]) {
      objects[Constants.SHARED_TO_ME_FOLDER_ID].folderSettings = folderSettings[Constants.SHARED_TO_ME_FOLDER_ID];
    } else {
      objects[Constants.SHARED_TO_ME_FOLDER_ID].folderSettings = defaultFolderSettings;
    }
    */

    // 'Point' shared company levels to root
    for (let company_key in shared.objects) {
      objects[company_key] = shared.objects[company_key];
      bubbleTimestampToParents(objects, objects[company_key]);
      updateFolderStatuses(objects[0].objects[Constants.SHARED_TO_ME_FOLDER_ID], objects[company_key].statusCounts);
    }
  }
  return {statusCounts, templateIdCounts, superfolderIdCounts};
}

/** Action requirements **
        Moving:
          owner-right
        Deleting:
          owner-right
          OR
          manage right to the object and its parent
        Renaming:
          owner-right
          OR
          manage right to the object and its parent
        Sharing:
          owner-right
          OR
          manage right to the object
        Copying:
          ability to create documents (canAddDocument) AND
          document type of object AND
            owner/edit rights at the same company
            OR
            manage right to the object and its parent
*/
export const evaluateRights = (object, parent, userCompanyId, userCompanyFeatures, folderCompanyFeatures) => {
  const rights = {
    move: false,
    delete: false,
    copy: false,
    rename: false,
    share: false
  };

  if (!object) {
    return rights;
  }

  let canAddDocument = false;

  if (parent?.company_id === userCompanyId) {
    canAddDocument = !!userCompanyFeatures?.documents;
  } else if (parent?.right === 'manage') {
    canAddDocument = !!folderCompanyFeatures?.documents;
  }

  if (object.right === 'owner') {
    rights.move = true;
  }

  if (
    object.right === 'owner' ||
    object.right === 'manage'
  ) {
    rights.share = true;
  }

  if (
    object.right === 'owner' ||
    (object.right === 'manage' && parent?.right === 'manage')
  ) {
    rights.delete = true;
    rights.rename = true;
  }

  if (
    canAddDocument &&
    object.type === 'document' &&
    (
      (
        (object.right === 'owner' || object.right === 'edit') &&
        userCompanyId === object.company_id
      )
      ||
      (
        object.right === 'manage' && parent?.right === 'manage'
      )
    )
  ) {
    rights.copy = true;
  }

  if (object.type === 'compilationsfolder') {
    rights.move = false;
    rights.delete = false;
    rights.rename = false;
    rights.copy = false;
  }

  return rights;
}

export const buildObjectPath = (objects, object) => {
  var path = [];
  if (!object) {
    return path;
  }

  /**
   * object.parent_id = object.in_superfolder_id; is done in buildObjectTree(), don't do it here.
   * This is not the place to modify objectTree, this is meant to get objectPath.
   * object is read-only at least when this function is called from ObjectBreadcrumb, then object is props.object
   * Seems that there is not easy way to check if object is read-only.
   */

  let parentId = object.parent_id;
  if (!objects[object.parent_id] && object.in_superfolder_id) {
    parentId = object.in_superfolder_id;
  }

  if (parentId !== 0) {
    if (objects[parentId]) {
      var parent = objects[parentId];
      path = buildObjectPath(objects, parent);
    } else {
      // parent not shared to this user, skip
    }
  }
  path.push({
    title: object.title,
    id: object.id,
    isSuperfolder: object.superfoldertype_id ? true : false,
    propagateViewOnShare: object.propagate_view_on_share ? true : false,
    type: object.type,
    right: object.right,
  });

  return path;
}
