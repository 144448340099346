import { configureStore } from '@reduxjs/toolkit';
import objectsReducer from '../features/objects/objectsSlice';
import authReducer from '../features/auth/authSlice';
import documentReducer from '../features/document/documentSlice';
import doctemplatesReducer from '../features/doctemplates/doctemplatesSlice';
import companyReducer from '../features/company/companySlice';
import companyAdminReducer from '../features/companyAdmin/companyAdminSlice'
import usersReducer from '../features/users/usersSlice';
import objectRightsReducer from '../features/objectRights/objectRightsSlice';
import accountReducer from '../features/account/accountSlice';
import superfoldersReducer from '../features/superfolders/superfoldersSlice';
import folderReducer from '../features/folder/folderSlice';
import statisticsReducer from '../features/companyAdmin/statistics/statisticsSlice';
import errorReducer from '../features/error/errorSlice';
import navigationReducer from '../features/navigation/navigationSlice';
import imageReducer from '../features/image/imageSlice';
import elasticsearchReducer from '../features/elasticsearch/elasticsearchSlice';
import metricReducer from '../features/metric/metricSlice';
import signupReducer from '../features/signup/signupSlice';
import subscriptionReducer from '../features/subscription/subscriptionSlice';
import journalReducer from '../features/journal/journalSlice';
import helpScoutReducer from '../features/helpScout/helpScoutSlice';
import templatesEditorReducer from '../features/templatesEditor/templatesEditorSlice';
import recycleBinReducer from '../features/recycleBin/recycleBinSlice';
import floorPlanReducer from '../features/floorPlan/floorPlanSlice';
import modalReducer from '../features/modal/modalSlice';
import sgAdminReducer from '../features/sgAdmin/sgAdminSlice';
import compilationReducer from '../features/compilation/compilationSlice';
import documentReviewReducer from '../features/documentReview/documentReviewSlice';

export const store = configureStore({
  reducer: {
    error: errorReducer,
    objects: objectsReducer,
    auth: authReducer,
    document: documentReducer,
    doctemplates: doctemplatesReducer,
    company: companyReducer,
    companyAdmin: companyAdminReducer,
    users: usersReducer,
    objectRights: objectRightsReducer,
    account: accountReducer,
    superfolders: superfoldersReducer,
    folder: folderReducer,
    statistics: statisticsReducer,
    navigation: navigationReducer,
    image: imageReducer,
    elasticsearch: elasticsearchReducer,
    metric: metricReducer,
    signup: signupReducer,
    subscription: subscriptionReducer,
    journal: journalReducer,
    helpScout: helpScoutReducer,
    templatesEditor: templatesEditorReducer,
    recycleBin: recycleBinReducer,
    floorPlan: floorPlanReducer,
    modal: modalReducer,
    sgAdmin: sgAdminReducer,
    compilation: compilationReducer,
    documentReview: documentReviewReducer,
  },
});
