import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './recycleBinAPI';

const initialState = {
  status: 'idle',
  recycleBinList: [],
  recycleBinListById: [],
  recycleBinListTotalCount: 0,
  selectedObjectIds: {},
  modal: {},
  listOrder: { type: 'deleted_ts', order: 'desc' },
  searchString: ''
};

export const fetchRecycleBin = createAsyncThunk(
  'recycleBin/fetchRecycleBin',
  async () => {
    const response = await api.fetchRecycleBin();
    return response.body;
  }
);

export const deleteObject = createAsyncThunk(
  'recycleBin/deleteFromRecycleBin',
  async (params, { dispatch }) => {
    let promiseArr = params.objectIds;
    let mostRecentPromise = promiseArr.reduce((previousPromise, objectId) => {
      return previousPromise.then(() => {
        return api.deleteObject(objectId);
      });
    }, Promise.resolve([]));

    return mostRecentPromise.then(() => {
      dispatch(fetchRecycleBin());
    });
  }
);

export const restoreObject = createAsyncThunk(
  'recycleBin/restoreObject',

  async (params, { dispatch }) => {
    let promiseArr = params.objectIds;
    let mostRecentPromise = promiseArr.reduce((previousPromise, objectId) => {
      return previousPromise.then(() => {
        return api.restoreObject(objectId);
      });
    }, Promise.resolve([]));

    return mostRecentPromise.then(() => {
      dispatch(fetchRecycleBin());
    });
  }
);

export const emptyRecycleBin = createAsyncThunk(
  'recycleBin/emptyRecycleBin',
  async (_params, {dispatch }) => {
    const response = await api.emptyRecycleBin();
    dispatch(fetchRecycleBin());
    return response.body;
  }
);

function flattenChildren(array) {
  const result = [];
  array.forEach(item => {
    result.push({...item});
    if (item.children.length > 0) {
      result.push(...flattenChildren(item.children));
    }
  });
  return result;
}

function findAncestors(objectsById, objectId, result = []) {
  const node = objectsById[objectId];
  if (node) {
    result.push(node);
    if (node.parent_id !== 0) {
      findAncestors(objectsById, node.parent_id, result);
    }
  }
  return result;
}

export const recycleBinSlice = createSlice({
  name: 'recycleBin',
  initialState,
  reducers: {
    toggleSelectItem: (state, action) => {
      if (state.selectedObjectIds[action.payload]) {
        delete state.selectedObjectIds[action.payload];
      } else {
        state.selectedObjectIds[action.payload] = action.payload;
      }
    },
    selectAllItems: (state) => {
      state.selectedObjectIds = {};
      state.recycleBinList.forEach(item => {
        state.selectedObjectIds[item.id] = item.id;
      });
    },
    deselectAllItems: (state) => {
      state.selectedObjectIds = {};
    },
    setModalOpen: (state, action) => {
      state.modal.isOpen = action.payload.open;
      state.modal.type = action.payload.type;
    },
    setRecycleBinListOrder: (state, action) => {
      state.listOrder.type = action.payload.type;
      state.listOrder.order = action.payload.order;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    resetRecycleBin: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecycleBin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecycleBin.fulfilled, (state, action) => {
        let fullList = action.payload;
        let filteredList = [];
        let resultArray = [];
        
        let itemMap = new Map();
        fullList.forEach(item => {
          item.children = [];
          itemMap.set(item.id, item);
        });

        let listOfIds = new Set(fullList.map(item => item.id));
 
        itemMap.forEach(item => {
          if (!listOfIds.has(item.parent_id)) {
            if (item.parent_id === 0) {
              item.parent_title = "Vision";
            }
            filteredList.push(item);
          } else {
            let parent = itemMap.get(item.parent_id);
            if (parent && item.deleted_ts === parent.deleted_ts) {
              parent.children.push(item);
            }
          }
        });

        filteredList.forEach(item => {
          let flatChild = flattenChildren(item.children);
          resultArray.push({...item, flattenedChildren: flatChild})
        });

        const listById = {};
        for (const obj of resultArray) {
          listById[obj.id] = obj;
        }
        
        state.recycleBinList = resultArray;
        state.recycleBinListById = listById;
        state.recycleBinListTotalCount = fullList.length;
        state.status = 'idle';
      })
      .addCase(fetchRecycleBin.rejected, (state) => {
        state.status = 'error';
      })
      
      .addCase(deleteObject.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(deleteObject.fulfilled, (state) => {
        state.status = 'idle';
        state.selectedObjectIds = {};
      })
      .addCase(deleteObject.rejected, (state) => {
        state.status = 'error';
      })

      .addCase(restoreObject.pending, (state) => {
        state.status = 'restoring';
      })
      .addCase(restoreObject.fulfilled, (state) => {
        state.status = 'idle';
        state.selectedObjectIds = {};
      })
      .addCase(restoreObject.rejected, (state) => {
        state.status = 'error';
      })

      .addCase(emptyRecycleBin.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(emptyRecycleBin.fulfilled, (state) => {
        state.status = 'idle';
        state.selectedObjectIds = {};
      })
      .addCase(emptyRecycleBin.rejected, (state) => {
        state.status = 'error';
      });
  }
});

export const selectIdInSelectedObjectIds = (state, objectId) => state.recycleBin.selectedObjectIds[objectId];
export const selectHasSelectedObjects = (state) => Object.keys(state.recycleBin.selectedObjectIds).length > 0;
export const selectSelectedObjectCount = (state) => Object.keys(state.recycleBin.selectedObjectIds).length || 0;
export const selectTotalObjectCount = (state) => state.recycleBin.recycleBinListTotalCount || 0;
export const selectObjectById = (state, objectId) => state.recycleBin.recycleBinListById[objectId];
export const selectRecycleBinListOrder = (state) => state.recycleBin.listOrder;
export const selectRecycleBinSearchString = (state) => state.recycleBin.searchString;
export const selectRecycleBinModal = (state) => state.recycleBin.modal;

export const selectSelectedObjectTotalCount = (state) => {
  let selectedObjects = Object.keys(state.recycleBin.selectedObjectIds);
  let count = 0;
  selectedObjects.map((objectId) => {
    count++;
    let object = state.recycleBin.recycleBinListById[objectId];
    if (object.flattenedChildren && object.flattenedChildren.length > 0) {
      let childCount = object.flattenedChildren.length;
      count += childCount;
    }
  });
  return count;
}

export const selectAncestors = (state, parentId) => {

  if (parentId) {
    const objects = state.objects.objectsById;
    const object = state.objects.objectsMapById[parentId];
    
    return findAncestors(objects, object?.id);
  }
}

export const {
  toggleSelectItem,
  selectAllItems,
  deselectAllItems,
  setModalOpen,
  setRecycleBinListOrder,
  resetRecycleBin,
  setSearchString
} = recycleBinSlice.actions;

export default recycleBinSlice.reducer;