import KPConfig from "../../KPConfig";

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as authSlice from '../auth/authSlice';
import * as api from './signupAPI';

const initialState = {
  step: 1,
  status: 'idle',
  createAccountStatus: 'idle',
  createAccountErrorReason: '',
  loginStatus: 'idle',
  resetStatus: 'idle',
  emailVerificationSendStatus: 'idle',
  emailVerificationSendError: '',
  email: '',
  templates: [],
  selectedTemplate: null,
  accessToken: null,
  idToken: null,
  tokenProvider: null,
  userBaseInfoModalOpenCount: 0,
  continueByPhoneClosed: false,
  continueByPhoneStatus: 'idle',
  loginErrorMessage: '',
  resetCodeStatus: 'idle',
  resetErrorMessage: '',
  nonce: null,
  challenge: { id: false, answer: "" },
  downloadTokenStatus: 'idle',
  downloadToken: null,
};

export const fetchSignupTemplates = createAsyncThunk(
  'signup/fetchSignupTemplates',
  async (locale) => {
    const response = await api.fetchSignupTemplates(locale);
    return response.body;
  }
);

export const createAccount = createAsyncThunk(
  'signup/createAccount',
  async (params, { getState }) => {
    const response = await api.createAccount({
      email: getState().signup.email,
      selectedTemplate: getState().signup.selectedTemplate.label,
      locale: params,
      idToken: getState().signup.idToken,
      tokenProvider: getState().signup.tokenProvider,
      accessToken: getState().signup.accessToken,
    });

    return response;
  }
);

export const login = createAsyncThunk(
  'signup/login',
  async (password, { getState }) => {
    const response = await api.login({
      email: getState().signup.email,
      password,
      idToken: getState().signup.idToken,
      tokenProvider: getState().signup.tokenProvider,
      accessToken: getState().signup.accessToken,
    });

    if (response?.body?.visionAccessToken) {
      await api.newBeLogin({
        visionAccessToken: response.body.visionAccessToken
      });
    }

    return response;
  }
);

export const newBeLogout = createAsyncThunk(
  'signup/newBeLogout',
  async () => {
    const response = await api.newBeLogout() ?? {body: ""};
    return response.body;
  }
);

export const sendPasswordReset = createAsyncThunk(
  'signup/sendPasswordReset',
  async (_params, { getState }) => {
    const response = await api.sendPasswordReset({
      email: getState().signup.email,
    });

    return response;
  }
);

export const sendEmailVerificationEmail = createAsyncThunk(
  'signup/sendEmailVerificationEmail',
  async (_params, { getState, dispatch }) => {
    const response = await api.sendEmailVerificationEmail(getState().auth.data.email);

    dispatch(authSlice.getAuth());
    return response;
  }
);

export const sendLoginEmail = createAsyncThunk(
  'signup/sendLoginEmail',
  async (_params, { getState }) => {
    const response = await api.sendLoginEmail(getState().auth.data.email);

    return response;
  }
);

export const inviteLogin = createAsyncThunk(
  'signup/inviteLogin',
  async (params) => {
    let response = await api.inviteLogin(params);

    if (response?.body?.visionAccessToken) {
      await api.newBeLogin({
        visionAccessToken: response.body.visionAccessToken
      });
    }

    return response.body;
  }
);

export const validatePasswordResetCode = createAsyncThunk(
  'signup/validatePasswordResetCode',
  async (passwordResetCode) => {
    const response = await api.validatePasswordResetCode(passwordResetCode);
    return response;
  }
);

export const passwordReset = createAsyncThunk(
  'signup/passwordReset',
  async (params) => {
    const response = await api.passwordReset(params);
    return response;
  }
);

export const getNonce = createAsyncThunk(
  'signup/getNonce',
  async () => {
    let response = await api.getNonce();
    return response.body;
  }
);

export const respondToChallenge = createAsyncThunk(
  'signup/respondToChallenge',
  async (_params, { getState }) => {
    let response = await api.respondToChallenge(getState().signup.challenge);
    return response.body;
  }
);

export const getObjectDownloadTokenStatus = createAsyncThunk(
  'signup/getObjectDownloadTokenStatus',
  async (params) => {
    const response = await api.getObjectDownloadTokenStatus(params);
    return response;
  }
);

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload
    },
    setIdToken: (state, action) => {
      state.idToken = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setTokenProvider: (state, action) => {
      state.tokenProvider = action.payload;
    },
    resetAll: (state) => {
      state.tokenProvider = null;
      state.accessToken = null;
      state.idToken = null;
      state.selectedTemplate = null;
      state.email = '';
    },
    addUserBaseInfoModalOpenCount: (state) => {
      state.userBaseInfoModalOpenCount++;
    },
    setContinueByPhoneClosed: (state, action) => {
      state.continueByPhoneClosed = action.payload;
    },
    setChallengeAnswer: (state, action) => {
      state.challenge.answer = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignupTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSignupTemplates.fulfilled, (state, action) => {
        state.templates = action.payload.results;
        state.status = 'idle';
      })
      .addCase(fetchSignupTemplates.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createAccount.pending, (state) => {
        state.createAccountStatus = 'loading';
        state.step = 4;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.createAccountStatus = 'idle';

        if (action.payload.body.loggedIn) {
          const pathName = window.location;
          window.location.replace(pathName.origin + KPConfig.publicUrl + '/folders?fromSignup=1');
        } else if (action.payload.body.redirectToLogin) {
          const pathName = window.location;
          window.location.replace(pathName.origin + '/login');
        } else {
          state.createAccountStatus = 'ready';
        }
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.createAccountStatus = 'error';
        state.createAccountErrorReason = action?.error?.message;
      })
      .addCase(login.pending, (state) => {
        state.loginStatus = 'loading';
        state.loginErrorMessage = '';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginStatus = 'idle';
        
        if (action?.payload?.challengeRequired) {
          state.challenge = {id: action.payload.challengeRequired, answer: ''};
          return;
        }

        const params = new URLSearchParams(window.location.search);
        let path = params?.get('targetUrl');
        if (!path) {
          if (action?.payload?.body?.targetUrl) {
            path = action.payload.body.targetUrl;
          } else {
            path = KPConfig.publicUrl;
          }
        }
        const pathName = window.location;
        window.location.replace(pathName.origin + path);
      })
      .addCase(login.rejected, (state, action) => {
        state.loginErrorMessage = action.error.message;
        state.loginStatus = 'error';
        state.idToken = null;
        state.tokenProvider = null;
        state.accessToken = null;
      })

      .addCase(newBeLogout.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(newBeLogout.fulfilled, (state) => {
        state.loginStatus = 'success';
      })
      .addCase(newBeLogout.rejected, (state) => {
        state.loginStatus = 'success';
      })
      
      .addCase(sendPasswordReset.pending, (state) => {
        state.resetStatus = 'loading';
      })
      .addCase(sendPasswordReset.fulfilled, (state) => {
        state.resetStatus = 'success';
      })
      .addCase(sendPasswordReset.rejected, (state) => {
        state.resetStatus = 'error';
      })
      .addCase(sendEmailVerificationEmail.pending, (state) => {
        state.emailVerificationSendStatus = 'sending';
        state.emailVerificationSendError = '';
      })
      .addCase(sendEmailVerificationEmail.fulfilled, (state) => {
        state.emailVerificationSendStatus = 'idle';
      })
      .addCase(sendEmailVerificationEmail.rejected, (state, action) => {
        state.emailVerificationSendStatus = 'error';
        state.emailVerificationSendError = action.error?.message;
      })
      .addCase(sendLoginEmail.pending, (state) => {
        state.continueByPhoneStatus = 'sending';
      })
      .addCase(sendLoginEmail.fulfilled, (state) => {
        state.continueByPhoneStatus = 'ready';
      })
      .addCase(sendLoginEmail.rejected, (state) => {
        state.continueByPhoneStatus = 'error';
      })
      .addCase(inviteLogin.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(inviteLogin.fulfilled, (state, action) => {
        state.loginStatus = 'idle';
        let targetUrl = action?.payload?.targetUrl || (KPConfig.publicUrl + '/folders');
        window.location.replace(window.location.origin + targetUrl);
      })
      .addCase(inviteLogin.rejected, (state) => {
        state.loginStatus = 'error';
      })
      .addCase(validatePasswordResetCode.pending, (state) => {
        state.resetCodeStatus = 'loading';
      })
      .addCase(validatePasswordResetCode.fulfilled, (state) => {
        state.resetCodeStatus = 'success';
      })
      .addCase(validatePasswordResetCode.rejected, (state, action) => {
        state.resetCodeStatus = 'error';
        state.resetErrorMessage = action.error?.message;
      })
      .addCase(passwordReset.pending, (state) => {
        state.resetStatus = 'loading';
      })
      .addCase(passwordReset.fulfilled, (state) => {
        state.resetStatus = 'success';
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.resetStatus = 'error';
        state.resetErrorMessage = action.error?.message;
      })

      .addCase(getNonce.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(getNonce.fulfilled, (state, action) => {
        state.loginStatus = 'idle';
        state.nonce = action?.payload?.nonce;
      })
      .addCase(getNonce.rejected, (state) => {
        state.loginStatus = 'error';
      })

      .addCase(respondToChallenge.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(respondToChallenge.fulfilled, (state, action) => {
        state.loginStatus = 'idle';

        if (action?.payload?.ret === 'ok') {
          const params = new URLSearchParams(window.location.search);
          let path = params?.get('targetUrl');
          if (!path) {
            if (action?.payload?.body?.targetUrl) {
              path = action.payload.body.targetUrl;
            } else {
              path = KPConfig.publicUrl;
            }
          }
          const pathName = window.location;
          window.location.replace(pathName.origin + path);
        } else {
          console.error("login challenge failed: "+action?.payload?.ret);
          state.challenge.ret = action?.payload?.ret;
        }
      })
      .addCase(respondToChallenge.rejected, (state) => {
        state.loginStatus = 'error';
      })

      .addCase(getObjectDownloadTokenStatus.pending, (state) => {
        state.downloadTokenStatus = 'loading';
      })
      .addCase(getObjectDownloadTokenStatus.fulfilled, (state, action) => {
        state.downloadTokenStatus = 'success';
        state.downloadToken = action?.payload?.body?.results;
      })
      .addCase(getObjectDownloadTokenStatus.rejected, (state) => {
        state.downloadTokenStatus = 'error';
      });
  }
});

export const {
  setStep,
  setEmail,
  setSelectedTemplate,
  setIdToken,
  setAccessToken,
  setTokenProvider,
  resetAll,
  addUserBaseInfoModalOpenCount,
  setContinueByPhoneClosed,
  setChallengeAnswer,
} = signupSlice.actions;

export const selectTemplates = (state) => state.signup.templates;
export const selectStatus = (state) => state.signup.status;
export const selectCreateAccountStatus = (state) => state.signup.createAccountStatus;
export const selectCreateAccountErrorReason = (state) => state.signup.createAccountErrorReason;
export const selectLoginStatus = (state) => state.signup.loginStatus;
export const selectResetStatus = (state) => state.signup.resetStatus;
export const selectResetCodeStatus = (state) => state.signup.resetCodeStatus;
export const selectResetErrorMessage = (state) => state.signup.resetErrorMessage;
export const selectStep = (state) => state.signup.step;
export const selectEmail = (state) => state.signup.email;
export const selectSelectedTemplate = (state) => state.signup.selectedTemplate;
export const selectEmailVerificationSendStatus = (state) => state.signup.emailVerificationSendStatus;
export const selectEmailVerificationSendError = (state) => state.signup.emailVerificationSendError;
export const selectTokenProvider = (state) => state.signup.tokenProvider;
export const selectUserBaseInfoModalOpenCount = (state) => state.signup.userBaseInfoModalOpenCount;
export const selectContinueByPhoneClosed = (state) => state.signup.continueByPhoneClosed;
export const selectContinueByPhoneStatus = (state) => state.signup.continueByPhoneStatus;
export const selectLoginErrorMessage = (state) => state.signup.loginErrorMessage;
export const selectNonce = (state) => state.signup.nonce;
export const selectIsChallengeSet = (state) => state.signup?.challenge?.id ? true : false;
export const selectChallengeAnswer = (state) => state.signup?.challenge?.answer ?? '';
export const selectChallengeReturnValue = (state) => state.signup?.challenge?.ret;
export const selectDownloadTokenStatus = (state) => state.signup.downloadTokenStatus;
export const selectDownloadToken = (state) => state.signup.downloadToken;

export default signupSlice.reducer;
