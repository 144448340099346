import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  beaconOpen: false,
  beaconInitialized: false,
  showBeaconText: false,
  beaconAutoOpened: false
};

export const helpScoutSlice = createSlice({
  name: 'helpScout',
  initialState,
  reducers: {
    setBeaconOpen: (state, action) => {
      state.beaconOpen = action.payload;
    },
    setBeaconInitialized: (state, action) => {
      state.beaconInitialized = action.payload;
    },
    setShowBeaconText: (state, action) => {
      state.showBeaconText = action.payload;
    },
    setBeaconAutoOpened: (state, action) => {
      state.beaconAutoOpened = action.payload;
    }
  }
});

export const {
  setBeaconOpen,
  setBeaconInitialized,
  setShowBeaconText,
  setBeaconAutoOpened
} = helpScoutSlice.actions;


export const selectBeaconOpen = (state) => state.helpScout.beaconOpen;
export const selectBeaconInitialized = (state) => state.helpScout.beaconInitialized;
export const selectShowBeaconText = (state) => state.helpScout.showBeaconText;
export const selectBeaconAutoOpened = (state) => state.helpScout.beaconAutoOpened;

export default helpScoutSlice.reducer;
