import { createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as api from './usersAPI';

import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import { updateUser } from '../account/accountAPI'
import { toggleModalFade } from '../../utils/HelperFunctions'

const initialState = {
  status: 'idle',
  costUnits: [],
  prices: [],
  ownerCompanyData: {},

  users: [],
  guests: [],
  allUsers: [],
  usersById: {},

  companyUserCount: 0,
  guestUserCount: 0,

  userList: {
    visibleUserIds: [],
    selectedTab: 'users',
    searchStr: '',
    sort: {key: 'last_name', order: 'asc'},
    selectedUsers: {},
    allVisibleUsersSelected: false,
  },

  openUserId: null, // for showing user data in modal

  sendInviteStatus: "idle",
  userEditorCheckboxStatuses: {},
  userAdminCheckboxStatuses: {},
  updateUserCostUnitStatuses: {},
  removeUserFromCompanyStatuses: {},
  guestUserStatuses: {},

  showRemoveUserFromCompanyModal: false,
  showAddNewUserModal: false,
  showMoreInfoModal: false,
  showUserObjectsModal: false,

  userObjects: [],
  getUserObjectsStatus: undefined,
};

const generateVisibleUserIds = (state) => {
  let newVisibleUserIds = [];

  // Filter by tab
  if (state.userList.selectedTab === 'users') {
    newVisibleUserIds = state.users.map(u => u.id);
  } else if (state.userList.selectedTab === 'guests') {
    newVisibleUserIds = state.guests.map(u => u.id);
  } else {
    state.userList.visibleUserIds = [];
    return;
  }

  // Filter by search str
  if (state.userList.searchStr && state.userList.searchStr !== "")
    newVisibleUserIds = newVisibleUserIds.filter((id) => {
      const user = state.usersById[id];
      return (user.first_name+' '+user.last_name+' '+user.email).toLowerCase()
        .includes(state.userList.searchStr);
    })

  // Sort
  const sortKey = state.userList.sort.key;
  const sortOrder = state.userList.sort.order;
  let mainSorter = userId => state.usersById[userId][sortKey];
  switch (sortKey) {
    case "last_name":
    case "first_name":
    case "email":
    case "cost_unit":
      // � is quite largish unicode char and it will make empty values to go to their own side
      mainSorter = userId => (state.usersById[userId][sortKey] && state.usersById[userId][sortKey] !== "" ? state.usersById[userId][sortKey].toLowerCase() : "�");
      break;
    case "editor":
    case "admin":
      // there might be some nulls and zeros, so treat them both as zeros (nulls and zeroes are opposite sides of 1's, when sorted)
      mainSorter = userId => (state.usersById[userId][sortKey] ? 1 : 0);
      break;
    default:
      break;
  }
  const deletedSorter = userId => (state.usersById[userId]['deleted'] ? 1 : 0);
  const idSorter = userId => userId;
  newVisibleUserIds = orderBy(newVisibleUserIds, [deletedSorter, mainSorter, idSorter], ['asc', sortOrder, 'asc']);

  // Reassign visibleUserIds only if its contents would be changed
  if (!isEqual(state.userList.visibleUserIds, newVisibleUserIds)) {
    state.userList.visibleUserIds = newVisibleUserIds;
  }
}

const clearSelectedUsers = (state) => {
  state.userList.selectedUsers = {};
  state.userList.allVisibleUsersSelected = false;
}

export const fetchUsers = createAsyncThunk(
  'users/fetch',
  async (params, { getState }) => {
    const response = await api.fetch(getState().auth.data.company_id, params?.hide_deleted);
    return response.body;
  }
);

export const listUsersForManager = createAsyncThunk(
  'users/listForManager',
  async (params) => {
    const response = await api.listForManager(params.objectId);
    response.body.objectId = params.objectId;
    return response.body;
  }
);

export const toggleEditorRightsForUser = createAsyncThunk(
  'users/toggleEditorRightsForUser',
  async (userId) => {
    const response = await api.toggleEditorRightsForUser(userId);
    return response.body;
  }
)

export const toggleAdminRightsForUser = createAsyncThunk(
  'users/toggleAdminRightsForUser',
  async (userId) => {
    const response = await api.toggleAdminRightsForUser(userId);
    return response.body;
  }
)

export const updateUserCostUnit = createAsyncThunk(
  'users/updateUserCostUnit',
  async (params) => {
    const response = await updateUser(params);
    return response.body;
  }
)

export const removeUserFromCompany = createAsyncThunk(
  'users/removeUserFromCompany',
  async (params, {dispatch}) => {
    const response = await  api.removeUserFromCompany(params);

    if (response.isOk) {
      dispatch(fetchUsers({status:"updating"}));
      toggleModalFade(false);
    }
  }
)

export const sendInvite = createAsyncThunk(
  'users/sendInvite',
  async (params, {dispatch}) => {
    const response = await  api.sendInvite(params);

    if (response.isOk) {
      dispatch(fetchUsers());
    }
  }
)

/* If params.userIds is null, selected user ids will be used */
export const restrictShares = createAsyncThunk(
  'users/restrictShares',
  async (params, {getState, dispatch}) => {
    if (!params.userIds) {
      params.userIds = Object.keys(getState().users.userList.selectedUsers)
    }
    if (!params.userIds) return null;

    const response = await  api.restrictShares(params);

    if (response.isOk) {
      dispatch(fetchUsers({status:"updating"}));
    }
  }
)

export const getUserObjects = createAsyncThunk(
  'companyAdmin/getUserObjects',
  async (userId) => {
    return api.getUserObjects(userId);
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetState: () => initialState,

    resetUserObjectsModalStatus: (state) => {
      state.getUserObjectsStatus = undefined;
      state.userObjects = [];
    },

    setSelectedUserListTab: (state, action) => {
      state.userList.selectedTab = action.payload;

      clearSelectedUsers(state);
      generateVisibleUserIds(state);
    },
    setUserListSearchStr: (state,action) => {
      state.userList.searchStr = typeof (action.payload) === "string" ? action.payload.toLowerCase() : "";

      clearSelectedUsers(state);
      generateVisibleUserIds(state);
    },
    setUserListSortOptions: (state, { payload: { key, order } }) => {
      state.userList.sort.key = key ?? state.userList.sort.key;
      state.userList.sort.order = order ?? state.userList.sort.order;

      console.debug("setUserListSortOptions "+state.userList.sort.key+" "+state.userList.sort.order);

      generateVisibleUserIds(state);
    },

    setUserSelected: (state, action) => {
      state.userList.selectedUsers[action.payload] = true;
    },
    setUserDeselected: (state, action) => {
      if (state.userList.selectedUsers[action.payload]) {
        delete state.userList.selectedUsers[action.payload];
      }
      state.userList.allVisibleUsersSelected = false;
    },
    setAllVisibleUsersSelected: (state) => {
      state.userList.selectedUsers = {};
      for (let id of state.userList.visibleUserIds) {
        state.userList.selectedUsers[id] = true;
      }
      state.userList.allVisibleUsersSelected = true;
    },
    setAllUsersDeselected: (state) => {
      state.userList.selectedUsers = {};
      state.userList.allVisibleUsersSelected = false;
    },

    setShowAddNewUserModal: (state, action) => {
      state.showAddNewUserModal = action.payload;
    },
    toggleRemoveUserFromCompanyModal: (state, action) => {
      state.showRemoveUserFromCompanyModal = action.payload.toggleValue;
      state.openUserId = action.payload.userId;
    },
    toggleMoreInfoModal: (state, action) => {
      state.showMoreInfoModal = action.payload.toggleValue;
      state.openUserId = action.payload.userId;
    },
    toggleShowUserObjectsModal: (state, action) => {
      state.showUserObjectsModal = action.payload;
      state.openUserId = action.payload.userId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.fetchUsersStatus = action.meta.arg && action.meta.arg.status
          ? action.meta.arg.status
          : 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.fetchUsersStatus = 'idle';

        state.companyUserCount = action.payload.results.users.length;
        state.guestUserCount = action.payload.results.restricted.length;

        state.users = action.payload.results.users;
        state.guests = action.payload.results.restricted;

        //const allUsers = action.payload.results.users.concat(action.payload.results.restricted);
        //state.data = [...allUsers];
        state.usersById = {};
        state.allUsers = [];
        for (let user of state.users) {
          state.usersById[user.id] = user;
          state.allUsers.push(user);
        }
        for (let user of state.guests) {
          state.usersById[user.id] = user;
          state.allUsers.push(user);
        }

        state.costUnits = action.payload.results.costUnits;
        state.prices = action.payload.results.userPrices;

        generateVisibleUserIds(state);
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.fetchUsersStatus = 'error';
      })

      .addCase(listUsersForManager.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(listUsersForManager.fulfilled, (state, action) => {
        state.ownerCompanyData[action.payload.objectId] = {
          prices: action.payload.prices,
          users: action.payload.companyUsers.concat(action.payload.restrictedUsers)
        };
      })
      .addCase(listUsersForManager.rejected, (state) => {
        state.status = 'error';
      })

      .addCase(toggleEditorRightsForUser.pending, (state, action) => {
        const userId = action?.meta?.arg;
        if (!userId) return;

        state.userEditorCheckboxStatuses[userId] = "saving";
      })
      .addCase(toggleEditorRightsForUser.fulfilled, (state, action) => {
        const userId = action?.meta?.arg;
        if (!userId) return;

        state.userEditorCheckboxStatuses[userId] = "idle";

        const user = state?.usersById?.[userId];
        if (!user) return;

        if (action?.payload?.editor === undefined) return;

        user.editor = action.payload.editor;
      })
      .addCase(toggleEditorRightsForUser.rejected, (state, action) => {
        const userId = action?.meta?.arg;
        if (!userId) return;

        state.userEditorCheckboxStatuses[userId] = "idle";
      })

      .addCase(toggleAdminRightsForUser.pending, (state, action) => {
        const userId = action?.meta?.arg;
        if (!userId) return;

        state.userAdminCheckboxStatuses[userId] = "saving";
        console.debug("saving")
      })
      .addCase(toggleAdminRightsForUser.fulfilled, (state, action) => {
        const userId = action?.meta?.arg;
        if (!userId) return;

        state.userAdminCheckboxStatuses[userId] = "idle";

        const user = state?.usersById?.[userId];
        if (!user) return;

        if (action?.payload?.admin === undefined) return;

        user.admin = action.payload.admin;
      })
      .addCase(toggleAdminRightsForUser.rejected, (state, action) => {
        const userId = action?.meta?.arg;
        if (!userId) return;

        state.userAdminCheckboxStatuses[userId] = "idle";
      })

      .addCase(updateUserCostUnit.pending, (state, action) => {
        const userId = action?.meta?.arg?.user_id;
        if (!userId) return;

        state.updateUserCostUnitStatuses[userId] = "saving";
      })
      .addCase(updateUserCostUnit.fulfilled, (state, action) => {
        const userId = action?.meta?.arg?.user_id;
        const costUnit = action?.meta?.arg?.cost_unit;
        if (!userId || (costUnit ?? null) === null) return;

        const user = state?.usersById?.[userId];
        if (!user) return;

        if (action?.payload?.cost_unit === undefined) return;

        user.cost_unit = action.payload.cost_unit;

        if (state.costUnits.filter((cu) => cu.value === action.payload.cost_unit).length === 0) {
          state.costUnits.push({value: action.payload.cost_unit, label: action.payload.cost_unit});
        }

        state.updateUserCostUnitStatuses[userId] = "idle";
      })
      .addCase(updateUserCostUnit.rejected, (state, action) => {
        const userId = action?.meta?.arg?.user_id;
        if (!userId) return;

        state.updateUserCostUnitStatuses[userId] = "error";
      })

      .addCase(removeUserFromCompany.pending, (state, action) => {
        const userId = action?.meta?.arg?.userId;
        if (!userId) return;

        state.removeUserFromCompanyStatuses[userId] = "saving";
      })
      .addCase(removeUserFromCompany.fulfilled, (state, action) => {
        const userId = action?.meta?.arg?.userId;
        if (!userId) return;

        state.showRemoveUserFromCompanyModal = false;
        state.openUserId = null;
        state.removeUserFromCompanyStatuses[userId] = "idle";
      })
      .addCase(removeUserFromCompany.rejected, (state, action) => {
        const userId = action?.meta?.arg?.userId;
        if (!userId) return;

        state.removeUserFromCompanyStatuses[userId] = "idle";
      })

      .addCase(sendInvite.pending, (state) => {
        state.sendInviteStatus = "sending";
      })
      .addCase(sendInvite.fulfilled, (state) => {
        state.sendInviteStatus = "idle";
        state.showAddNewUserModal = false;
      })
      .addCase(sendInvite.rejected, (state) => {
        state.sendInviteStatus = "error";
      })

      .addCase(restrictShares.pending, (state, action) => {
        let userIds = action?.meta?.arg?.userIds;
        if (!userIds) {
          // missing (selected) userIds would be set in thunk,
          // but this is run before it, so they are needed to be set here
          userIds = Object.keys(state.userList.selectedUsers)
        }
        if (!userIds) return;

        userIds.forEach((userId) => {
          state.guestUserStatuses[userId] = "updating";
        });
      })
      .addCase(restrictShares.fulfilled, (state, action) => {
        const newLevel = action?.meta?.arg?.newLevel;
        if (!newLevel) return;

        let userIds = action?.meta?.arg?.userIds;
        if (!userIds) return;

        const selectedUsersCount = userIds.length;

        if (selectedUsersCount > 0 && newLevel === "none") {
          clearSelectedUsers(state);

          userIds.forEach((userId) => {
            if (state.guestUserStatuses[userId]) {
              delete state.guestUserStatuses[userId];
            }
          });
        } else {
          userIds.forEach((userId) => {
            state.guestUserStatuses[userId] = "idle";
          });
        }
      })
      .addCase(restrictShares.rejected, (state, action) => {
        let userIds = action?.meta?.arg?.userIds;
        if (!userIds) return;

        userIds.forEach((userId) => {
          state.guestUserStatuses[userId] = "idle";
        });
      })

      .addCase(getUserObjects.pending, (state) => {
        state.getUserObjectsStatus = "loading";
      })
      .addCase(getUserObjects.fulfilled, (state, action) => {
        state.userObjects = action.payload.body;
        state.getUserObjectsStatus = "idle";
      })
      .addCase(getUserObjects.rejected, (state) => {
        state.getUserObjectsStatus = "error";
      })
  }
});

export const {
  resetState,
  setSelectedUserListTab,
  setUserListSearchStr,
  setUserListSortOptions,

  setUserSelected,
  setUserDeselected,
  setAllVisibleUsersSelected,
  setAllUsersDeselected,

  setShowAddNewUserModal,
  toggleRemoveUserFromCompanyModal,
  toggleMoreInfoModal,
  toggleShowUserObjectsModal,

  resetUserObjectsModalStatus,
} = usersSlice.actions;

export const selectAllUsers = (state) => state.users.allUsers;
export const selectCompanyUsers = (state) => state.users.users;
export const selectUserById = (state, id) => {
  if (id === undefined) {
    throw new Error("Can't select user by id. Id was missing.");
  }

  return state?.users?.usersById?.[id] || null;
}
export const selectUsersCount = (state, tabName) => {
  return tabName === "users" ? state.users.companyUserCount : state.users.guestUserCount;
}

export const selectEditor = (state, id) => {
  return state?.users?.usersById?.[id]?.editor || null;
}
export const selectAdmin = (state, id) => {
  return state?.users?.usersById?.[id]?.admin || null;
}

export const selectPrices = (state) => state.users.prices;
export const selectShowCostUnit = (state) => {
  const { prices } = state.users;
  return prices?.basePrice === 0 && prices?.userCountInBasePrice === 0;
};
export const selectUserPricesCurrency = (state) => state.users.prices?.currency;
export const selectRestrictedViewWithoutVAT = (state) => state.users.prices?.restrictedViewWithoutVAT;
export const selectRestrictedEditWithoutVAT = (state) => state.users.prices?.restrictedEditWithoutVAT;
export const selectFetchUsersStatus = (state) => state.users.fetchUsersStatus;
export const selectCostUnits = (state) => state.users.costUnits;

export const selectOwnerCompanyUsers = (state, objectId) => state.users.ownerCompanyData[objectId]?.users || null;
export const selectOwnerCompanyPrices = (state, objectId) => state.users.ownerCompanyData[objectId]?.prices || null;

export const selectVisibleUserIds = (state) => state.users.userList.visibleUserIds;
export const selectSelectedUsersTab = (state) => state.users.userList.selectedTab;
export const selectUserListSearchStr = (state) => state.users.userList.searchStr;
export const selectUserListSortOrder = (state) => state.users.userList.sort.order;
export const selectUserListSortKey = (state) => state.users.userList.sort.key;
export const selectIsUserSelected = (state, userId) => {
  return !!state.users.userList.selectedUsers[userId]
}
export const selectIsAllVisibleUsersSelected = (state) => state.users.userList.allVisibleUsersSelected;
export const selectSelectedUserCount = (state) => Object.keys(state.users.userList.selectedUsers).length

export const selectOpenUserId = (state) => state.users.openUserId;

export const selectSendInviteStatus = (state) => state.users.sendInviteStatus;
export const selectShowAddNewUserModal = (state) => state.users.showAddNewUserModal;
export const selectShowMoreInfoModal = (state) => state.users.showMoreInfoModal;
export const selectShowRemoveUserFromCompanyModal = (state) =>  state.users.showRemoveUserFromCompanyModal;
export const selectShowUserObjectsModal = (state) => state.users.showUserObjectsModal;
export const selectUserObjects = (state) => state.users.userObjects;

export const selectUserEditorCheckboxStatus = (state, userId) => {
  return state.users.userEditorCheckboxStatuses?.[userId] ?? null;
}
export const selectUserAdminCheckboxStatus = (state, userId) => {
  return state.users.userAdminCheckboxStatuses?.[userId] ?? null;
}
export const selectUpdateUserCostUnitStatuses = (state, userId) => {
  return state.users.updateUserCostUnitStatuses?.[userId] ?? null;
}
export const selectRemoveUserFromCompanyStatus = (state, userId) => {
  return state.users.removeUserFromCompanyStatuses?.[userId] ?? null;
}
export const selectGetUserObjectsStatus = (state) => state.users.getUserObjectsStatus;
export const selectGuestUserStatus = (state, userId) => {
  return state.users.guestUserStatuses?.[userId] ?? null;
}
export const selectIsGuestUsersUpdateInProgress = (state) => {
  return Object.values(state.users.guestUserStatuses).includes('updating');
}

export default usersSlice.reducer;
