import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './subscriptionAPI';
import * as customerTypes from './customerTypes';
import * as companySlice from '../company/companySlice';
import { toast } from 'react-toastify';

const initialState = {
  billingStatus: 'idle',
  paymentMethodStatus: 'idle',
  enterpriseSetupStatus: 'idle',
  plans: null,
  subscription: null,
  selectedPlan: { id: 'pro', name: 'Pro', pricePerUser: '' },
  allCountries: [],
  euCountries: [],
  eInvoiceOperators: [],
  billingObject: {
    country: "",
    confirmedLocation: "",
    vatId: "",
    vatStatus: "",
    buyerName: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    emailAddress: "",
    billingMethod: "card", // card or e-invoicing
    card: { isSet: false, last4: "", expYear: "", expMonth: "" },
    eInvoiceAddress: { isSet: false, address: "", operator: "" },
    invoicingEmail: { isSet: false, email: "" }
  },
  vatOk: true,
  ipCountryCode: null,
  saveMsg: {type: "", msg: ""},
  billingMethodToUpdate: null,
  stripeClientSecret: null,
  gotoConfirmation: false,
  subscriptionActivated: false,
  stripePublishableKey: "",
  invoicesWithPaymentData: [],
  cancelSubscriptionStatus: 'idle',
  sendGoogleEvents: true,
  enterpriseDetails: {
    subscriptionPlan: {},
    startingFeeData: {},
  }
};

export const fetchWebshopPlans = createAsyncThunk(
  'subscription/fetchWebshopPlans',
  async ( params, { dispatch }) => {
    const response = await api.fetchWebshopPlans();
    if (!params?.skipSubscriptionFetch) {
      dispatch(fetchSubscription());
    }
    return response.body;
  }
);

export const fetchSubscription = createAsyncThunk(
  'subscription/fetchSubscription',
  async (planId) => {
    const response = await api.fetchSubscription(planId);
    return response.body;
  }
);

export const getCountryData = createAsyncThunk(
  'subscription/getCountryData',
  async () => {
    const response = await api.getCountryData();
    return response.body;
  }
);

export const getIPLocation = createAsyncThunk(
  'subscription/getIPLocation',
  async () => {
    const response = await api.getIPLocation();
    return response.body;
  }
);

export const getEInvoiceOperators = createAsyncThunk(
  'subscription/getEInvoiceOperators',
  async () => {
    const response = await api.getEInvoiceOperators();
    return response.body;
  }
);

export const getBillingDetails = createAsyncThunk(
  'subscription/getBillingDetails',
  async () => {
    const response = await api.getBillingDetails();
    return response.body;
  }
);

export const attachCardToCustomer = createAsyncThunk(
  'subscription/attachCardToCustomer',
  async (params) => {
    const response = await api.attachCardToCustomer(params);
    return response.body;
  }
);

export const updateBillingMethod = createAsyncThunk(
  'subscription/updateBillingMethod',
  async (params) => {
    const response = await api.updateBillingMethod(params);
    return response.body;
  }
);

export const saveBillingDetails = createAsyncThunk(
  'subscription/saveBillingDetails',
  async (params) => {
    const response = await api.saveBillingDetails(params.billingObject);
    return response.body;
  }
);

export const stripeCardSetupIntentSecret = createAsyncThunk(
  'subscription/stripeCardSetupIntentSecret',
  async (params) => {
    const response = await api.stripeCardSetupIntentSecret(params);
    return response.body;
  }
);

export const activateSubscription = createAsyncThunk(
  'subscription/activateSubscription',
  async (params, { dispatch }) => {
    const response = await api.activateSubscription(params);
    dispatch(companySlice.getUserCompany());
    return response.body;
  }
);

export const fetchPayments = createAsyncThunk(
  'subscription/fetchPayments',
  async () => {
    const response = await api.fetchPayments();
    return response.body;
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancelSubscription',
  async (_, {dispatch}) => {
    const response = await api.cancelSubscription();
    dispatch(fetchSubscription());
    return response.body;
  }
);

export const getEnterpriseDetails = createAsyncThunk(
  'subscription/getEnterpriseDetails',
  async () => {
    const response = await api.getEnterpriseDetails();
    return response.body;
  }
);

export const updateEnterprisePlan = createAsyncThunk(
  'subscription/updateEnterprisePlan',
  async (params, {dispatch}) => {
    const response = await api.updateEnterprisePlan(params);
    dispatch(companySlice.getUserCompany());
    return response.body;
  }
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;

      console.debug("state.selectedPlan = "+JSON.stringify(state.selectedPlan));
    },
    setAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    setSaveMsg: (state, action) => {
      state.saveMsg = action.payload;
    },
    setBillingObject: (state, action) => {
      state.billingObject = action.payload;
    },
    setBillingMethodToUpdate: (state, action) => {
      state.billingMethodToUpdate = action.payload;
    },
    setGotoConfirmation: (state, action) => {
      state.gotoConfirmation = action.payload;
    },
    resetCompanyAdminSubscriptionState: (state) => {
      state.status = undefined;
      state.fetchPaymentsStatus = undefined;
      state.subscription = null;
    },
    resetSubscriptionActivated: (state) => {
      state.subscriptionActivated = false;
    },
    resetBillingView: (state) => {
      state.billingObject.confirmedLocation = initialState.billingObject.confirmedLocation;
      state.vatOk = initialState.vatOk;
      state.saveMsg = initialState.saveMsg;
    },
    setEnterpriseSubscriptionPlan: (state, action) => {
      state.enterpriseDetails.subscriptionPlan = action.payload;
    },
    setEnterpriseStartingFeeData: (state, action) => {
      state.enterpriseDetails.startingFeeData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebshopPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWebshopPlans.fulfilled, (state, action) => {
        state.plans = action.payload.plans;
        state.status = 'idle';
      })
      .addCase(fetchWebshopPlans.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload.data;
        if (state.plans && state.plans[action.payload.data.planIdentifier]) {
          state.selectedPlan = state.plans[action.payload.data.planIdentifier];
        } else {
          state.selectedPlan = { id: 'unknown', name: 'Unknown', pricePerUser: '' };
        }

        if (state.subscription?.status && state.subscription.status !== "trialing") {
          state.sendGoogleEvents = false;
        }

        state.status = 'idle';
      })
      .addCase(fetchSubscription.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getCountryData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCountryData.fulfilled, (state, action) => {
        state.euCountries = action.payload.eu_countries;
        state.status = 'idle';
      })
      .addCase(getCountryData.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getIPLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getIPLocation.fulfilled, (state, action) => {
        state.ipCountryCode = action.payload.ip_country_code;
        state.status = 'idle';
      })
      .addCase(getIPLocation.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getEInvoiceOperators.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEInvoiceOperators.fulfilled, (state, action) => {
        state.eInvoiceOperators = action.payload.operators.map((o) => { return { label: o.name, value: o.operator } });
        state.status = 'idle';
      })
      .addCase(getEInvoiceOperators.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getBillingDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBillingDetails.fulfilled, (state, action) => {
        let d = action.payload.billing_details;
        // Why we are doing this kind of mess?
        // Sometimes we have billingMethod and sometimes paymentMethod
        // Why just don't use data coming from BE without variable name changes here? No time to fix this now...
        // 7.9.22 Have to fix this little bit now anyway, maybe someday this mess is cleaned totally...

        // 21.6.23 Don't try to fix ^ :)
        // Old client billing and subscription views were buggy right from the start. They were fixed quickly to get them into production. There was no time to make it right.
        // New ui with trial->subscription flow introduced many new bugs, because the code was already clumsy.
        // The rest of the subscription and billing views were done now. And everything is fixed to follow the old client logic.
        // Some names are bad and some stupidity is done. But at least it might work as good as it worked on the old client.

        let newBillingObject = {};

        newBillingObject.is_valid = d.is_valid;

        let country_code;
        if (d.country_code) {
          country_code = d.country_code;
        }
        if (d.ip_country_code) {
          country_code = d.ip_country_code;
          state.ipCountryCode = country_code;
        }
        if (country_code) {
          newBillingObject.country = state.allCountries.filter(c => c.value == country_code)[0];
        }

        if (d.customer_type &&
          Number(d.customer_type) >= customerTypes.CUSTOMER_TYPE_PERSONAL &&
          Number(d.customer_type) <= customerTypes.CUSTOMER_TYPE_BUSINESS_NO_VAT
        ) {
          newBillingObject.vatStatus = d.customer_type;
        }
        if (d.vat_number) {
          newBillingObject.vatId = d.vat_number;
        }
        if (d.buyer_name) {
          newBillingObject.buyerName = d.buyer_name;
        }
        if (d.street_address) {
          newBillingObject.streetAddress = d.street_address;
        }
        if (d.postal_code) {
          newBillingObject.postalCode = d.postal_code;
        }
        if (d.city) {
          newBillingObject.city = d.city;
        }
        if (d.email_address) {
          newBillingObject.emailAddress = d.email_address;
        }

        if (d.payment_method == "stripe.card") {
          newBillingObject.billingMethod = 'card';
          if (d.card && d.card.last4 && d.card.exp_year && d.card.exp_month) {
            newBillingObject.card = {
              isSet: true,
              last4: d.card.last4,
              expYear: d.card.exp_year,
              expMonth: d.card.exp_month
            };
          }
        }
        else if (d.payment_method == "procountor.e-invoice") {
          newBillingObject.billingMethod = 'e-invoicing';
          newBillingObject.eInvoiceAddress = {isSet: true, address: d.einvoice_address, operator: {value: d.einvoice_operator}};
        }
        else if (d.payment_method == "procountor.invoice-email") {
          newBillingObject.billingMethod = 'email';
          newBillingObject.invoicingEmail = {isSet: true, email: d.email_address};
        }

        let stripePublishableKey = "";
        if (action.payload.stripe_pk) {
          stripePublishableKey = action.payload.stripe_pk;
        }

        state.status = 'idle';
        state.billingObject = { ...state.billingObject, ...newBillingObject };
        state.stripePublishableKey = stripePublishableKey;
      })
      .addCase(getBillingDetails.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(attachCardToCustomer.pending, (state) => {
        state.status = 'loading';
        state.paymentMethodStatus = 'loading';
      })
      .addCase(attachCardToCustomer.fulfilled, (state, action) => {
        if (action.payload.card) {
          state.billingObject.card = {
            isSet: true,
            last4: action.payload.card.last4,
            expYear: action.payload.card.exp_year,
            expMonth: action.payload.card.exp_month,
          };
        }

        state.status = 'idle';
        state.paymentMethodStatus = 'idle';
      })
      .addCase(attachCardToCustomer.rejected, (state) => {
        state.status = 'error';
        state.paymentMethodStatus = 'error';
      })
      .addCase(updateBillingMethod.pending, (state) => {
        state.status = 'loading';
        state.paymentMethodStatus = 'loading';
      })
      .addCase(updateBillingMethod.fulfilled, (state, action) => {
        state.billingObject[state.billingMethodToUpdate] = {...action.meta.arg.value, isSet: true};
        
        if (state.billingObject.billingMethod === "email") {
          state.billingObject['emailAddress'] = action.meta.arg.value.email; // sync 'invoice via email' -payment method email address with the upper form emailAddress
          // (Both are the same billing_info.BuyerEmailaddressIdentifier)
        }

        state.status = 'idle';
        state.paymentMethodStatus = 'idle';
      })
      .addCase(updateBillingMethod.rejected, (state) => {
        state.status = 'error';
        state.paymentMethodStatus = 'error';
      })
      .addCase(saveBillingDetails.pending, (state) => {
        state.billingStatus = 'loading';
      })
      .addCase(saveBillingDetails.fulfilled, (state, action) => {
        state.billingStatus = 'idle';

        if (action.payload.vat_number_status >= 0) {
          state.saveMsg.type = "ok";
          state.saveMsg.msg = { id: 'common.savingSucceeded', defaultMessage: 'Saving succeeded' };
          state.vatOk = true;
        }
        else if (action.payload.vat_number_status == -1) {
          state.saveMsg.type = "warning";
          state.saveMsg.msg = { id: 'billing.saveSucceededVatMightBeInvalid2', defaultMessage: 'Saving succeeded but the VAT number seems to be invalid. Reverse charge procedure will not be applied. Check the VAT number and retry.' };
          state.vatOk = false;
        }
        else {
          state.saveMsg.type = "warning";
          state.saveMsg.msg = { id: 'billing.saveSucceededVatCheckFailed2', defaultMessage: 'Saving succeeded but the VAT number check failed. Reverse charge procedure will not be applied. Check the VAT number and retry.' };
          state.vatOk = false;
        }

        if (action.meta.arg.redirectUrl) {
          //const pathName = window.location;
          // TODO: Plan is not saved into database, redirect must do other way that we dont reset plan
          //window.location.replace(action.meta.arg.redirectUrl);
          state.gotoConfirmation = true;
        }

        if (action.meta.arg.toastMessage) {
          toast.success(action.meta.arg.toastMessage);
        }
      })
      .addCase(saveBillingDetails.rejected, (state, action) => {
        state.billingStatus = 'error';

        state.saveMsg.type = "error";
        state.saveMsg.msg = { id: 'error.saveFailed', defaultMessage: 'Saving failed' };

        if (action?.error?.message) { //action.payload is undefined when user is offline and tries to save billing details
          let ec = Number(action.error.message);
          if (ec <= -10 && ec > -20) { // -1x
            state.saveMsg.msg = { id: 'billing.saveFailedInvalidVAT', defaultMessage: 'Saving failed. VAT number is invalid.' };
          }
          else if (ec <= -20 && ec > -30) { // -2x
            state.saveMsg.msg = { id: 'billing.saveFailedInvalidLocation', defaultMessage: 'Saving failed. Provided location details are invalid.' };
          }
          else {
            state.saveMsg.msg = { id: 'error.saveFailed', defaultMessage: 'Saving failed' };
          }
        }
      })
      .addCase(stripeCardSetupIntentSecret.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(stripeCardSetupIntentSecret.fulfilled, (state, action) => {
        state.status = 'idle';

        state.stripeClientSecret = action.payload.client_secret;
      })
      .addCase(stripeCardSetupIntentSecret.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(activateSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(activateSubscription.fulfilled, (state) => {
        state.status = 'idle';

        state.subscriptionActivated = true;
      })
      .addCase(activateSubscription.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchPayments.pending, (state) => {
        state.fetchPaymentsStatus = "updating";
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.invoicesWithPaymentData = action.payload.invoicesWithPaymentData;
        state.fetchPaymentsStatus = "idle";
      })
      .addCase(fetchPayments.rejected, (state, action) => {
        state.invoicesWithPaymentData = action.payload.invoicesWithPaymentData;
        state.fetchPaymentsStatus = "error";
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.cancelSubscriptionStatus = 'canceling';
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.cancelSubscriptionStatus = 'idle';
      })
      .addCase(cancelSubscription.rejected, (state) => {
        state.cancelSubscriptionStatus = 'idle';
      })
      .addCase(getEnterpriseDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEnterpriseDetails.fulfilled, (state, action) => {
        state.status = 'idle';
        state.enterpriseDetails.subscriptionPlan = action.payload.subscriptionPlan;
        state.enterpriseDetails.startingFeeData = action.payload.startingFeeData;
      })
      .addCase(getEnterpriseDetails.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(updateEnterprisePlan.pending, (state) => {
        state.enterpriseSetupStatus = 'loading';
      })
      .addCase(updateEnterprisePlan.fulfilled, (state, action) => {
        state.enterpriseSetupStatus = 'idle';
        state.enterpriseDetails.subscriptionPlan = action.payload.subscriptionPlan;
        state.enterpriseDetails.startingFeeData = action.payload.startingFeeData;
      })
      .addCase(updateEnterprisePlan.rejected, (state) => {
        state.enterpriseSetupStatus = 'idle';
      })
  }
});


export const {
  setSelectedPlan,
  setAllCountries,
  setSaveMsg,
  setBillingObject,
  setBillingMethodToUpdate,
  setGotoConfirmation,
  resetCompanyAdminSubscriptionState,
  resetSubscriptionActivated,
  resetBillingView,
  setEnterpriseSubscriptionPlan,
  setEnterpriseStartingFeeData,
} = subscriptionSlice.actions;

export const selectStatus = (state) => state.subscription.status;
export const selectBillingStatus = (state) => state.subscription.billingStatus;
export const selectPaymentMethodStatus = (state) => state.subscription.paymentMethodStatus;
export const selectEnterpriseSetupStatus = (state) => state.subscription.enterpriseSetupStatus;
export const selectPlans = (state) => state.subscription.plans;
export const selectSubscription = (state) => state.subscription.subscription;
export const selectSubscriptionStatus = (state) => state.subscription.subscription?.status;
export const selectSelectedPlan = (state) => state.subscription.selectedPlan;
export const selectCancelSubscriptionStatus = (state) => state.subscription.cancelSubscriptionStatus;

export const selectBillingObject = (state) => state.subscription.billingObject;
export const selectStripePublishableKey = (state) => state.subscription.stripePublishableKey;
export const selectIpCountryCode = (state) => state.subscription.ipCountryCode;
export const selectAllCountries = (state) => state.subscription.allCountries;
export const selectEuCountries = (state) => state.subscription.euCountries;
export const selectEInvoiceOperators = (state) => state.subscription.eInvoiceOperators;
export const selectSaveMsg = (state) => state.subscription.saveMsg;
export const selectBillingMethodToUpdate = (state) => state.subscription.billingMethodToUpdate;
export const selectVatOk = (state) => state.subscription.vatOk;
export const selectStripeClientSecret = (state) => state.subscription.stripeClientSecret;
export const selectGotoConfirmation = (state) => state.subscription.gotoConfirmation;
export const selectSubscriptionActivated = (state) => state.subscription.subscriptionActivated;
export const selectSubscriptionCancelAtPeriodEnd = (state) => state.subscription.subscription?.cancelAtPeriodEnd;
export const selectSubscriptionCurrency = (state) => state.subscription.subscription?.currency;
export const selectSubscriptionCancelsAt = (state) => state.subscription.subscription?.cancelsAt;
export const selectInvoicesWithPaymentData = (state) => state.subscription.invoicesWithPaymentData;
export const selectFetchPaymentsStatus = (state) => state.subscription.fetchPaymentsStatus;
export const selectEnterpriseSubscriptionPlan = (state) => state.subscription.enterpriseDetails?.subscriptionPlan;
export const selectEnterpriseStartingFeeData = (state) => state.subscription.enterpriseDetails?.startingFeeData;

export const selectSendGoogleEvents = (state) => state.subscription.sendGoogleEvents;

export default subscriptionSlice.reducer;
