import { apiCall, API } from '../../utils/APIFunctions';

export function fetch(opts = {}) {
  let partOne = "";
  let currentObjectId = "";
  if (opts) {
    if (opts.partOne !== undefined) partOne = "partOne=" + opts.partOne + "&";
    if (opts.currentObjectId !== undefined) currentObjectId = "currentObjectId=" + opts.currentObjectId + "&";
  }

  return apiCall(`${API}/kp_objects?${partOne}${currentObjectId}getKeyed=true&superView=1`, "GET");
}

export function searchFromElastic(params) {
  return apiCall(`${API}/elasticsearch/objects/search?q=${params.q}&size=${params.size}&from=${params.from}`,"GET");
}

export function fetchChanged(lastUpdatedTimestamp) {
  return apiCall(`${API}/kp_objects?getKeyed=true&updatedAfter=${lastUpdatedTimestamp}&superView=1`, "GET");
}

export function deleteObject(id) {
  if (!id) {
    return false;
  }

  return apiCall(`${API}/kp_objects/${id}`, "DELETE");
}

export function moveObject(objectId, targetObjectId, copyParentRights) {
  const data = { target_id: targetObjectId, copy_parent_rights: copyParentRights };
  return apiCall(`${API}/kp_objects/${objectId}/moveToFolder`, "put", JSON.stringify(data));
}

export function createDocument(data) {
  return apiCall(`${API}/o/documents`, "post", JSON.stringify(data));
}

export function createFolder(data) {
  return apiCall(`${API}/o/folders`, "post", JSON.stringify(data));
}

export function saveObject(data) {
  return apiCall(`${API}/kp_objects/${data.id}`, "put", JSON.stringify(data));
}

export function savePropagateViewOnShare(data) {
  return apiCall(`${API}/kp_objects/${data.id}/propagate_view_on_share`, "post", JSON.stringify({ propagateViewOnShare: data.value }));
}

export function updateFolderSettings(data) {
  return apiCall(`${API}/kp_objects/${data.id}/folderSettings`, "put", JSON.stringify(data));
}

export function fetchFolderSettings() {
  return apiCall(`${API}/folderSettings`, "GET");
}

export function saveObjectTag(objectId, tagId, tagValue) {
  return apiCall(`${API}/kp_objects/${objectId}/tags/${tagId}`,"put", JSON.stringify(tagValue));
}

export function fetchFavoriteObjects() {
  return apiCall(`${API}/favorite_objects`, "GET");
}

export function addFavoriteObject(objectId) {
  return apiCall(`${API}/favorite_objects/${objectId}`, "POST");
}

export function removeFavoriteObject(objectId) {
  return apiCall(`${API}/favorite_objects/${objectId}`, "DELETE");
}

export function fetchMyObjects() {
  return apiCall(`${API}/my_objects`, "GET");
}