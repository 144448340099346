import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './doctemplatesAPI';

const initialState = {
  status: 'idle',
  listOrder: { type: 'latest', order: 'asc' },
  templateList: [],
  latestTemplateList: [],
  selectedTemplate: null
};

export const fetchTemplatesByType = createAsyncThunk(
  'objects/fetchTemplatesByType',
  async (params) => {
    const response = await api.fetchTemplatesByType(params.type, params.parentId);
    return response.body;
  }
);

export const doctemplatesSlice = createSlice({
  name: 'doctemplates',
  initialState,
  reducers: {
    // NOTE: Server wants template_id to be comp_doctemplates.id (on document creation), and it's available in template.enabled
    selectTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    setListOrder: (state, action) => {
      state.listOrder = action.payload;
    },
    resetTemplates: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplatesByType.pending, (state) => {
        state.templateStatus = 'loading';
      })
      .addCase(fetchTemplatesByType.fulfilled, (state, action) => {
        let originalTemplates = [...action.payload.results];
        let newTemplateList = [];
        let newTemplates = [];

        if (action.payload?.latest && action.payload.latest.length > 0) {
          let latestTemplates = action.payload.latest;

          latestTemplates.forEach((template) => {
            let field = template.enabled ? 'enabled' : 'id';
            // Find index and content of latest template
            let pos = originalTemplates.findIndex(arr => arr[field] === template[field]);
            let row = originalTemplates[pos];
            
            // Remove latest template from old array and add to a new array
            originalTemplates.splice(pos, 1);
            newTemplates.push(row);
          })
        }

        originalTemplates.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);

        // Add remaining templates to the end of new array
        newTemplateList = newTemplates.concat(originalTemplates);

        state.templateStatus = 'idle';
        state.templateList = action.payload.results;
        state.latestTemplateList = newTemplateList;

      })
      .addCase(fetchTemplatesByType.rejected, (state) => {
        state.templateStatus = 'error';
      });
  },
});

export const {
  selectTemplate, setListOrder, resetTemplates
} = doctemplatesSlice.actions;

export const selectListOrder = (state) => state.doctemplates.listOrder;
export const selectTemplateList = (state) => state.doctemplates.templateList;

export default doctemplatesSlice.reducer;
