import KPConfig from "../KPConfig";

export const folderObjectIdPath = KPConfig.publicUrl + "/folders/:folderObjectId";

export const objectIdPaths = [
  KPConfig.publicUrl + "/folders/:folderObjectId",
  KPConfig.publicUrl + "/folders/:folderObjectId/documents/new",
  KPConfig.publicUrl + "/documents/:objectId",
  KPConfig.publicUrl + "/journals/:objectId",
  KPConfig.publicUrl + "/journals/:objectId/entries/:entryId",
  KPConfig.publicUrl + "/metrics/:objectId",
  KPConfig.publicUrl + "/metrics/:objectId/measurements/:measurementId",
  KPConfig.publicUrl + "/floor_plans/:objectId",
  KPConfig.publicUrl + "/templates/:documentId",
  KPConfig.publicUrl + "/floor_plans_fullscreen/:objectId"
];