import * as NodeProcessFunctions from './NodeProcessFunctions';
import * as NodeTypes from './NodeTypes';

export const recursiveFindNode = (data, nodeId) => {
  if (data.id === nodeId) return data;

  if (!data.nodes) return null;

  for (let node of data.nodes) {
    const found = recursiveFindNode(node, nodeId);
    if (found) {
      return found;
    }
  }

  if (data.macros) {
    for (let macro of Object.values(data.macros)) {
      if (macro.node) {
        const foundFromMacro = recursiveFindNode(macro.node, nodeId);
        if (foundFromMacro) {
          return foundFromMacro;
        }
      }
    }
  }
  return null;
};

export const recursiveFindMacroSectionNodes = (data, macroId, foundNodes) => {
  if (!data.nodes) return foundNodes;

  for (let node of data.nodes) {
    if (node.type === 'macro-section' && node.macroId === macroId) {
      foundNodes.push(node);
    }
    recursiveFindMacroSectionNodes(node, macroId, foundNodes);
  }

  if (data.macros) {
    for (let macro of Object.values(data.macros)) {
      if (macro.node) {
        recursiveFindMacroSectionNodes(macro.node, macroId, foundNodes);
      }
    }
  }

  return foundNodes;
}

export const collectNodeCopyData = (node) => {
  // count depth. Copied from old client. Maybe easiest to just use this
  let collectedData = { maxLevel: 0, deepestSectionLevel: 0, hasMacroSection: false };
  let collectNodeData = (node, collectedData, myLevel) => {
    if (node.type === 'macro-section') {
      collectedData.hasMacroSection = true;
    }
    if (node.type === 'section' && myLevel > collectedData.deepestSectionLevel) {
      collectedData.deepestSectionLevel = myLevel;
    }
    if (!node.nodes || !node.nodes.length) {
      return;
    }
    else {
      myLevel++;
      if (myLevel > collectedData.maxLevel) {
        collectedData.maxLevel = myLevel;
      }
    }

    if (myLevel > 5) {
      collectedData.tooDeep = true;
      return;
    }

    for (let n of node.nodes) {
      collectNodeData(n, collectedData, myLevel);
    }
    return;
  }

  collectNodeData(node, collectedData, 0);
  return collectedData;
}

// Message is used in toaster
export const isPasteDisabled = (targetNodeType, targetParentNodeType, targetNodeLevel, clipboard, mode, intl) => {
  if (clipboard?.data?.type === 'image') {
    return intl.formatMessage({ id: 'template.errorMsg.To paste a picture, you need to paste the entire image section', defaultMessage: 'To paste a picture, you need to paste the entire image section' });
  }

  const isInMacroSection = targetParentNodeType === 'macro-section' || clipboard.data.isInMacroSection;

  if (isInMacroSection && clipboard.hasMacroSection) {
    return intl.formatMessage({ id: "template.errorMsg.Repetitive sections can't be pasted inside another repetitive section", defaultMessage: "Repetitive sections can't be pasted inside another repetitive section" });
  }

  // Disallow pasting over macro-section
  if (targetNodeType === 'macro-section' && mode === 'replace') {
    return intl.formatMessage({ id: 'template.errorMsg.Pasting over repetitive section is not allowed', defaultMessage: 'Pasting over repetitive section is not allowed' });
  }

  // Limit allowed element types for field-set
  if (targetParentNodeType === 'field-set') {
    if (clipboard.data.type !== 'input-textfield' &&
      clipboard.data.type !== 'input-checkbox' &&
      clipboard.data.type !== 'input-select') {
      return intl.formatMessage({ id: 'template.errorMsg.Copied element type is not allowed to be pasted here', defaultMessage: 'Copied element type is not allowed to be pasted here' });
    }
  }

  // Fix 1st level inside macro-section to be considered as one level closer to root (this allows pasting deep structures e.g. sections inside macro-sections)
  if (isInMacroSection) {
    // TODO: Is this needed, we have already adjusted level before?
    targetNodeLevel--;
  }

  // First element inside macro-section must be a section
  if (targetParentNodeType === 'macro-section' && clipboard.data.type !== 'section') {
    return intl.formatMessage({ id: 'template.errorMsg.Only section element is allowed to be pasted here', defaultMessage: 'Only section element is allowed to be pasted here' });
  }

  if (clipboard.nodeHelperVariables?.nodeLevel < targetNodeLevel) { // skip these checks if pasting to copy level or closer to root
    // Don't allow pasting section element too deep
    if (clipboard.data.type === 'section' && targetNodeLevel + clipboard.deepestSectionLevel > 2) {
      if (targetNodeLevel <= 2 && clipboard.deepestSectionLevel > 0) {
        return intl.formatMessage({ id: "template.errorMsg.Inner section element would be too deep", defaultMessage: "Inner section element would be too deep" });
      }
      else {
        return intl.formatMessage({ id: "template.errorMsg.Section elements can't be pasted this deep", defaultMessage: "Section elements can't be pasted this deep" });
      }
    }

    // Don't allow creating too deep structures
    if ((clipboard.nodeLevel + targetNodeLevel) > 4) {
      return intl.formatMessage({ id: 'template.errorMsg.Final structure would be too deep', defaultMessage: 'Final structure would be too deep' });
    }
  }

  return false;
}

export const updateChildNodeIds = (node) => {
  if (node.id) {
    node.id = Math.random().toString(36).substring(2, 14);
  }
  if (node.nodes) {
    // Remove "New file" or "New image" that is not yet saved into database. User can't see those pasted nodes in UI.
    // This fixes undefined file_id on document create. New file is still saved in original node.
    node.nodes = node.nodes.filter(n => !((n?.type === 'file' && !n?.file_id) || (n?.type === 'image' && !n?.filename)));
    node.nodes.forEach((n) => updateChildNodeIds(n));
  }
}

export const addNode = (state, action) => {
  // List parameters on top to make this clearer
  const parentId = action.payload.parentId;
  const copyNodeId = action.payload.copyNodeId;
  const copyNode = action.payload.copyNode; // This is whole node from clipboard
  const macroId = action.payload.macroId;
  const index = action.payload.index;
  const type = action.payload.type;
  const path = action.payload.path;

  if (parentId === undefined || index === undefined || (!type && !copyNodeId && !copyNode)) {
    console.error('addNewNode: parentId, index or type not defined');
    return;
  }

  let parentNode = null;

  // In add mode, we give parentId
  if (parentId === 0) {
    parentNode = state.data;
  } else {
    parentNode = recursiveFindNode(state.data, parentId);
  }

  if (!parentNode) {
    console.error('addNewNode: parentNodes not found');
    return;
  }

  const uniq = Math.random().toString(36).substring(2, 14);
  let newNode = null;

  if (copyNode) {
    newNode = JSON.parse(JSON.stringify(copyNode));
    newNode.id = uniq;

    // Node was pasted, need to update all childNode ids also to prevent duplicate ids if node with same children
    // is pasted multiple times.
    updateChildNodeIds(newNode);
  } else if (copyNodeId) {
    // Let's use copyNode really as a new copied node. In old client it was used to move node.
    const copyNode = recursiveFindNode(state.data, copyNodeId);
    if (!copyNode) {
      console.error('addNewNode: copyNode not found by copyNodeId');
      return;
    }
    newNode = JSON.parse(JSON.stringify(copyNode));
    newNode.id = uniq;
  } else {
    newNode = { id: uniq, type: type };

    /**
     * NOTE: Init empty nodes also to macro-section. Old UI will crash without this.
     * Empty nodes is not used in template editor, but it will be used in normal document
     * when sections are added inside macro-section.
     **/
    if (NodeTypes.typesWithNodes.includes(type)) {
      newNode.nodes = [];
    }
    if (NodeTypes.typesWithColumns.includes(type)) {
      newNode.columns = [];
    }
    if (NodeTypes.typesWithDefaultTitle.includes(type)) {
      newNode.title = '';
    }
  }

  if (macroId) {
    newNode.macroId = macroId;
  }

  if (path) {
    path.push(newNode.id);

    state.createdNodePath = path;
  }

  if (!parentNode.nodes) {
    parentNode.nodes = [];
  }

  if (index < 0) {
    parentNode.nodes.unshift(newNode); // .childNodes is based on .nodes in process-functions
  } else {
    parentNode.nodes.splice(index, 0, JSON.parse(JSON.stringify(newNode))); // .childNodes is based on .nodes in process-functions
  }

  state.nodesById[newNode.id] = JSON.parse(JSON.stringify(newNode));
  state.nodesById[newNode.id].wasCreated = true;

  // Node is already in right place, but we must process to set all variables (levelIndex, parentNodeId etc.)
  // And build nodes again from macros
  if (!state.nodesById[parentNode.id]) {
    // parentNode is root (=document)
    NodeProcessFunctions.processNodeStructure(state);
  } else {
    NodeProcessFunctions.processNodeStructuresPartially(state, parentNode);
  }

  state.saveButtonEnabled = true;
  state.nodeAddOpen = {};

  return newNode.id;
}

export const deleteNode = (state, action) => {
  const node = state.nodesById[action.payload.id];
  if (!node) {
    console.error('deleteNode: node not found: ' + action.payload.id);
    return;
  }

  const parentNode = recursiveFindNode(state.data, node.parentNodeId) || state.data;
  if (!parentNode || !parentNode.nodes) {
    console.error('deleteNode: parentNode not found or it hasnt nodes.');
    return;
  }

  const index = parentNode.nodes.findIndex((n) => n.id === node.id);
  if (index < 0) {
    return;
  }

  parentNode.nodes.splice(index, 1);

  if (!state.nodesById[parentNode.id]) {
    // parentNode is root (=document)
    NodeProcessFunctions.processNodeStructure(state);
  } else {
    NodeProcessFunctions.processNodeStructuresPartially(state, parentNode);
  }

  state.saveButtonEnabled = true;
}

export const moveNode = (state, action) => {
  const node = state.nodesById[action.payload.id];
  if (!node) {
    console.error('moveNode: node not found: ' + action.payload.id);
    return;
  }

  const oldParentNode = recursiveFindNode(state.data, node.parentNodeId) || state.data;
  const newParentNode = recursiveFindNode(state.data, action.payload.parentId) || state.data;
  if (!oldParentNode || !oldParentNode.nodes || !newParentNode || !newParentNode.nodes) {
    console.error('moveNode: missing parentNodes or their childNodes.');
    return;
  }

  // First move node to new place
  let newIndex = action.payload.index;
  const copyNode = recursiveFindNode(state.data, action.payload.id);
  if (newIndex < 0) {
    newParentNode.nodes.unshift(copyNode);
    // newIndex: -1 was signal to put node to first place
    // Now node is found from index 0 (and from another place), update newIndex to 0 to skip deleting it from start.
    newIndex = 0;
  } else {
    newParentNode.nodes.splice(newIndex, 0, copyNode);
  }

  // Delete node from old place
  // NOTE: If node was moved to same parentNode where it was, it is currently two times in same parentNode.
  // Have to check that found index is not newIndex.
  const inSameParent = oldParentNode.id === newParentNode.id;
  const index = oldParentNode.nodes.findIndex((n, i) => n.id === node.id && (i !== newIndex || !inSameParent));
  if (index < 0) {
    console.error('moveNode: node not found from oldParentNode.');
    return;
  }

  oldParentNode.nodes.splice(index, 1);

  if (!state.nodesById[newParentNode.id] || !state.nodesById[oldParentNode.id]) {
    // parentNode is root (=document)
    NodeProcessFunctions.processNodeStructure(state);
  } else {
    NodeProcessFunctions.processNodeStructuresPartially(state, newParentNode);
    NodeProcessFunctions.processNodeStructuresPartially(state, oldParentNode);
  }

  state.saveButtonEnabled = true;
}

export const buildRowNodeData = (node) => {
  let rowNodes = [];
  if (node?.columns) {
    let rowCount = 0;
    node.columns.forEach((column) => {
      if (column.rows) {
        rowCount = Math.max(rowCount, column.rows.length);
      }
    });

    for (let i = 0; i < rowCount; i++) {
      const initValues = node.columns.map(() => '');
      rowNodes[i] = {
        type: 'table-row',
        id: Math.random().toString(36).substring(2, 14),
        created_ts: null,
        creator_name: null,
        values: initValues
      };
    }

    node.columns.forEach((column, columnIndex) => {
      if (column.rows) {
        column.rows.forEach((row, rowIndex) => {
          rowNodes[rowIndex].values[columnIndex] = row.label;
        });
      }
    });

    node.nodes = rowNodes;
    return node;
  }
};
