import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarCollapsed: false, // This could be in some new slice
  sidebarShouldCollapse: false,
  navbarAccountOptionsOpen: false,
  navbarAdminbarOpen: false,
  tapbarAdminbarOpen: false,
  tapbarAccountOptionsOpen: false,
  tapbarMoreOpen: false,
  routeChangeCount: 0,
  oldUiTo: null
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action) => {
      if (window.innerWidth > 1400 && action.payload) {
        return;
      }

      if (state.navbarAccountOptionsOpen) {
        state.sidebarCollapsedShouldClose = true;
      } else {
        state.sidebarCollapsed = action.payload;
      }
    },
    setNavbarAccountOptionsOpen: (state, action) => {
      state.navbarAccountOptionsOpen = action.payload;

      if (!action.payload && state.sidebarShouldCollapse) {
        state.sidebarShouldCollapse = false;
        state.sidebarCollapsed = true;
      }
    },
    setTapbarAccountOptionsOpen: (state, action) => {
      state.tapbarAccountOptionsOpen = action.payload;
    },
    setNavbarAdminbarOpen: (state, action) => {
      state.navbarAdminbarOpen = action.payload;
    },
    setTapbarAdminbarOpen: (state, action) => {
      state.tapbarAdminbarOpen = action.payload;
    },
    toggleTapbarMoreOpen: (state) => {
      state.tapbarMoreOpen = !state.tapbarMoreOpen;
    },
    setTapbarMoreOpen: (state, action) => {
      state.tapbarMoreOpen = action.payload;
    },
    incrementRouteChangeCount: (state) => {
      state.routeChangeCount++;
    },
    setOldUiTo: (state, action) => {
      state.oldUiTo = action.payload;
    }
  }
});

export const {
  setSidebarCollapsed,
  incrementRouteChangeCount,
  setNavbarAccountOptionsOpen,
  setTapbarAccountOptionsOpen,
  setNavbarAdminbarOpen,
  setTapbarAdminbarOpen,
  toggleTapbarMoreOpen,
  setTapbarMoreOpen,
  setOldUiTo
} = navigationSlice.actions;

export const selectSidebarCollapsed = (state) => state.navigation.sidebarCollapsed;
export const selectRouteChangeCount = (state) => state.navigation.routeChangeCount;
export const selectNavbarAccountOptionsOpen = (state) => state.navigation.navbarAccountOptionsOpen;
export const selectTapbarAccountOptionsOpen = (state) => state.navigation.tapbarAccountOptionsOpen;
export const selectNavbarAdminbarOpen = (state) => state.navigation.navbarAdminbarOpen;
export const selectTapbarAdminbarOpen = (state) => state.navigation.tapbarAdminbarOpen;
export const selectTapbarMoreOpen = (state) => state.navigation.tapbarMoreOpen;
export const selectOldUiTo = (state) => state.navigation.oldUiTo;

export default navigationSlice.reducer;
