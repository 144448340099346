import KPConfig from "../../KPConfig";

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './sgAdminAPI';
import * as companySlice from '../company/companySlice';

const initialState = {
  status: 'idle',
  adminGroupCompanies: [],
  accessRequestUsers: [],
  accessRequestStatus: 'idle',
};

export const getUserCompanyList = createAsyncThunk(
  'sgAdmin/getUserCompanyList',
  async () => {
    const response = await api.getUserCompanyList();
    return response.body;
  }
);

export const setCurrentCompany = createAsyncThunk(
  'sgAdmin/setCurrentCompany',
  async (companyId) => {
    const response = await api.setCurrentCompany(companyId);
    return response.body;
  }
);

export const getTokenGranters = createAsyncThunk(
  'sgAdmin/getTokenGranters',
  async (companyId) => {
    const response = await api.getTokenGranters(companyId);
    return response.body;
  }
);

export const requestAdminAccess = createAsyncThunk(
  'sgAdmin/requestAdminAccess',
  async (params, { dispatch }) => {
    const response = await api.requestAdminAccess(params);
    dispatch(companySlice.getUserCompany());
    return response.body;
  }
);

export const sgAdminSlice = createSlice({
  name: 'sgAdmin',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCompanyList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserCompanyList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.adminGroupCompanies = action.payload;
      })
      .addCase(getUserCompanyList.rejected, (state) => {
        state.status = 'error';
      })

      .addCase(setCurrentCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setCurrentCompany.fulfilled, (state) => {
        state.status = 'idle';
        window.location.replace(window.location.origin + KPConfig.publicUrl + '/folders');
      })
      .addCase(setCurrentCompany.rejected, (state) => {
        state.status = 'error';
      })

      .addCase(getTokenGranters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTokenGranters.fulfilled, (state, action) => {
        state.status = 'idle';
        state.accessRequestUsers = action.payload;
      })
      .addCase(getTokenGranters.rejected, (state) => {
        state.status = 'error';
      })

      .addCase(requestAdminAccess.pending, (state) => {
        state.accessRequestStatus = 'loading';
      })
      .addCase(requestAdminAccess.fulfilled, (state) => {
        state.accessRequestStatus = 'idle';
      })
      .addCase(requestAdminAccess.rejected, (state) => {
        state.accessRequestStatus = 'error';
      })
  }
});

export const selectAdminGroupCompanies = (state) => state.sgAdmin.adminGroupCompanies;
export const selectAccessRequestUsers = (state) => state.sgAdmin.accessRequestUsers;
export const selectAccessRequestStatus = (state) => state.sgAdmin.accessRequestStatus;

export default sgAdminSlice.reducer;
