import KPConfig from '../KPConfig.js'

export const API = KPConfig.backendUrl + '/api/v2';

export async function processResponseAsync(response, opts) {
  let isOk = response.ok; // true, when response status code is in 200-299
  let body;
  let responseText;

  try {
    responseText = await response.text();
    body = JSON.parse(responseText);
  } catch (err) {
    if (responseText && responseText.replace(/\s/g, "") === "") {
      body = null;
    } else {
      isOk = false;
    }
  }

  let challengeRequired = response.status === 401 && body?.['WWW-Authenticate'] ? body?.['WWW-Authenticate'] : false; // TODO: Replace with header, if you get it work with docker env
  if (response.status === 401 &&
      !challengeRequired &&
      !opts?.skipErrorHandling
  ) {
    window.location.replace('/login/fi');
  }

  // This was old one. We want to return only body.
  // Handle error situations mostly here on general level?
  return { body: body, statusCode: response.status, isOk: isOk, challengeRequired: challengeRequired};
}

export async function apiCall(apiURL, apiMethod, bodyParam, abortSignal = null, opts = {}) {

  const requestObject = {
    credentials: 'include',
    method: apiMethod,
    body: bodyParam ? bodyParam : null,
    headers: {
      "Accept": "application/json"
    }
  };

  if (abortSignal instanceof AbortSignal) {
    // Used by getDocumentStatusId and 
    // getFloorPlanStatusId to timeout if time limit is exceeded
    requestObject.signal = abortSignal; 
  }

  let res;

  try {
    res = await fetch(apiURL, requestObject);
  } catch (err) {
    // The Promise returned from fetch() won't reject on HTTP error status even if the response is an HTTP 404 or 500.
    // Instead, as soon as the server responds with headers, the Promise will resolve normally (with the ok property of the response set to false if the response isn't in the range 200–299),
    // and it will only reject on network failure or if anything prevented the request from completing.
    if (err?.name === "AbortError") {
      console.log('Fetch aborted:', err.message);
      return;
    } else {
      if (opts?.skipErrorHandling) {
        return;
      } else {
        throw new Error(-1);
      }
    }
  }

  const processedResponse = await processResponseAsync(res, opts);

  if (opts.skipErrorHandling && !processedResponse.isOk) {
    return;
  } else if (!processedResponse.isOk && !processedResponse.challengeRequired) {
    console.log("err2");
    throw new Error(processedResponse?.body?.error || processedResponse.statusCode);
  } else if (!processedResponse.isOk && processedResponse.challengeRequired) {
    return processedResponse;
  } else if (processedResponse.body && processedResponse.body.success === false) {
    // body has {success: false}
    console.log("err3");
    throw new Error(processedResponse.body.error || processedResponse.body.error_code || -2);
  }

  return processedResponse;
}
