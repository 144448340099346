export const svgPoint = (elem, x, y) => {
  let p = new DOMPoint();
  p.x = x;
  p.y = y;

  let ctm = elem.getScreenCTM();

  /**
   * Next hack is made because of following iOS browser bug:
   * https://bugs.webkit.org/show_bug.cgi?id=209220
   */
  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (isIOS) {
    let matrix = elem.style.transform;
    matrix = matrix.split("(");
    if (matrix?.length > 1) {
      matrix = matrix[1].split(", ");
      let scale = matrix[0];
      ctm.a = ctm.a * scale;
      ctm.d = ctm.d * scale;
    } else {
      // What is happening, what should we do? Everything seems to work, don't do anything?
    }
  }

  return p.matrixTransform(ctm.inverse());
}

export function initToolDefaultEventHandlers(tool, toolType = null) {
  if (!tool) return;

  tool.started = false;
  tool.touchCount = 0;

  tool.mousedown = (ev) => {
    return tool.startDrawing(ev);
  };

  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (toolType === "pin") {
    if (isIOS) {
      // Touchend is not firing in current configuration on iOS. Let's use touchstart.
      // Touchstart is fired when zoomElement is paused. Otherwise touchend fires.
      tool.touchstart = (ev) => {
        console.debug("tool touchstart");
        if (ev.touches.length === 1) {
          return tool.startDrawing(ev);
        }
      };
    } else {
      // Touchend is tested and working on Android. Zooming creates new pin if touchstart is used.
      tool.touchend = (ev) => {
        if (ev.touches.length < 2) { // In touchend, ev.touches.length is right
          return tool.startDrawing(ev);
        }
      };
    }

  } else {
    tool.touchstart = (ev) => {
      /**
       * When touching with 2 fingers, browser fires 2 touchstart events,
       * in both ev.touches.length is 1. Thats why we need this hack.
       * We want to allow zooming with 2 touches and start drawing with 1 touch.
       * For some reason, with textTool, tool.touchCount is 0.
       * 
       * EDIT 11.10.21: On iOS, we must use ev.touches.length. On iOS it is counted right.
       * On iOS tool.touchCount mechanism doesn't work.
      */
      if (isIOS) {
        if (ev.touches.length === 1) {
          tool.startDrawing(ev);  // Return-value is only needed for pin, and that is handled above.
        }
      } else {
        tool.touchCount++;

        setTimeout(function () {
          if (tool.touchCount < 2) {
            tool.startDrawing(ev);  // Don't try to return anything from timeout!
          }
        }, 50);
      }
    };
  }

  tool.mousemove = (ev) => {
    if (toolType === "text" || toolType === "pin") {
      return;
    }

    if (tool.started) {
      tool.draw(ev);
    }
  };

  tool.touchmove = (ev) => {
    if (toolType === "text" || toolType === "pin") {
      return;
    }

    if (tool.started) {
      tool.draw(ev);
    }
  };

  tool.mouseup = (ev) => {
    if (toolType === "text" || toolType === "pin") {
      return;
    }

    if (tool.started) {
      return tool.drawEnd(ev);
    }
  };

  tool.drawEnd = (ev) => {
    tool.touchCount = 0;
    if (toolType === "text" || toolType === "pin") {
      return;
    }
    tool.started = false;
    return tool.toolMouseUp(ev);
  }

  tool.mouseleave = (ev) => {
    if (toolType === "text" || toolType === "pin") {
      return;
    }

    if (tool.started) {
      return tool.drawEnd(ev);
    }
  }

  tool.touchend = (ev) => {
    tool.touchCount = 0;

    if (toolType === "text" || toolType === "pin") {
      return;
    }

    if (tool.started) {
      return tool.drawEnd(ev);
    }
  };
}

export function getUuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function replaceScaleValuesInHtml(htmlString, markingScale) {
  const matrix = htmlString.match(/matrix\(\s*(.*?)\)/);

  if (matrix) {
    const transformValues = matrix[1].match(/[\d.-]+/g).map(Number);

    transformValues[0] = markingScale;
    transformValues[3] = markingScale;

    const newTransform = `matrix(${transformValues.join(', ')})`;
    //return htmlString.replace(/matrix([^"]*)/, `matrix(${newTransform})`);
    return htmlString.replace(/transform:\s*matrix\([^)]*\)/, "transform: " + newTransform);
  }

  return htmlString;
}