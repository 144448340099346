import { apiCall, API } from '../../utils/APIFunctions';

const journalAPI = {
  getJournal: (objectId) => {
    if (!objectId) return {}
    return apiCall(`${API}/o/journals/${objectId}`,"GET");
  },
  getJournalEntries: (objectId) => {
    if (!objectId) return {}
    return apiCall(`${API}/o/journals/${objectId}/journal_entries`,"GET");
  },
  createNewJournal: (parent_id, title, template_id) => {
    if (title === undefined || template_id === undefined) return {}
    const data = {
      parent_id,
      title,
      template_id
    }
    let url = `${API}/o/journals`
    return apiCall(url,"post", JSON.stringify(data));
  },
  addNewJournalEntry: (journalId, copyPrevious) => {
    if (!journalId) return {}
    if (copyPrevious === undefined) {
      copyPrevious = 0
    }
    let url = `${API}/journals/${journalId}/journal_entries?copy_previous=${copyPrevious}`
    return apiCall(url,"post");
  },
  deleteJournal: (objectId) => {
    if (!objectId) return {}
    let url = `${API}/kp_objects/${objectId}`
    return apiCall(url,"DELETE");
  }
}

export default journalAPI