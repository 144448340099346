// First 6 lists are used to determine how new node should be initialized
export const typesWithNodes = ['input-images', 'input-notes', 'input-files', 'doc-table', 'macro-section', 'section'];
export const typesWithoutNodes = ['input-checkbox', 'input-textfield', 'input-textarea', 'input-select', 'input-datetime', 'input-signature', 'info-text', 'compilation-content'];
export const typesWithText = ['info-text'];
export const typesWithTitle = ['section'];
export const typesWithLabel = ['input-checkbox', 'input-textfield', 'input-textarea', 'input-select', 'input-datetime', 'input-signature', 'compilation-content'];
export const typesWithOptions = ['input-select'];
export const typesWithColumns = ['doc-table'];
export const typesWithValue = ['input-textfield', 'input-textarea']; // If we change type to other than these, default value will be deleted

/**
 * This is used to determine if we have to show node.nodes in template-editor.
 * NOTE: This is different list in normal document (at least for now). 
 * In old editor there is possible to create any kind of node-structures. 
 * We don't want to show all kind of mutants in new editor.
 */
export const typesWithChildNodes = ['section', 'macro-section', 'field-set'];

export const allowedTypesForFieldSet = ['input-textfield', 'input-checkbox', 'input-select'];
export const typesWithDefaultTitle = ['file'];  // File-node without title caused error in pdf-generation