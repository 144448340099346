import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './companyAdminAPI';
import * as companySlice from '../company/companySlice';

const initialState = {
  settings: [],
  didLoadCompanySettings: false, //Used in DocumentCreate and FolderView
  isCompanyAdminSidebarOpen: true,
  getCompanySettingsStatus: undefined,
  companyIntegrationsStatus: 'idle',
  companyIntegrationsApiStatus: 'idle',
  companyIntegrationsTampuuriStatus: 'idle',
  companyIntegrationsTampuuriSaveStatus: 'idle',
  companyIntegrationsTampuuriSyncStatus: 'idle',
  companyIntegrationsTampuuriSyncing: false,
  companyIntegrations: {},
  savedTab: "users"
};

export const getCompanySettings = createAsyncThunk(
  'companyAdmin/getCompanySettings',
  async (companyId, { getState }) => {
    return api.getCompanySettings(companyId || getState().auth.data.company_id);
  }
)

export const updateCompanySettings = createAsyncThunk(
  'companyAdmin/updateCompanySettings',
  async (params) => {
    return api.updateCompanySettings(params);
  }
)

export const getCompanyIntegrations = createAsyncThunk(
  'companyAdmin/getCompanyIntegrations',
  async (companyId) => {
    return api.getCompanyIntegrations(companyId);
  }
)

export const enableIntegrationsApi = createAsyncThunk(
  'companyAdmin/enableIntegrationsApi',
  async (companyId, { dispatch }) => {
    const response = await api.enableIntegrationsApi(companyId);
    if (response.body.success) {
      dispatch(companySlice.setCompanyApiIntegration(1));
    }
    return response;
  }
)

export const disableIntegrationsApi = createAsyncThunk(
  'companyAdmin/disableIntegrationsApi',
  async (companyId, { dispatch }) => {
    const response = await api.disableIntegrationsApi(companyId);
    if (response.body.success) {
      dispatch(companySlice.setCompanyApiIntegration(0));
    }
    return response;
  }
)

export const enableIntegrationsTampuuri = createAsyncThunk(
  'companyAdmin/enableIntegrationsTampuuri',
  async (companyId, { dispatch }) => {
    const response = await api.enableIntegrationsTampuuri(companyId);
    if (response.body.success) {
      dispatch(companySlice.setCompanyTampuuriIntegration(1));
    }
    return response;
  }
)

export const disableIntegrationsTampuuri = createAsyncThunk(
  'companyAdmin/disableIntegrationsTampuuri',
  async (companyId, { dispatch }) => {
    const response = await api.disableIntegrationsTampuuri(companyId);
    if (response.body.success) {
      dispatch(companySlice.setCompanyTampuuriIntegration(0));
    }
    return response;
  }
)

export const updateIntegrationsTampuuri = createAsyncThunk(
  'companyAdmin/updateIntegrationsTampuuri',
  async (params) => {
    return api.updateIntegrationsTampuuri(params);
  }
)

export const startIntegrationsTampuuriSync = createAsyncThunk(
  'companyAdmin/startIntegrationsTampuuriSync',
  async (companyId) => {
    return api.startIntegrationsTampuuriSync(companyId);
  }
)

export const companyAdminSlice = createSlice({
  name: 'companyAdmin',
  initialState,
  reducers: {
    resetCompanyAdminSettingsState: (state) => {
      state.getCompanySettingsStatus = undefined;
    },
    toggleCompanyAdminSidebar: (state) => {
      state.isCompanyAdminSidebarOpen = !state.isCompanyAdminSidebarOpen;
    },
    resetCompanyIntegrations: (state) => {
      state.companyIntegrationsStatus = 'idle';
      state.companyIntegrationsApiStatus = 'idle';
      state.companyIntegrationsTampuuriStatus = 'idle';
      state.companyIntegrationsTampuuriSaveStatus = 'idle';
      state.companyIntegrationsTampuuriSyncStatus = 'idle';
      state.companyIntegrationsTampuuriSyncing = false;
      state.companyIntegrations = {};
    },
    saveTab: (state, action) => {
      state.savedTab = action.payload;
    },
    setCompanySettings: (state, action) => {
      const { name } = action.payload;
      const { settings } = state;

      if (settings.length === 0) {
        settings.push(action.payload);
        return;
      }

      const isSettingNameInState = settings.some(item => item.name === name);
        
      if (!isSettingNameInState) {
        settings.push(action.payload);
        return;
      }

      const settingIndex = settings.findIndex(setting => setting.name == name);

      if (settingIndex > -1) {
        settings[settingIndex] = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanySettings.pending, (state) => {
        state.getCompanySettingsStatus = "loading";
        state.didLoadCompanySettings = false;
      })     
      .addCase(getCompanySettings.fulfilled, (state, action) => {
        state.settings = action.payload.body;
        state.getCompanySettingsStatus = "idle";
        state.didLoadCompanySettings = true;
      })
      .addCase(getCompanySettings.rejected, (state) => {
        state.getCompanySettingsStatus = "error";
        state.didLoadCompanySettings = false;
      })

      .addCase(updateCompanySettings.pending, (state, action) => {
        const { name } = action.meta.arg;
        const { settings } = state;

        if (action.meta.arg.newProviderValue) {
          state[action.meta.arg.newProviderValue + "ProviderStatus"] = "saving";
        }
        state.originalSettings = [...settings];
        state[name+"Status"]= "saving";

        if (settings.length === 0) {
          settings.push(action.meta.arg);
          return;
        }

        const isSettingNameInState = settings.some(item => item.name === name);
          
        if (!isSettingNameInState) {
          settings.push(action.meta.arg);
          return;
        }

        const settingIndex = settings.findIndex(setting => setting.name == name);

        if (settingIndex > -1) {
          settings[settingIndex] = action.meta.arg;
        }

      })
      .addCase(updateCompanySettings.fulfilled, (state, action) => {
        if (action.meta.arg.newProviderValue) {
          state[action.meta.arg.newProviderValue + "ProviderStatus"] = "idle";
        }
        state[action.meta.arg.name+"Status"]= "idle";
      })
      .addCase(updateCompanySettings.rejected, (state, action) => {
        if (action.meta.arg.newProviderValue) {
          state[action.meta.arg.newProviderValue + "ProviderStatus"] = "idle";
        }
        state.settings = state.originalSettings;
        state[action.meta.arg.name+"Status"]= "idle";
      })

      .addCase(getCompanyIntegrations.pending, (state) => {
        state.companyIntegrationsStatus = "loading";
      })     
      .addCase(getCompanyIntegrations.fulfilled, (state, action) => {
        state.companyIntegrationsStatus = "idle";
        state.companyIntegrations = action?.payload?.body;
      })
      .addCase(getCompanyIntegrations.rejected, (state) => {
        state.companyIntegrationsStatus = "error";
      })
      .addCase(enableIntegrationsApi.pending, (state) => {
        state.companyIntegrationsApiStatus = "loading";
      })     
      .addCase(enableIntegrationsApi.fulfilled, (state, action) => {
        state.companyIntegrationsApiStatus = "idle";
        if (action?.payload?.body.success) {
          state.companyIntegrations.api.enabled = 1;
        }
      })
      .addCase(enableIntegrationsApi.rejected, (state) => {
        state.companyIntegrationsApiStatus = "error";
      })
      .addCase(disableIntegrationsApi.pending, (state) => {
        state.companyIntegrationsApiStatus = "loading";
      })     
      .addCase(disableIntegrationsApi.fulfilled, (state, action) => {
        state.companyIntegrationsApiStatus = "idle";
        if (action?.payload?.body.success) {
          state.companyIntegrations.api.enabled = 0;
        }
      })
      .addCase(disableIntegrationsApi.rejected, (state) => {
        state.companyIntegrationsApiStatus = "error";
      })

      .addCase(enableIntegrationsTampuuri.pending, (state) => {
        state.companyIntegrationsTampuuriStatus = "loading";
      })     
      .addCase(enableIntegrationsTampuuri.fulfilled, (state) => {
        state.companyIntegrationsTampuuriStatus = "idle";
      })
      .addCase(enableIntegrationsTampuuri.rejected, (state) => {
        state.companyIntegrationsTampuuriStatus = "error";
      })
      .addCase(disableIntegrationsTampuuri.pending, (state) => {
        state.companyIntegrationsTampuuriStatus = "loading";
      })     
      .addCase(disableIntegrationsTampuuri.fulfilled, (state) => {
        state.companyIntegrationsTampuuriStatus = "idle";
      })
      .addCase(disableIntegrationsTampuuri.rejected, (state) => {
        state.companyIntegrationsTampuuriStatus = "error";
      })

      .addCase(updateIntegrationsTampuuri.pending, (state) => {
        state.companyIntegrationsTampuuriSaveStatus = "loading";
      })     
      .addCase(updateIntegrationsTampuuri.fulfilled, (state, action) => {
        state.companyIntegrationsTampuuriSaveStatus = "idle";
        state.companyIntegrations.tampuuri = action?.payload?.body.tampuuri;
      })
      .addCase(updateIntegrationsTampuuri.rejected, (state) => {
        state.companyIntegrationsTampuuriSaveStatus = "error";
      })

      .addCase(startIntegrationsTampuuriSync.pending, (state) => {
        state.companyIntegrationsTampuuriSyncStatus = "loading";
      })     
      .addCase(startIntegrationsTampuuriSync.fulfilled, (state) => {
        state.companyIntegrationsTampuuriSyncStatus = "idle";
        state.companyIntegrationsTampuuriSyncing = true;
      })
      .addCase(startIntegrationsTampuuriSync.rejected, (state) => {
        state.companyIntegrationsTampuuriSyncStatus = "error";
        state.companyIntegrationsTampuuriSyncing = false;
      })
  }
});

export const {
  resetCompanyAdminSettingsState,
  toggleCompanyAdminSidebar,
  resetCompanyIntegrations,
  saveTab,
  setCompanySettings
} = companyAdminSlice.actions;

export const selectCompanySettingStatus = (state, companySetting) => {
  return state.companyAdmin[companySetting+"Status"] || "idle"; 
}
export const selectProviderStatus = (state, providerName) => {
  return state.companyAdmin[providerName+"ProviderStatus"] || "idle";
}
export const selectDidLoadCompanySettings = (state) => state.companyAdmin.didLoadCompanySettings;
export const selectGetCompanySettingsStatus = (state) => state.companyAdmin.getCompanySettingsStatus;
export const selectCompanyIntegrations = (state) => state.companyAdmin.companyIntegrations;
export const selectCompanyIntegrationsStatus = (state) => state.companyAdmin.companyIntegrationsStatus;
export const selectCompanyIntegrationsApiStatus = (state) => state.companyAdmin.companyIntegrationsApiStatus;
export const selectCompanyIntegrationsTampuuriStatus = (state) => state.companyAdmin.companyIntegrationsTampuuriStatus;
export const selectCompanyIntegrationsTampuuriSaveStatus = (state) => state.companyAdmin.companyIntegrationsTampuuriSaveStatus;
export const selectCompanyIntegrationsTampuuriSyncStatus = (state) => state.companyAdmin.companyIntegrationsTampuuriSyncStatus;
export const selectCompanyIntegrationsTampuuriSyncing = (state) => state.companyAdmin.companyIntegrationsTampuuriSyncing;
export const selectCompanySetting = (state, settingName) => {
  if (!settingName || typeof settingName !== "string") {
    throw new Error("Invalid settingName argument");
  }

  const { settings } = state.companyAdmin;

  const matchingSetting = settings.find(setting => setting.name === settingName);

  if (!matchingSetting) {
    return null;
  }

  if (matchingSetting.value) {
    switch (settingName) {
      case "login_providers-allowed":
      case "root_folder_objects-enabled":
        return matchingSetting.value.split(",");
      case "use_custom_beacon":
        return matchingSetting.value === "0" ? false : matchingSetting.value;
      default:
        return matchingSetting.value;
    }
  } else {
    return  [];
  }
}

export default companyAdminSlice.reducer;
