import { apiCall, API } from '../../utils/APIFunctions';

export function getUserCompanyList() {
  return apiCall(`${API}/user/company/companyList`, "GET");
}

export function setCurrentCompany(companyId) {
  return apiCall(`${API}/user/company/currentCompany`, "POST", JSON.stringify({companyId}));
}

export function getTokenGranters(companyId) {
  return apiCall(`${API}/user/company/${companyId}/tokenGranters`, "GET");
}

export function requestAdminAccess(params) {
  params.newui = 1;
  return apiCall(`${API}/admintokens/request`, "POST", JSON.stringify(params));
}