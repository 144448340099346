import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalsOpen: {},
  callModalOpenId: null,
  pdfModalType : null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.modalsOpen[action.payload] = true;
      state.callModalOpenId = action.payload;
    },
    closeModal: (state, action) => {
      if (state.modalsOpen[action.payload]) {
        delete state.modalsOpen[action.payload];
        state.callModalOpenId = null;
      }
    },
    setCallModalOpenId: (state, action) => {
      state.callModalOpenId = action.payload;
    },
    setPdfModalType: (state, action) => {
      state.pdfModalType = action.payload;
    },
    closeAllOpenModals: (state) => {
      state.modalsOpen = {};
      state.callModalOpenId = null; 
    }
  }
});

export const { 
  openModal, 
  closeModal,
  setCallModalOpenId,
  setPdfModalType,
  closeAllOpenModals
} = modalSlice.actions;

export const selectIsModalOpen = (state, modalId) => state.modal.modalsOpen[modalId];
export const selectCallModalOpenId = (state) => state.modal.callModalOpenId;
export const selectPdfModalType = (state) => state.modal.pdfModalType;

export default modalSlice.reducer;
