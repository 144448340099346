import { previousMonday, nextMonday, startOfDay, isYesterday, startOfWeek, subWeeks, startOfMonth, subMonths, endOfDay, endOfWeek, endOfMonth } from 'date-fns';

export function calculateStartTimestamp(filterValue) {
  const currentDate = new Date();
  
  switch (filterValue) {
  
    case "today": {
      return Math.floor(startOfDay(currentDate).getTime() / 1000);
    }
    case "thisWeek": {
      return Math.floor(startOfWeek(currentDate, { weekStartsOn: 1 }).getTime() / 1000);
    }
    case "lastWeek": {
      return Math.floor(startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 }).getTime() / 1000);
    }
    case "thisMonth": {
      return Math.floor(startOfMonth(currentDate).getTime() / 1000);
    }
    case "lastMonth": {
      return Math.floor(startOfMonth(subMonths(currentDate, 1)).getTime() / 1000);
    }
    case "all":{
      return 0;
    }
    default:
      return 0;
  }
}
  
export function calculateEndTimestamp(filterValue) {
  const currentDate = new Date();
    
  switch (filterValue) {
  
    case "today": {
      return Math.floor(endOfDay(currentDate).getTime() / 1000);
    }
    case "thisWeek": {
      return Math.floor(endOfWeek(currentDate, { weekStartsOn: 1 }).getTime() / 1000);
    }
    case "lastWeek": {
      return Math.floor(endOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 }).getTime() / 1000);
    }
    case "thisMonth": {
      return Math.floor(endOfMonth(currentDate).getTime() / 1000);
    }
    case "lastMonth": {
      return Math.floor(endOfMonth(subMonths(currentDate, 1)).getTime() / 1000);
    }
    case "all": {
      return Math.floor(endOfDay(currentDate).getTime() / 1000);
    }
    default:
      return 0;
  }
}

export const dateHelper = {
  isInDateRange: (dateTs, range, dates) => {
    const date = new Date(dateTs * 1000);
    const now = new Date();
    // Multiple ways to do these...
    const startOfWeek = startOfDay(previousMonday(now));
    const endOfWeek = startOfDay(nextMonday(now));
    const startOfPreviousWeek = startOfDay(previousMonday(startOfWeek));
    const endOfPreviousWeek = startOfDay(previousMonday(now));

    switch (range) {
      case "all":
        return true;
      case "today": 
        return date.getDate() === now.getDate();
      case "yesterday":
        return isYesterday(date);
      case "thisWeek":
        return date > startOfWeek && date < endOfWeek;
      case "lastWeek":
        return date > startOfPreviousWeek && date < endOfPreviousWeek;
      case "thisMonth":
        return date.getMonth() === now.getMonth();
      case "lastMonth": {
        let prev = now.getMonth()-1;
        if (prev < 0) {
          prev = 11;
        }
        return date.getMonth() === prev;
      }
      case "custom":
        return date >= new Date(dates.start) && date <= new Date(dates.end);
      default:
        return false;
    }
  },

  isInTimeRange:(timestamp, timeFilterObj) => {

    const isCustomValue = timeFilterObj.filterValue === "custom";

    const startTimestamp = isCustomValue ? timeFilterObj.dates.startDateTimestamp / 1000 :  calculateStartTimestamp(timeFilterObj.filterValue);
    const endTimestamp = isCustomValue ? timeFilterObj.dates.endDateTimestamp / 1000 : calculateEndTimestamp(timeFilterObj.filterValue);
    
    return timestamp >= startTimestamp && timestamp <= endTimestamp;
  }

};
