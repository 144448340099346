const vars = {
  SHARED_TO_ME_FOLDER_ID: 'shared',

  // Manage right 'name' is 05manage just to make getting the most signifigant permission with MIN() aggregate select query easily :Z
  MANAGE_PERMISSION_ID: '05manage',
  EDIT_PERMISSION_ID: 'edit',
  VIEW_PERMISSION_ID: 'view',

  // object.is_folder is not returned from BE, so we must do this kind of mapping...
  FOLDER_TYPES: ['folder', 'floor_plan_folder', 'compilationsfolder'],
};

export default vars; 
