import { apiCall, API } from '../../utils/APIFunctions';

export function searchObjects(params, globalAbortController) {
  // nodeTerms is an array of objects, so URLSearchParams won't work for it
  let url = `${API}/elasticsearch/objects/searchforcompilation?nodeTerms=${encodeURIComponent(params.nodeTerms)}&`;
  delete params.nodeTerms;
  url += new URLSearchParams(params).toString();

  return apiCall(url, "GET", null, globalAbortController.signal);
}

export function createCompilation(params) {
  return apiCall(`${API}/documents/createcompilation`, "POST", JSON.stringify(params));
}

export function fetchSearchCriteria() {
  return apiCall(`${API}/compilation_search_criteria`, "GET");
}

export function saveSearchCriteria(params) {
  return apiCall(`${API}/compilation_search_criteria`, "POST", JSON.stringify(params));
}

export function updateSearchCriteria(id, params) {
  return apiCall(`${API}/compilation_search_criteria/${id}`, "PUT", JSON.stringify(params));
}

export function deleteSearchCriteria(id) {
  return apiCall(`${API}/compilation_search_criteria/${id}`, "DELETE");
}