import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import metricAPI from './metricAPI';
import * as objectsSlice from '../objects/objectsSlice';

const initialState = {
  status:'idle',
  metricStatus: 'idle',
};

export const getMetric = createAsyncThunk (
  'metric/getMetric',
  async (params) => {
    const response = await metricAPI.getMetric(params)
    return response.body;
  }
)

export const getMeasurement = createAsyncThunk (
  'metric/getMeasurement',
  async (params) => {
    const response = await metricAPI.getMeasurement(params)
    return response.body;
  }
)

export const fetchMeasurements = createAsyncThunk (
  'metric/fetchMeasurements',
  async (params) => {
    const response = await metricAPI.fetchMeasurements(params);
    return response.body;
  }
)

export const fetchMetricChoices = createAsyncThunk (
  'metric/fetchMetricChoices',
  async (params, ) => {
    const response = await metricAPI.fetchMetricChoices(params);
    return response.body;
  }
)

export const fetchMetricGuides = createAsyncThunk (
  'metric/fetchMetricGuides',
  async (params, ) => {
    const response = await metricAPI.fetchMetricGuides(params);
    return response.body;
  }
)
export const addNewMeasurement = createAsyncThunk (
  'metric/addNewMeasurement',
  async (params) => {
    const response = await metricAPI.addNewMeasurement(params);
    return response.body;
  }
)

export const deleteMeasurement = createAsyncThunk(
  'metric/deleteMeasurement',
  async (params) => {
    const response = await metricAPI.deleteMeasurement(params);
    return response.body;
  }
)
export const updateTargetScore = createAsyncThunk (
  'metric/updateTargetScore',
  async (params) => {
    const response = await metricAPI.updateTargetScore(params.measurementId, params.targetId, params.pass, params.fail);
    return response.body;
  }
)

export const createMetric = createAsyncThunk(
  'metric/createMetric',
  async (params, { dispatch }) => {
    const response = await metricAPI.createMetric(params);
    dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());
    return response.body;
  }
);

export const markMeasurementCompleted = createAsyncThunk (
  'metric/markMeasurementCompleted',
  async (params) => {
    const response = await metricAPI.markMeasurementCompleted(
      params.measurementId,
      params.totalPass,
      params.totalFail,
      params.score,
      params.measurementParticipants,
      params.additionalInfo
    );
    return response.body;
  }
);

export const saveNote = createAsyncThunk(
  'metric/saveNote',
  async (params) => {
    const response = await metricAPI.saveNote(params
    );
    return response.body;
  }
);

export const updateNote = createAsyncThunk(
  'metric/updateNote',
  async (params) => {
    const response = await metricAPI.updateNote(params);
    return response.body;
  }
);

export const sendSummary = createAsyncThunk(
  'metric/sendSummary',
  async (params) => {
    const response = await metricAPI.sendSummary(params);
    return response.body;
  }
);

export const markNoteDone = createAsyncThunk(
  'metric/markNoteDone',
  async (params) => {
    const response = await metricAPI.markNoteDone(params);
    return response.body;
  }
);

export const addComment = createAsyncThunk(
  'metric/addComment',
  async (params) => {
    const response = await metricAPI.addComment(params);
    return response.body;
  }
);
export const deleteNoteImage = createAsyncThunk(
  'metric/deleteNoteImage',
  async (params) => {
    const response = await metricAPI.deleteNoteImage(params);
    return response.body;
  }
);

export const metricSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setCurrentMeasurementInfoAdditional: (state, action) => {
      if (state.currentMeasurement.info) {
        state.currentMeasurement.info.additional = action.payload;
      }
    },
    setCurrentMeasurementInfoParticipants: (state, action) => {
      if (state.currentMeasurement.info) {
        state.currentMeasurement.info.participants = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeasurements.fulfilled, (state, action) => {
        state.measurements = action.payload.results;
      })
      .addCase(addNewMeasurement.pending, (state) => {
        state.metricStatus = "creating";
      })
      .addCase(addNewMeasurement.fulfilled, (state) => {
        state.metricStatus = "idle";
      })
      .addCase(addNewMeasurement.rejected, (state) => {
        state.metricStatus = "error";
      })
      .addCase(getMetric.fulfilled, (state,action) => {
        state.metricData = action.payload;
      })
      .addCase(fetchMetricChoices.fulfilled, (state,action) => {
        state.metricChoices = action.payload;
      })
      .addCase(fetchMetricGuides.fulfilled, (state,action) => {
        state.metricGuides = action.payload;
      })
      .addCase(getMeasurement.fulfilled, (state,action) => {
        state.currentMeasurement = action.payload;

        if (!action.payload.info) {
          state.currentMeasurement.info = {additional: "", participants : ""}
        }
      })
      .addCase(deleteMeasurement.pending, (state) => {
        state.currentMeasurement.status = "deleting";
      })
      .addCase(deleteMeasurement.fulfilled, (state) => {
        state.currentMeasurement.status = "idle";
      })
      .addCase(deleteMeasurement.rejected, (state) => {
        state.currentMeasurement.status = "error";
      })
      .addCase(sendSummary.pending, (state) => {
        state.summaryStatus = 'sending';
      })
      .addCase(sendSummary.fulfilled, (state) => {
        state.summaryStatus = 'idle';
        state.currentMeasurement.summary_sent = 1;
      })
      .addCase(sendSummary.rejected, (state) => {
        state.summaryStatus = 'error';
      })
      .addCase(updateTargetScore.pending, (state,action) => {
        if (action?.meta?.arg.targetId !== undefined) {
          const target = state.currentMeasurement.targets[action.meta.arg.targetId];

          target.originalPass = target.pass;
          target.originalFail = target.fail;
          target.pass = action.meta.arg.pass;
          target.fail = action.meta.arg.fail;
        }
      })
      .addCase(updateTargetScore.fulfilled, (state,action) => {
        if (action?.meta?.arg.targetId !== undefined) {
          state.currentMeasurement.score = action.payload.score;
          state.currentMeasurement.pass = action.payload.pass;
          state.currentMeasurement.fail = action.payload.fail;
        }
      })
      .addCase(updateTargetScore.rejected, (state,action) => {
        if (action?.meta?.arg.targetId !== undefined) {
          const target = state.currentMeasurement.targets[action.meta.arg.targetId];

          target.pass = target.originalPass;
          target.fail = target.originalFail;
        }
      })
      .addCase(updateNote.pending, (state, action) => {
        const targetId = action.meta.arg.targetId;
        const noteId = action.meta.arg.noteId;

        let note = state.currentMeasurement.targets[targetId].notes[noteId];
        note.status = "saving";
      })
      .addCase(updateNote.fulfilled, (state,action) => {

        const targetId = action.meta.arg.targetId;
        const noteId = action.meta.arg.noteId;
        const noteString = `targets.${targetId}.notes.${noteId}.`;

        let note = state.currentMeasurement.targets[targetId].notes[noteId];
        note.text = action.payload[noteString + "text"];
        note.choice = action.payload[noteString + "choice"];
        note.responsible_email = action.payload[noteString + "responsible_email"];
        note.responsible_id = action.payload[noteString + "responsible_id"];
        note.responsible_name = action.payload[noteString + "responsible_name"];
        note.priority = action.payload[noteString + "priority"];
        note.image = action.payload[noteString + "image"];
        note.images = action.payload[noteString + "images"];
        note.status = "idle";
      })
      .addCase(updateNote.rejected, (state, action) => {
        const targetId = action.meta.arg.targetId;
        const noteId = action.meta.arg.noteId;

        let note = state.currentMeasurement.targets[targetId].notes[noteId];
        note.status = "error";
      })
      .addCase(saveNote.pending, (state, action) => {
        state.currentMeasurement.targets[action.meta.arg.targetId].newNoteStatus = "saving"
      })
      .addCase(saveNote.fulfilled, (state,action) => {
        state.currentMeasurement.targets[action.meta.arg.targetId].notes.push(action.payload);
        state.currentMeasurement.targets[action.meta.arg.targetId].newNoteStatus = "idle";
      })
      .addCase(saveNote.rejected, (state, action) => {
        state.currentMeasurement.targets[action.meta.arg.targetId].newNoteStatus = "error";
      })
      .addCase(markNoteDone.fulfilled, (state,action) => {
        const targetId = action.meta.arg.targetId;
        const noteId = action.meta.arg.noteId;
        const noteString = `targets.${targetId}.notes.${noteId}.`;

        let note = state.currentMeasurement.targets[targetId].notes[noteId];
        note.done = action.payload[noteString + "done"];
        note.done_id = action.payload[noteString + "done_id"];
        note.done_name = action.payload[noteString + "done_name"];
        note.done_ts = action.payload[noteString + "done_ts"];
      })
      /*.addCase(deleteNoteImage.pending, (state,action) => {
      })*/
      .addCase(deleteNoteImage.fulfilled, (state, action) => {
        const targetId = action.meta.arg.targetId;
        const noteId = action.meta.arg.noteId;

        let note = state.currentMeasurement.targets[targetId].notes[noteId];
        note.image = null;
      })
      /*.addCase(deleteNoteImage.rejected, (state,action) => {
        
      })*/
      .addCase(markMeasurementCompleted.pending, (state) => {
        state.measurementStatus = 'saving';
      })
      .addCase(markMeasurementCompleted.fulfilled, (state, action) => {
        state.measurementStatus = 'idle';
        state.currentMeasurement.completed = action.payload.completed;
        state.currentMeasurement.info = {additional: action.payload["info.additional"], participants: action.payload["info.participants"]}

        const currentMeasurementIndex = state.measurements.findIndex(measurement => measurement.id == action.meta.arg.measurementId);
        if (currentMeasurementIndex > -1) {
          state.measurements[currentMeasurementIndex].completed = action.payload.completed;
        }
      })
      .addCase(markMeasurementCompleted.rejected, (state) => {
        state.measurementStatus = 'error';
      })
      .addCase(addComment.fulfilled, (state, action) => {

        const targetId = action.meta.arg.targetId;
        const noteId = action.meta.arg.noteId;

        let note = state.currentMeasurement.targets[targetId].notes[noteId];
        if (!note.comments) {
          note.comments = [];
        }
        note.comments.push(action.payload);
      })
      .addCase(createMetric.pending, (state) => {
        state.status = 'creating';
      })
      .addCase(createMetric.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(createMetric.rejected, (state) => {
        state.status = 'error';
      })
  }
});

export const {
  setCurrentMeasurementInfoAdditional,
  setCurrentMeasurementInfoParticipants
} = metricSlice.actions;

export const selectMeasurements = (state) => {
  if (!state.metric || !state.metric.measurements) return null;
  return state.metric.measurements;
}

export const selectStatus = (state) => state.metric.status;
export const selectMeasurementStatus = (state) => state.metric.measurementStatus; //This should be refactored?
export const selectCurrentMeasurementStatus = (state) => state.metric.currentMeasurement?.status || null;
export const selectSummaryStatus = (state) => state.metric.summaryStatus;
export const selectMetricStatus = (state) => state.metric.metricStatus;
export const selectCurrentMeasurementCreatorId = (state) => state.metric.currentMeasurement?.creator_id || null;


export const selectMetricChoices = (state) => {
  if (!state.metric || !state.metric.metricChoices) return null;
  return state.metric.metricChoices;
}

export const selectMetricGuides = (state) => {
  if (!state.metric || !state.metric.metricGuides) return null;
  return state.metric.metricGuides;
}
export const selectCurrentMeasurement = (state) => {
  if (!state.metric || !state.metric.currentMeasurement) return null;
  return state.metric.currentMeasurement;
}

export const selectCurrentMeasurementPassAndFail = (state) => {
  if (!state.metric || !state.metric.currentMeasurement) return null;
  return {pass: state.metric.currentMeasurement.pass, fail: state.metric.currentMeasurement.fail}
}

export const selectCurrentMeasurementTargets = (state) => {
  return state.metric?.currentMeasurement?.targets || null;
}

export const selectCurrentMeasurementInfoParticipants = (state) => {
  return state.metric?.currentMeasurement?.info?.participants || ""
}

export const selectCurrentMeasurementInfoAdditional = (state) => {
  return state.metric?.currentMeasurement?.info?.additional || ""
}

export const selectCurrentMeasurementTargetNote = (state, targetId, noteId) => {
  return state.metric?.currentMeasurement?.targets[targetId]?.notes[noteId] || null;
}
export const selectNewNoteStatus = (state, targetId) => {
  if (targetId == null) return null
  return state.metric.currentMeasurement.targets[targetId]?.newNoteStatus || null;
}

export const selectSavedNoteStatus = (state, targetId, noteId) => {
  if (targetId == null || noteId == null) return null
  return state.metric.currentMeasurement?.targets[targetId]?.notes[noteId]?.status || null;
}

export default metricSlice.reducer;
