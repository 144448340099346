import { apiCall, API, processResponseAsync } from '../../utils/APIFunctions';
import KPConfig from '../../KPConfig.js';
import { abortGlobalRequest } from '../../utils/abortController';

const documentAPI = {
  fetchDocument: (params) => {
    if (!params.id) return {};

    return params.isTemplatePreview ?
      apiCall(`${API}/document_templates/${params.id}`, "get") :
      apiCall(`${API}/documents/${params.id}`, "get")
    ;
  },

  deleteDocument: (documentId) => {
    if (!documentId) return {}
    let url = `${API}/documents/${documentId}`
    return apiCall(url,"DELETE");
  },

  markReady: (documentId) => {
    return apiCall(`${API}/documents/${documentId}/completed`, "put");
  },

  updateDocumentDate: (documentId, date) => {
    return apiCall(`${API}/documents/${documentId}/date`, "put",
      JSON.stringify({
        date_ts: date
      })
    );
  },

  moveSection: (documentId, nodeId, isSubSection, fromIndex, toIndex) => {
    return apiCall(`${API}/documents/${documentId}/nodes/${nodeId}/sectionMove`, "put",
      JSON.stringify({
        fromIndex: fromIndex,
        toIndex: toIndex,
        isMacroSection: isSubSection
      })
    );
  },

  updateSectionPageBreak: (documentId, nodeId, pageBreak) => {
    return apiCall(
      `${API}/documents/${documentId}/sections/${nodeId}`,
      "put",
      JSON.stringify({pageBreak: pageBreak})
    );
  },

  addRepeatingSection: (documentId, nodeId, macroId, copyOption, templateNodeId) => {
    return apiCall(`${API}/documents/${documentId}/nodes/${nodeId}/macros`, "post",
      JSON.stringify({
        macroId: macroId,
        copyOption: copyOption,
        templateNodeId: templateNodeId
      })
    );
  },

  deleteSection: (documentId, nodeId) => {
    return apiCall(`${API}/documents/${documentId}/sections/${nodeId}`, "delete");
  },

  updateSection: (documentId, nodeId, data) => {
    return apiCall(
      `${API}/documents/${documentId}/sections/${nodeId}`,
      "put",
      JSON.stringify(data)
    );
  },

  uploadImage: (documentId, nodeId, file, index, description, geolocation) => {
    let data = new FormData();
    data.append("file", file);
    data.append("nodeIndex", index);
    data.append("title", description); // description is more descriptive name than title :)

    if (geolocation) {
      data.append("location_lat", geolocation[0]);
      data.append("location_long", geolocation[1]);
    }

    return apiCall(`${API}/documents/${documentId}/nodes/${nodeId}/images`, "post", data);
  },

  askAIImageTitle: (documentId, nodeId, file, instructions) => {
    let data = new FormData();
    if (file) {
      data.append("file", file);
    }
    data.append("instructions", instructions);

    return apiCall(`${API}/documents/${documentId}/nodes/${nodeId}/askAIImageTitle`, "post", data);
  },
  
  moveImage: (documentId, parentNodeId, fromIndex, toIndex) => {
    return apiCall(`${API}/documents/${documentId}/nodes/${parentNodeId}/imageMove`, "put",
      JSON.stringify({
        fromIndex,
        toIndex
      })
    );
  },

  moveImageToSection: (documentId, newParentNodeId, originalImageId, originalNode) => {
    return apiCall(`${API}/documents/${documentId}/nodes/${newParentNodeId}/imageMoveToSection`, "put",
      JSON.stringify({
        originalImageId,
        originalNode
      })
    );
  },

  uploadFile: (documentId, nodeId, file, index, fileName, description, isPublic) => {
    let data = new FormData();
    data.append("file", file);
    data.append("name", fileName);
    data.append("nodeIndex", index);
    data.append("title", description); // description is more descriptive name than title :)
    data.append("public", isPublic);
    return apiCall(`${API}/documents/${documentId}/nodes/${nodeId}/files`, "post", data);
  },

  updateFile: (documentId, nodeId, title, isPublic) => {
  
    const payload = {
      ...(title !== undefined && { title }),
      ...(isPublic !== undefined && { public: isPublic }),
    };
  
    return apiCall(
      `${API}/documents/${documentId}/files/${nodeId}`,
      "put",
      JSON.stringify(payload)
    );
  },

  deleteFile: (documentId, nodeId) => {
    return apiCall(
      `${API}/documents/${documentId}/files/${nodeId}`,
      "delete"
    );
  },

  updateCheckbox: (documentId, nodeId, isChecked) => {
    return apiCall(`${API}/documents/${documentId}/checkboxes/${nodeId}`, "put",
      JSON.stringify({
        isChecked: isChecked
      })
    );
  },
  updateSelectionListNode: (documentId, nodeId, selected) => {
    return apiCall(`${API}/documents/${documentId}/selects/${nodeId}`, "put",
      JSON.stringify({
        selected: selected
      })
    );
  },
  updateDateTime: (documentId, nodeId, datetime) => {
    return apiCall(`${API}/documents/${documentId}/datetimes/${nodeId}`, "put",
      JSON.stringify({
        datetime: datetime
      })
    );
  },
  saveSignature: (documentId, nodeId, signatureData) => {
    return apiCall(`${API}/documents/${documentId}/signatures/${nodeId}`, "put",
      JSON.stringify({
        signature: signatureData
      })
    );
  },
  setSigner: (documentId, nodeId, userId) => {
    return apiCall(`${API}/documents/${documentId}/signatures/${nodeId}/signerUpdate`, "put",
      JSON.stringify({
        signingRightsId: userId
      })
    );
  },
  updateImage(params) {
    const data = {
      title: params.title
    };
    if (params.fullSize !== undefined) {
      data.fullSize = params.fullSize;
    }
  
    return apiCall(`${API}/documents/${params.documentId}/images/${params.nodeId}`,"put", JSON.stringify(data));
  },

  rotateImage(params) {
    const data = { 
      filename: params.imageNode?.filename,
      originalFilename: params.imageNode?.originalFilename,
      rotation: params.imageNode.rotation || 0,
      angle: params.angle
    };
  
    return apiCall(`${API}/documents/${params.documentId}/images/${params.imageNode.id}/rotate`, "put", JSON.stringify(data));
  },

  deleteImage(params) {
    return apiCall(`${API}/documents/${params.documentId}/images/${params.nodeId}`,"delete");
  },

  updateCoverImage(params) {
    return apiCall(`${API}/documents/${params.documentId}`,"put", JSON.stringify(params));
  },

  // Text field related
  askAISuggestion(params) {
    let url = `${API}/documents/${params.documentId}/nodes/${params.nodeId}/askAISuggestion?text=${params.text || ''}`;
    if (params.suggestionType) {
      url += `&suggestionType=${params.suggestionType}`;
    }
    if (params.instructions) {
      url += `&instructions=${params.instructions}`;
    }
    return apiCall(url, "get");
  },

  updateText: (documentId, nodeId, nodeType, text, format) => {
    let type;
    if (nodeType === 'input-textfield') {
      type = "textfields";
    } else if (nodeType === 'input-textarea') {
      type = "textareas";
    } else if (nodeType === 'note') {
      type = "notes";
    }
    if (!type) return; // Go away, nothing to see here

    const url = `${API}/documents/${documentId}/${type}/${nodeId}`;

    let data = {text: text};
    if (format === 'markdown') {
      data.format = format;
    }

    return apiCall(url, "put", JSON.stringify(data));
  },
  addNote: (documentId, nodeId, text, format) => {
    const url = `${API}/documents/${documentId}/nodes/${nodeId}/notes`;

    let data = {text: text};
    if (format === 'markdown') {
      data.format = format;
    }

    return apiCall(url, "post", JSON.stringify(data));
  },
  deleteNote: (documentId, nodeId) => {
    const url = `${API}/documents/${documentId}/notes/${nodeId}`;

    return apiCall(url, "delete", {});
  },

  addTableRow: (documentId, nodeId, values) => {
    const tableRowData = {values: values};

    return apiCall(`${API}/documents/${documentId}/nodes/${nodeId}/rows`,"post", JSON.stringify(tableRowData));
  },
  updateTableRow: (documentId, nodeId, values) => {
    const tableRowData = {values: values};
    
    return apiCall(`${API}/documents/${documentId}/rows/${nodeId}`,"put", JSON.stringify(tableRowData));
  },
  deleteTableRow: (documentId, nodeId) => {
    const url = `${API}/documents/${documentId}/rows/${nodeId}`;

    return apiCall(url, "delete", {});
  },
  getDocumentStatusId: async (documentId, type, formatParam, globalAbortController) => {
    const format = formatParam ? formatParam : null;
  
    const params = new URLSearchParams({
      async: true,
      type,
      ...formatParam && { format },
    });
  
    const timeout = KPConfig.pdfDownloadTimeoutMs;
    const timeoutInSeconds = timeout / 1000;
    const timeoutErrorText = `API call timed out after ${timeoutInSeconds} seconds`;

    let timeoutId;
    const timeoutPromise = new Promise((_, reject) => {
      timeoutId = setTimeout(() => {
        abortGlobalRequest();
        reject(new Error(timeoutErrorText));
      }, timeout);
    });
  
    try {
      const apiCallPromise = apiCall(
        `${KPConfig.backendUrl}/pdf/documents/${documentId}.pdf?${params}`,
        "get",
        null,
        globalAbortController ? globalAbortController.signal : undefined
      );
  
      const result = await Promise.race([apiCallPromise, timeoutPromise]);
      clearTimeout(timeoutId);
      return result;
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.message === "Request aborted") {
        console.error(timeoutErrorText);
      } else {
        console.error(error.message);
      }
      throw error;
    }
  }
  ,

  // This is common for all pdfs. So this is also used by floorPlanSlice.
  getPdfProcessedDownloadId: async (statusId, globalAbortController) => {
    const url = `${KPConfig.backendUrl}/pdf/status/${statusId}`;
    let pdfPollInterval = 3000;

  
    const poll = async (resolve, reject) => {
      const timeoutId = setTimeout(() => abortGlobalRequest, KPConfig.pdfDownloadTimeoutMs);
  
      try {
        const res = await fetch(url, {
          credentials: 'include',
          signal: globalAbortController ? globalAbortController.signal : null,
        });
  
        clearTimeout(timeoutId);
  
        const processedResponse = await processResponseAsync(res);
  
        if (!processedResponse.body?.status) {
          resolve(false);
        } else if (processedResponse.body.status === 'failed') {
          resolve(false);
        } else if (processedResponse.body.status === 'completed') {
          resolve(processedResponse.body.download_id);
        } else {
          setTimeout(poll, pdfPollInterval, resolve, reject);
          // Increase poll interval
          if (pdfPollInterval < 30000) {
            pdfPollInterval += 3000;
          }
        }
      } catch (error) {
        clearTimeout(timeoutId);

        if (error.name === 'AbortError') {
          console.log('Fetch aborted:', error.message);
          resolve("AbortError");
        } else {
          resolve(false);
        }
      }
    };
  
    return new Promise(poll);
  }
  ,
  // This is used by downloadJournalWeekPdf
  downloadDocument: (url, fileName) => {
    fetch(url, {
      credentials: 'include'
    })
      .then((response) => response.blob())
      .then(blob => {

        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', fileName);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200);
      });
  },
  lockDocument: (documentId) => {
    return apiCall(`${API}/documents/${documentId}/lock`, "PUT");
  },
  unlockDocument: (documentId) => {
    return apiCall(`${API}/documents/${documentId}/unlock`, "PUT");
  },
  savePrintSettings: (documentId, printSettings) => {
    return apiCall(`${API}/documents/${documentId}/print_settings`,"put", JSON.stringify(printSettings));
  },
  follow: (objectId) => {
    return apiCall(`${API}/kp_objects/${objectId}/follow`, "POST");
  },
  unfollow: (objectId) => {
    return apiCall(`${API}/kp_objects/${objectId}/follow`, "DELETE");
  },
  fetchFollowers: (objectId) => {
    return apiCall(`${API}/kp_objects/${objectId}/followers`, "GET");
  },
  sendNotification: (documentId, recipients, message, includePdf) => {
    const data = {recipients: recipients, message: message, includePdf: includePdf};

    return apiCall(`${API}/documents/${documentId}/notifications`,"post", JSON.stringify(data));
  },
  sendDocumentPdfLink(documentId, recipientId) {
    return apiCall(`${API}/documents/${documentId}/sharePdfLink`, "post", JSON.stringify({recipientId}));
  }
};

export default documentAPI;
