import { apiCall, API } from '../../utils/APIFunctions';
import { replaceScaleValuesInHtml } from './shapes/ShapeFunctions';
import { decodeHtmlChars, escapeHtml } from '../../utils/HelperFunctions';

export function getMarkings(params) {
  let callPath = '';
  if (params.nodeId) {
    callPath = `${API}/markings/${params.objectId}?nodeId=${params.nodeId}&documentId=${params.documentId}`;
  } else {
    callPath = `${API}/markings/${params.objectId}`;
  }

  return apiCall(callPath, "get");
}

export function getMarking(markingId) {
  return apiCall(`${API}/marking/${markingId}`, "get");
}

export function saveMarking(params) {
  const marking = JSON.parse(JSON.stringify(params.marking));
  /**
   * In old-ui, marking clicks don't work if there is sjx-drag.
   * Remove it here from deep-cloned copy to keep subjx work in real marking svg-object.
   * 
   * NOTE: We set marking.svg_object only to copied object. Not for marking in state.markings.
   */
  if (marking.svg_object_latest) {
    marking.svg_object = marking.svg_object_latest.replaceAll('sjx-drag', '');
  }
  if (marking.databaseId) {
    marking.id = marking.databaseId;
  }

  if (marking.svg_object_type === 'pointer') {
    // Save pointer always with scale-values in zoom-level 1
    marking.svg_object = replaceScaleValuesInHtml(marking.svg_object, marking.scale);
  }

  // Sometimes subjx behaves buggy and there is .sjx-svg-wrapper-element inside .svgDrawing. Make sure it is never saved.
  // Now this should also be fixed in code, but you can never be sure...
  const temp = document.createElement("div");
  temp.innerHTML = decodeHtmlChars(marking.svg_object);
  const sjxElemToRemove = temp.querySelector(".sjx-svg-wrapper");
  if (sjxElemToRemove) {
    sjxElemToRemove.remove();
    marking.svg_object = escapeHtml(temp.innerHTML);
  }


  const data = {
    markings: [marking],
    nodeId: params.nodeId,
    documentId: params.documentId
  };

  return apiCall(`${API}/markings/${params.objectId}`, "post", JSON.stringify(data));
}

export function deleteMarking(params) {
  return apiCall(`${API}/markings/${params.markingId}`, "delete");
}

export function deleteAllMarkings(params) {
  const url = params.nodeId && params.documentId ?
    `${API}/markings/${params.objectId}/all?&nodeId=${params.nodeId}&documentId=${params.documentId}` :
    `${API}/markings/${params.objectId}/all`;

  return apiCall(url, "delete");
}

export function createMarkingContent(params) {
  return apiCall(`${API}/markings/${params.marking_id}/content`, "post", JSON.stringify(params));
}

export function changeMarkingContentParent(params) {
  return apiCall(`${API}/markings/${params.marking_id}/changeparent`, "post", JSON.stringify(params));
}

export function copyMarking(id) {
  return apiCall(`${API}/markings/${id}/copy`, "put");
}

export function getFloorPlanDrawSettings(floorPlanId) {
  return apiCall(`${API}/floor_plans/${floorPlanId}/draw_settings`, "get");
}