import { apiCall, API } from '../../utils/APIFunctions';

export function fetchWebshopPlans() {
  return apiCall(`${API}/webshop-plans`, "GET");
}

export function fetchSubscription(planId) {
  if (planId) {
    return apiCall(`${API}/user/company/subscription/${planId}`, "GET");
  }
  return apiCall(`${API}/user/company/subscription`, "GET");
}

export function getCountryData() {
  return apiCall(`${API}/billing/country_data`, "GET");
}

export function getIPLocation() {
  return apiCall(`${API}/billing/ip_location`, "GET");
}

export function getEInvoiceOperators() {
  return apiCall(`${API}/billing/einvoice_operators`, "GET");
}

export function getBillingDetails() {
  return apiCall(`${API}/billing/details`, "GET");
}

export function attachCardToCustomer(data) {
  return apiCall(`${API}/stripe/customer/attach_payment_method`, "POST", JSON.stringify(data));
}

export function updateBillingMethod(data) {
  return apiCall(`${API}/billing/payment_method`, "POST", JSON.stringify(data));
}

export function saveBillingDetails(data) {
  return apiCall(`${API}/billing/details`, "POST", JSON.stringify(data));
}

export function stripeCardSetupIntentSecret() {
  return apiCall(`${API}/stripe/customer/card_setup_intent_secret`, "GET");
}

export function activateSubscription(params) {
  return apiCall(`${API}/user/company/subscription/activate`, "POST", JSON.stringify({ "plan_identifier": params.planId, "amount": params.amount }));
}

export function fetchPayments() {
  return apiCall(`${API}/billing/payments`, "GET");
}

export function cancelSubscription() {
  return apiCall(`${API}/user/company/subscription/cancel`, "POST");
}

export function getEnterpriseDetails() {
  return apiCall(`${API}/user/company/subscription/enterpriseDetails`, "GET");
}

export function updateEnterprisePlan(data) {
  return apiCall(`${API}/user/company/subscription/enterprisePlan`, "POST", JSON.stringify(data));
}