import { apiCall, API } from '../../utils/APIFunctions';

const metricAPI = {
  getMetric: (id) => {
    if (!id) return {};
    return  apiCall(`${API}/o/metrics/${id}`,"get");
  },
  getMeasurement: (measurementId) => {
    if (!measurementId) return {};
    return apiCall(`${API}/measurements/${measurementId}`,"GET")
  },
  fetchMeasurements: (id) => {
    if (!id) return {};
    return  apiCall(`${API}/o/metrics/${id}/measurements`,"get");
  },
  fetchMetricChoices:(metricType) => {
    if (!metricType) return {};
    return  apiCall(`${API}/metric-choices/${metricType}`,"get");
  },
  fetchMetricGuides:(metricType) => {
    if (!metricType) return {};
    return  apiCall(`${API}/metric-guides/${metricType}`,"get");
  },
  addNewMeasurement: (metricId) => {
    if (!metricId) return {};
    return apiCall(`${API}/measurements?metric_id=${metricId}`,"post");
  },
  updateTargetScore: (measurementId, targetId, pass, fail) => {
    if (measurementId === undefined ||  targetId === undefined || pass < 0 || fail < 0) return {};
    const data = {pass: pass, fail: fail, total_pass: 0, total_fail: 0, total_score: 0};
    return apiCall(`${API}/measurements/${measurementId}/targets/${targetId}/score`,"put", JSON.stringify(data));
  },
  createMetric: (data) => {
    return apiCall(`${API}/o/metrics`, "post", JSON.stringify(data));
  },
  deleteMeasurement: (measurementId) => {
    return apiCall(`${API}/measurements/${measurementId}`,"delete");
  },
  saveNote: async (params) => {

    if (params.image.areNewImages) {

      const uploadedImages = [];

      for (let index = 0; index < params.image.imageFiles.length; index++) {
        const imageFile = params.image.imageFiles[index];

        const image = await fetch(imageFile.url).then(r => r.blob()).then(blobFile => new File([blobFile], imageFile.fileName, { type: imageFile.type }))
            
        URL.revokeObjectURL(imageFile.url); //Revoke url when we don't need it to prevent memory leak

        const imageData = new FormData();
        imageData.append("file", image);
  
        const uploadNoteImageResponse = await apiCall(`${API}/measurements/${params.measurementId}/`,"post", imageData)
        if (uploadNoteImageResponse.isOk && uploadNoteImageResponse.body) {
          uploadedImages.push(uploadNoteImageResponse.body);
        } else {
          throw 'Error uploading image';
        }
      }

      const data = new FormData();
      data.append("images", JSON.stringify(uploadedImages));
      data.append("text", params.text);
      data.append("choice", params.choice);
      data.append("priority", params.priority);
      data.append("responsible_id", params.responsible_id);

      return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes`,"post", data);
      
    } else {
      const data = {text: params.text, choice: params.choice, priority:params.priority,responsible_id:params.responsible_id};
      return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes`,"post", JSON.stringify(data))
    }

  },
  updateNote: async(params) => {

    if (params.image.areNewImages) {
      let images = [];

      for (let index = 0; index < params.image.imageFiles.length; index++) {
        const imageFile = params.image.imageFiles[index];
        if (imageFile.url) {
          const image = await fetch(imageFile.url).then(r => r.blob()).then(blobFile => new File([blobFile], imageFile.fileName, { type: imageFile.type }))
            
          URL.revokeObjectURL(imageFile.url); //Revoke url when we don't need it to prevent memory leak
  
          const imageData = new FormData();
          imageData.append("file", image);
    
          const uploadNoteImageResponse = await apiCall(`${API}/measurements/${params.measurementId}/`,"post", imageData)
  
          if (uploadNoteImageResponse.isOk && uploadNoteImageResponse.body) {
            images.push(uploadNoteImageResponse.body);
          }
          else {
            throw 'Error uploading image';
          }

        } else {
          images.push(imageFile);
        }
      }

      console.log(images);

      const noteData = 
        {
          text: params.text, 
          choice: params.choice, 
          priority:params.priority,
          responsible_id:params.responsible_id,
          images: images
        };
        
      return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes/${params.noteId}`,"put", JSON.stringify(noteData));


    } else {

      const data = 
      {
        text: params.text, 
        choice: params.choice, 
        priority:params.priority,
        responsible_id:params.responsible_id,
        images: params.image.imageFiles
      };
      return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes/${params.noteId}`,"put", JSON.stringify(data));
      
    }
  },
  sendSummary: (params) => {
    return apiCall(`${API}/measurements/${params.measurementId}/emailSummary`,"post", JSON.stringify({users:params.users}));
  },
  markNoteDone: (params) => {
    return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes/${params.noteId}/done`,"post");
  },
  deleteNoteImage: (params) => {
    return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes/${params.noteId}/noteImage`,"delete");
  },
  addComment: (params) => {
    const data = { text: params.text };
    return apiCall(`${API}/measurements/${params.measurementId}/targets/${params.targetId}/notes/${params.noteId}/comments`,"post", JSON.stringify(data));
  },
  markMeasurementCompleted: (measurementId, totalPass, totalFail, score, measurementParticipants, additionalInfo) => {
    if (measurementId === undefined) return {};
    var data = {
      fail: totalFail,
      pass: totalPass,
      score: score,
      completed: true,
      info: { participants: measurementParticipants, additional: additionalInfo}
    };
  
    return apiCall(`${API}/measurements/${measurementId}`,"put", JSON.stringify(data));
  },
};

export default metricAPI;
