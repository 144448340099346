
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMessage: null
};

function showErrorToast(action) {
  return action.type.endsWith('/rejected') &&
         // Show toasts for documents only if ...
         (!action.type.startsWith('document/') ||
         // response code is 423 (email not verified)
         action.error.message === "423" ||
         // or there is a permission problem
         action.error.message === "403" ||
         // or the 'thing' is not found
         action.error.message === "404" ||
         // or there's something wrong when moving images
         action.type.startsWith('document/moveImageToSection') || action.type.startsWith('document/moveImage'));
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearErrorMessage(state) {
      state.errorMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(showErrorToast, (state, action) => {
        state.errorMessage = action;
      });
  },
});

export const {
  clearErrorMessage
} = errorSlice.actions

export const selectErrorMessage = (state) => state.error.errorMessage;

export default errorSlice.reducer;
