import { apiCall, API } from '../../utils/APIFunctions';

export function updateUser(params) {
  return apiCall(`${API}/users/${params.user_id}`,"put", JSON.stringify(params));
}

export function updatePassword(params) {
  return apiCall(`${API}/users/${params.userId}/password`,"put", JSON.stringify(params));
}

export function updateEmail(params) {
  return apiCall(`${API}/users/${params.userId}/updateEmail`,"put", JSON.stringify(params));
}

export async function updateCompany(params) {

  if (params?.logo?.url) {

    const file = await fetch(params.logo.url).then(r => r.blob()).then(blobFile => new File([blobFile], params.logo.fileName, { type: params.logo.type }))
    
    URL.revokeObjectURL(params.logo.url); //Revoke url when we don't need it to prevent memory leak

    const data = new FormData();
    data.append("file", file);

    return apiCall(`${API}/user/company/logo_upload`,"post", data);
  } else {
    return apiCall(`${API}/user/company/logo_upload`,"post", JSON.stringify(params));       
  }
}

export async function deleteCompanyLogo() {
  return apiCall(`${API}/user/company/logo_delete`,"delete");
}
export async function updateSettings(params) {

  let settingsData = params
    
  if (params?.userSignature?.url) {
    settingsData = { ...params, userSignature:{url: params.userSignature.url.split(',')[1]}} //this can be removed?
  }
  return apiCall(`${API}/users/${params.id}/updateUserSettings`,"post", JSON.stringify(settingsData));
}

export async function updateUserSettings(params) {
  return apiCall(`${API}/users/${params.userId}/settings/update`,"post", JSON.stringify(params));
}

export async function updateSignatureData(params) {
  return apiCall(`${API}/users/${params.id}/updateSignatureData/`,"post", JSON.stringify(params));
}

export async function removeUserSignature(params) {
  return apiCall(`${API}/users/${params.id}/user_signature/`,"delete");
}

export async function getApiKeys() {
  return apiCall(`${API}/user/api_keys/`,"get");
}

export async function createApiKey(name) {
  return apiCall(`${API}/user/api_keys/`, 'POST', JSON.stringify({ name: name }));
}

export async function deleteApiKey(id) {
  return apiCall(`${API}/user/api_keys/${id}`, 'DELETE');
}

export async function toggleApiKey(apiKey) {
  if (apiKey.newCheckedValue) {
    return apiCall(`${API}/user/api_keys/${apiKey.id}/enable`, 'POST'); 
  } else {
    return apiCall(`${API}/user/api_keys/${apiKey.id}/disable`, 'POST');
  }
}

export async function getApiKeyScopes(id) {
  return  apiCall(`${API}/user/api_keys/${id}/scopes`, 'GET');
}

export async function saveApiKeyScopes(apiKeyScopes) {
  return apiCall(`${API}/user/api_keys/${apiKeyScopes.api_key_id}/scopes`, 'PUT', JSON.stringify(apiKeyScopes));
}

export function confirmEmailChange(params) {
  return apiCall(`${API}/users/${params.userId}/confirmEmailChange`, "PUT", JSON.stringify(params));
}