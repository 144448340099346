let globalAbortController = null;
let globalAbortControllerById = {}; // Possible to have multiple abortControllers in use at the same time

export const getGlobalAbortController = () => {
  if (!globalAbortController) {
    globalAbortController = new AbortController();
  }
  return globalAbortController;
};

export const abortGlobalRequest = () => {
  if (globalAbortController) {
    globalAbortController.abort();
    globalAbortController = null;
  }
};

export const getGlobalAbortControllerById = (id) => {
  if (!globalAbortControllerById[id]) {
    globalAbortControllerById[id] = new AbortController();
  }
  return globalAbortControllerById[id];
};

export const abortGlobalRequestById = (id) => {
  if (globalAbortControllerById[id]) {
    globalAbortControllerById[id].abort();
    globalAbortControllerById[id] = null;
  }
};