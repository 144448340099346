const Parser = require('expr-eval').Parser;

export const calcField = (columnIndex, row, recursionCount, columns, error) => {
  if (recursionCount === row.length) {
    return;
  }

  const eq = columns?.[columnIndex].eq;
  const re = /\$\d+/g;
  const matches = eq.match(re);

  let newEq = eq;

  for (let i = 0; i < matches.length; i++) {
    const foundIndex = parseInt(matches[i].replace('$', ''), 10);

    if (foundIndex === parseInt(columnIndex, 10)) {
      return NaN;
    }

    let value = NaN;
    let columnHasEq = columns?.[foundIndex]?.eq;

    //If there is a value in the row with given index
    //For example if row is ["Pesu ja maalaus", "1", "10", "", ""] and foundIndex 4, the last value is fetched
    if (row[foundIndex]) {
      let rowValue = columnHasEq ? calcField(foundIndex, row, recursionCount + 1, columns, error) : row[foundIndex];

      if (typeof rowValue === 'number') {
        rowValue = rowValue.toString();
      }

      value = rowValue.replace(',', '.');

      value = parseFloat(value);

      if (!value) value = 0;
    } else {
      if (row[foundIndex] === undefined) {
        error.values.push({ id: "table.columnNotFound", defaultMessage: "Column used in formula was not found." });
      }

      let rowValue = columnHasEq ? calcField(foundIndex, row, recursionCount + 1, columns, error) : 0;

      //rowValue can be undefined if there is an endless recursion
      //For example
      //Row2($1) has eq: $2 * 0.5
      //Row3($2) has eq: $1 * 0.5
      value = rowValue ? rowValue : 0;
    }

    newEq = newEq.replace(matches[i], value);
  }

  return getCalculationResult(newEq);
}

const getCalculationResult = (newEq) => {
  let mathResult;

  try {
    mathResult = Parser.evaluate(newEq);

    //Check if zero division is made
    if (typeof (mathResult) === 'object' || !isFinite(mathResult)) {
      mathResult = NaN;
    }

    if (mathResult) {
      mathResult = +mathResult.toFixed(4);
    }
  } catch (error) {
    mathResult = NaN;
  }

  return mathResult;
}