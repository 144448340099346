import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './elasticsearchAPI';
import { getGlobalAbortControllerById } from '../../utils/abortController';

const initialState = {
  status: 'idle',
  searchTs: 0,
  objects: [],
  q: "",
  from: 0,
  size: 10,
  total: 0,
  currentPage: 1,
  showResults: false,
  searchMade: false,
};

export const searchObjects = createAsyncThunk(
  'objects/searchObjects',
  async (params) => {
    const globalAbortController = getGlobalAbortControllerById('elasticsearchSlice.searchObjects');
    const response = await api.searchObjects(params, globalAbortController);

    return response?.body;
  }
);

export const elasticsearchSlice = createSlice({
  name: 'elasticsearch',
  initialState,
  reducers: {
    invalidateObjects: (state, action) => {
      state.objects = [];
      state.searchMade = false;
      state.from = 0;
      state.total = 0;
      state.currentPage = 0;
      state.showResults = action.payload;
    },
    resetAll: (state) => {
      state.objects = [];
      state.searchMade = false;
      state.from = 0;
      state.total = 0;
      state.currentPage = 0;
      state.showResults = false;
      state.status = 'idle';
      state.q = "";
    },
    setShowResults: (state, action) => {
      state.showResults = action.payload;
    },
    setQ: (state, action) => {
      state.q = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchObjects.pending, (state) => {
        state.status = 'searching';
      })
      .addCase(searchObjects.fulfilled, (state, action) => {
        if (!action?.payload) {
          // Request was aborted
          return;
        }
        state.status = 'idle';

        if (action.payload.fetched_ts < state.searchTs) {
          return;
        }

        state.searchTs = action.payload.fetched_ts;
        state.objects = action.payload.results;
        state.searchMade = true;
        state.total = action.payload.total;
        //state.q = action.meta.arg.q;  // Try to fix appearing search terms from old searches.
        state.from = action.meta.arg.from || 0;
        state.currentPage = action.meta.arg.page || 1;
        state.showResults = true;

      })
      .addCase(searchObjects.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const {
  invalidateObjects,
  setShowResults,
  resetAll,
  setQ,
  setStatus
} = elasticsearchSlice.actions;

export const selectStatus = (state) => state.elasticsearch.status;
export const selectShowResults = (state) => state.elasticsearch.showResults;
export const selectObjects = (state) => state.elasticsearch.objects;
export const selectSearchMade = (state) => state.elasticsearch.searchMade;
export const selectQ = (state) => state.elasticsearch.q;
export const selectFrom = (state) => state.elasticsearch.from;
export const selectSize = (state) => state.elasticsearch.size;
export const selectTotal = (state) => state.elasticsearch.total;
export const selectCurrentPage = (state) => state.elasticsearch.currentPage;

export default elasticsearchSlice.reducer;
