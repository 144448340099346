// Keep saveQueueSavingNodeIds and saveQueueFailedNodeIds in sync with the tasks in saveQueueTasksByNodeId[nodeId].tasksById
const syncStatusNodes = (state, nodeId) => {
  let someTasksSaving = false;
  let someTasksFailed = false;
  let someTasksPermanentlyFailed = false;
  for (const taskId in state?.saveQueueTasksByNodeId[nodeId]?.tasksById) {
    const task = state.saveQueueTasksByNodeId[nodeId].tasksById[taskId];
    if (task?.status?.saving) {
      someTasksSaving = true;
    }
    if (task?.status?.failed) {
      someTasksFailed = true;
    }
    if (task?.status?.permanentlyFailed) {
      someTasksPermanentlyFailed = true;
    }

    if (someTasksSaving && someTasksFailed && someTasksPermanentlyFailed) {
      // No need to iterate more
      break;
    }
  }

  if (someTasksSaving) {
    state.saveQueueSavingNodeIds[nodeId] = true;
  } else if (state.saveQueueSavingNodeIds[nodeId]) {
    delete state.saveQueueSavingNodeIds[nodeId];
  }
  if (someTasksFailed) {
    state.saveQueueFailedNodeIds[nodeId] = true;
  } else if (state.saveQueueFailedNodeIds[nodeId]) {
    delete state.saveQueueFailedNodeIds[nodeId];
  }
  if (someTasksPermanentlyFailed) {
    state.saveQueuePermanentlyFailedNodeIds[nodeId] = true;
  } else if (state.saveQueuePermanentlyFailedNodeIds[nodeId]) {
    delete state.saveQueuePermanentlyFailedNodeIds[nodeId];
  }
}

export default {
  'addToSaveQueue': (state, nodeId, taskId, taskData) => {
    // console.debug("addToSQ "+nodeId+" "+taskId);

    if (Object.keys(state.saveQueueTasksByNodeId).length === 0) {
      // Reset total task counter when new cycle is started
      state.totalSaveQueueTasksInOneCycle = 0;
    }

    if (!state.saveQueueTasksByNodeId[nodeId]) {
      // console.debug("  new node");
      state.saveQueueTasksByNodeId[nodeId] = {
        type: state.nodesById[nodeId].type,
        taskIds: [],
        tasksById: {}
      };
    }

    const t = state.saveQueueTasksByNodeId[nodeId];

    if (!t.tasksById[taskId]) {
      // console.debug("  new task");
      t.tasksById[taskId] = {
        data: {},  // data needed for saving
        status: {} // data for showing saving status related things
      };
      t.taskIds.push(taskId);

      state.totalSaveQueueTasksInOneCycle++;
    }
    t.tasksById[taskId].data = taskData;
  },

  'removeFromSaveQueue': (state, nodeId, taskId, opts={}) => {
    // console.debug("removeFromSQ "+nodeId+" "+taskId);

    if (state.saveQueueTasksByNodeId[nodeId]) {
      // .. taskIds
      state.saveQueueTasksByNodeId[nodeId].taskIds = state.saveQueueTasksByNodeId[nodeId].taskIds.filter(e => e !== taskId);
      // .. tasksById
      if (state.saveQueueTasksByNodeId[nodeId].tasksById[taskId]) {
        delete state.saveQueueTasksByNodeId[nodeId].tasksById[taskId]

        if (opts.discard) {
          // When use discards failed task (image), decrement it from the total count
          state.totalSaveQueueTasksInOneCycle--;
        }
      }
      // .. remove also the whole (save)node, if it has not any tasks left
      if (state.saveQueueTasksByNodeId[nodeId].taskIds.length === 0) {
        // console.debug("  remove whole node");
        delete state.saveQueueTasksByNodeId[nodeId];
      }
    }

    syncStatusNodes(state, nodeId);
  },

  'removeWholeNodeFromSaveQueue': (state, nodeId) => {
    if (state.saveQueueTasksByNodeId[nodeId]) {
      delete state.saveQueueTasksByNodeId[nodeId];
    }

    syncStatusNodes(state, nodeId);
  },

  'updateTaskStatus': (state, nodeId, taskId, status) => {
    // console.debug("updateSQTask "+nodeId+" "+taskId+" s="+JSON.stringify(status));

    if (state?.saveQueueTasksByNodeId?.[nodeId]?.tasksById?.[taskId]) {
      state.saveQueueTasksByNodeId[nodeId].tasksById[taskId].status = {
        ...state.saveQueueTasksByNodeId[nodeId].tasksById[taskId].status,
        ...status
      }
    }

    syncStatusNodes(state, nodeId);
  },

  'clearFailedTaskStatuses': (state, nodeId) => {
    if (!state.saveQueueTasksByNodeId[nodeId]) return;

    for (const taskId in state.saveQueueTasksByNodeId[nodeId].tasksById) {
      const task = state.saveQueueTasksByNodeId[nodeId].tasksById[taskId]
      if (task?.status?.failed) {
        delete task.status.failed;
      }
    }

    syncStatusNodes(state, nodeId);
  }
};
