import { apiCall, API } from '../../utils/APIFunctions';
import KPConfig from '../../KPConfig.js';
import { abortGlobalRequest } from '../../utils/abortController';

export function createFloorPlanFolder(params) {
  let data = new FormData();
  for (let i = 0; i < params.images.length; i++) {
    data.append('files[]', params.images[i]);
  }
  for (let i = 0; i < params.images.length; i++) {
    data.append("floorPlanParams[]", JSON.stringify(params.floorPlanParams[i]));
  }
  data.append("title", params.title);
  data.append("parentId", params.parentId);

  return apiCall(`${API}/floor_plan_folders`, "post", data);
}

export function addFloorPlanPages(params) {
  let data = new FormData();
  for (let i = 0; i < params.images.length; i++) {
    data.append('files[]', params.images[i]);
  }
  for (let i = 0; i < params.images.length; i++) {
    data.append("floorPlanParams[]", JSON.stringify(params.floorPlanParams[i]));
  }
  data.append("parentId", params.parentId);

  return apiCall(`${API}/floor_plan_folders/pages`, "post", data);
}

export function updateFloorPlanImage(params) {
  let data = new FormData();
  data.append('file', params.images[0]);

  if (params.convertQuality) {
    data.append("convertQuality", params.convertQuality);
  }

  return apiCall(`${API}/o/floor_plans/${params.id}/images`, "post", data);
}

export function getFloorPlan(id) {
  return apiCall(`${API}/floor_plans/${id}`, "GET");
}

export function getFloorPlanPages(id) {
  return apiCall(`${API}/floor_plans/${id}/pages?returnSinglePage=1`, "GET");
}

export async function getFloorPlanStatusId(floorPlanId, type, markingFilters, globalAbortController) {
  const params = new URLSearchParams({
    async: true,
    type,
    markingFilters: JSON.stringify(markingFilters)
  });

  const timeout = KPConfig.pdfDownloadTimeoutMs;
  const timeoutInSeconds = timeout / 1000;
  const timeoutErrorText = `API call timed out after ${timeoutInSeconds} seconds`;
  let timeoutId;

  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      abortGlobalRequest();
      reject(new Error(timeoutErrorText));
    }, timeout);
  });

  try {
    const apiCallPromise = apiCall(
      `${KPConfig.backendUrl}/pdf/floor_plans/${floorPlanId}.pdf?${params}`,
      "get",
      null,
      globalAbortController.signal
    );

    const result = await Promise.race([apiCallPromise, timeoutPromise]);
    clearTimeout(timeoutId);

    return result;
  } catch (error) {
    clearTimeout(timeoutId);

    if (error.message === "Request aborted") {
      console.error(timeoutErrorText);
    } else {
      console.error(error.message);
    }
    throw error;
  }
}

export function savePrintSettings(floorPlanId, printSettings) {
  return apiCall(`${API}/floor_plans/${floorPlanId}/print_settings`, "put", JSON.stringify(printSettings));
}

export function rotateImage(objectId, params) {
  return apiCall(`${API}/o/floor_plans/${objectId}/images/rotate`, "put", JSON.stringify(params));
}

export function createPartyFolder(data) {
  return apiCall(`${API}/floor_plans/partyfolders`, "post", JSON.stringify(data));
}

export function saveDrawSettings(floorPlanId, data) {
  return apiCall(`${API}/floor_plans/${floorPlanId}/draw_settings`, "put", JSON.stringify(data));
}