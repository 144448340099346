import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../objectRights/objectRightsAPI';
import * as objectsSlice from '../objects/objectsSlice';
import * as usersSlice from '../users/usersSlice';

const initialState = {
  status: 'idle',
  deleteObjectRightStatuses: {},
  deleteObjectRightStatusesByObjectRightId: {},
  listStatus: 'idle',
  participantsManageModalObjectId: null,
  inviteStatusById: {}, // Use separate statuses to prevent unwanted rerendering everywhere
  objectRightsById: {}, // We may have many objectRights visible on the screen at the same time (floor plan, document)
  objectRightsFetchedTs: {},  // We have probably fetched objectRights recently, check ts before new fetch
  invalidSearchValue: false, // I don't know why we need this
  hasPossibleViewPriceIncrease: false,
  hasPossibleEditPriceIncrease: false,
  hasPriceIncrease: false,
  notifyMessage: "",
  sendNotifyMessage: true,
  objectRightIdsByObjectId: {},
  sendPdfLink: false
};

export const fetchObjectRights = createAsyncThunk(
  'objectRights/fetchObjectRights',
  async (params) => {
    let response = await api.fetchObjectRights(params);
    response.body.objectId = params.objectId;
    return response.body;
  }
);

export const deleteObjectRight = createAsyncThunk(
  'objectRights/deleteObjectRight',
  async (params, { dispatch }) => {
    let response = await api.deleteObjectRight(params);

    if (params.fetchUserObjects) {
      dispatch(usersSlice.getUserObjects(params.userId));
    }
    // We always need to fetch object rights due to a bug:
    // 1. Visit the shared object (e.g., a document named 'Testipohja').
    // 2. Remove the right from UserObjectsModal.
    // 3. Revisit the object. The right will still appear to be present.
    dispatch(fetchObjectRights(params));

    if (params.userId === 'company') {
      dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch()); // Do this to update object.company_rights
    }
    return response.body;
  }
);

export const saveObjectRights = createAsyncThunk(
  'objectRights/saveObjectRights',
  async (params, { dispatch, getState }) => {
    if (getState().objectRights.sendNotifyMessage) {
      params.message = getState().objectRights.notifyMessage;
    }

    if (params.noMessage) {
      delete params.message;
    }

    let response = await api.saveObjectRights(params);

    if (!params.noFetch) {
      dispatch(fetchObjectRights({ objectId: params.objectId }));
      dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());
      dispatch(usersSlice.fetchUsers());
    }

    response.body.objectId = params.objectId;

    return response.body;
  }
);

export const resendObjectRightInvite = createAsyncThunk(
  'objectRights/resendObjectRightsInvite',
  async (params, { dispatch }) => {
    let response = await api.resendObjectRightsInvite(params);
    dispatch(fetchObjectRights({ objectId: params.objectId }));

    return response.body;
  }
);

export const objectRightsSlice = createSlice({
  name: 'objectRights',
  initialState,
  reducers: {
    setParticipantsManageModalObjectId: (state, action) => {
      state.participantsManageModalObjectId = action.payload;
    },
    addObjectRight: (state, action) => {
      if (!state.objectRightsById[action.payload.objectId]) {
        state.objectRightsById[action.payload.objectId] = [];
      }
      state.objectRightsById[action.payload.objectId].push(action.payload);
    },
    removeNewObjectRights: (state, action) => {
      if (state.objectRightsById[action.payload]) {
        state.objectRightsById[action.payload] = state.objectRightsById[action.payload].filter(o => !o.isNew);
      }
    },
    removeNewAndModifiedObjectRights: (state, action) => {
      if (state.objectRightsById[action.payload]) {
        state.objectRightsById[action.payload] = state.objectRightsById[action.payload].filter(o => !o.isNew && !o.isModified);
      }
    },
    changeObjectRightType: (state, action) => {
      state.objectRightsById[action.payload.objectId].forEach((u, i) => {
        if (u.email === action.payload.email) {
          // Copy old values to make cancel possible
          if (
            !state.objectRightsById[action.payload.objectId][i].isModified &&
            !state.objectRightsById[action.payload.objectId][i].isNew
          ) {
            state.objectRightsById[action.payload.objectId][i].valuesInDatabase = { ...state.objectRightsById[action.payload.objectId][i] };
            state.objectRightsById[action.payload.objectId][i].isModified = true;
          }
          state.objectRightsById[action.payload.objectId][i].type = action.payload.value;
        }
      });
    },
    changeObjectRightExpiresTs: (state, action) => {
      state.objectRightsById[action.payload.objectId].forEach((u, i) => {
        if (u.email === action.payload.email) {
          // Copy old values to make cancel possible
          if (
            !state.objectRightsById[action.payload.objectId][i].isModified &&
            !state.objectRightsById[action.payload.objectId][i].isNew
          ) {
            state.objectRightsById[action.payload.objectId][i].valuesInDatabase = { ...state.objectRightsById[action.payload.objectId][i] };
            state.objectRightsById[action.payload.objectId][i].isModified = true;
          }
          state.objectRightsById[action.payload.objectId][i].expires_ts = action.payload.value;
        }
      });
    },
    // NOTE: Langauge is not saved into objectRight, so we dont show it with existing objectRight rows
    changeObjectRightLanguage: (state, action) => {
      state.objectRightsById[action.payload.objectId].forEach((u, i) => {
        if (u.email === action.payload.email) {
          state.objectRightsById[action.payload.objectId][i].language = action.payload.value;
        }
      });
    },
    // I don't know what this is but I guess we need this?
    setInvalidSearchValue: (state, action) => {
      state.invalidSearchValue = action.payload;
    },
    setHasPossibleViewPriceIncrease: (state, action) => {
      state.hasPossibleViewPriceIncrease = action.payload;
    },
    setHasPossibleEditPriceIncrease: (state, action) => {
      state.hasPossibleEditPriceIncrease = action.payload;
    },
    setHasPriceIncrease: (state, action) => {
      state.hasPriceIncrease = action.payload;
    },
    deleteNewObjectRight: (state, action) => {
      state.objectRightsById[action.payload.objectId] = state.objectRightsById[action.payload.objectId].filter(o => o.email !== action.payload.email);
    },
    cancelObjectRightModify: (state, action) => {
      state.objectRightsById[action.payload.objectId].forEach((u, i) => {
        if (u.email === action.payload.email) {
          state.objectRightsById[action.payload.objectId][i] = { ...state.objectRightsById[action.payload.objectId][i].valuesInDatabase };
        }
      });
    },
    copySelectionsToAllObjectRightsUnderModification: (state, action) => {
      const rootParticipant = state.objectRightsById[action.payload.objectId].find(u => u.email === action.payload.email);

      if (rootParticipant) {
        state.objectRightsById[action.payload.objectId].forEach((u, i) => {
          if (u.isNew || u.isModified) {
            state.objectRightsById[action.payload.objectId][i].language = rootParticipant.language;
            state.objectRightsById[action.payload.objectId][i].expires_ts = rootParticipant.expires_ts;
            state.objectRightsById[action.payload.objectId][i].type = rootParticipant.type;
          }
        });
      }
    },
    setNotifyMessage: (state, action) => {
      state.notifyMessage = action.payload;
    },
    setSendNotifyMessage: (state, action) => {
      state.sendNotifyMessage = action.payload;
    },
    setSendPdfLink: (state, action) => {
      state.sendPdfLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchObjectRights.pending, (state) => {
        state.listStatus = 'loading';
      })
      .addCase(fetchObjectRights.fulfilled, (state, action) => {
        state.listStatus = 'idle';
        state.objectRightsById[action.payload.objectId] = action.payload;
        state.objectRightIdsByObjectId[action.payload.objectId] = action.payload.map(or => or.id !== undefined ? or.id : 0);
        state.objectRightsFetchedTs[action.payload.objectId] = new Date().getTime();
      })
      .addCase(fetchObjectRights.rejected, (state) => {
        state.listStatus = 'error';
      })
      .addCase(deleteObjectRight.pending, (state, action) => {
        state.status = 'removing';

        if (action.meta.arg.objectId) {
          state.deleteObjectRightStatuses[action.meta.arg.objectId] = "removing";
        }

        if (action.meta.arg.objectRightId) {
          state.deleteObjectRightStatusesByObjectRightId[action.meta.arg.objectRightId] = "removing";
        }

        if (action.meta.arg.userId === 'company') {
          state.deleteObjectRightStatusesByObjectRightId[action.meta.arg.userId] = "removing";
        }
      })
      .addCase(deleteObjectRight.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.meta.arg.objectId) {
          state.deleteObjectRightStatuses[action.meta.arg.objectId] = "idle";
        }

        if (action.meta.arg.objectRightId) {
          state.deleteObjectRightStatusesByObjectRightId[action.meta.arg.objectRightId] = "idle";
        }

        if (action.meta.arg.userId === 'company') {
          state.deleteObjectRightStatusesByObjectRightId[action.meta.arg.userId] = "idle";
        }
      })
      .addCase(deleteObjectRight.rejected, (state, action) => {
        state.status = 'error';

        if (action.meta.arg.objectId) {
          state.deleteObjectRightStatuses[action.meta.arg.objectId] = "error";
        }

        if (action.meta.arg.objectRightId) {
          state.deleteObjectRightStatusesByObjectRightId[action.meta.arg.objectRightId] = "error";
        }

        if (action.meta.arg.userId === 'company') {
          state.deleteObjectRightStatusesByObjectRightId[action.meta.arg.userId] = "error";
        }
      })
      .addCase(saveObjectRights.pending, (state) => {
        state.status = 'adding';
      })
      .addCase(saveObjectRights.fulfilled, (state, action) => {
        state.status = 'idle';

        state.objectRightsById[action.payload.objectId]?.forEach(o => {
          o.isNew = false;
          o.isModified = false;
        });

        state.notifyMessage = '';
      })
      .addCase(saveObjectRights.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(resendObjectRightInvite.pending, (state, action) => {
        state.inviteStatus = 'resending';
        state.inviteStatusById[action.meta.arg.objectRightId] = 'resending';
      })
      .addCase(resendObjectRightInvite.fulfilled, (state, action) => {
        state.inviteStatusById[action.payload.objectRightId] = 'idle';
      })
      .addCase(resendObjectRightInvite.rejected, (state, action) => {
        state.inviteStatusById[action.meta.arg.objectRightId] = 'error';
      });
  },
});

export const {
  setParticipantsManageModalObjectId,
  addObjectRight,
  deleteNewObjectRight,
  cancelObjectRightModify,
  removeNewObjectRights,
  removeNewAndModifiedObjectRights,
  changeObjectRightType,
  changeObjectRightLanguage,
  changeObjectRightSetExpiresTs,
  changeObjectRightExpiresTs,
  setInvalidSearchValue,
  setHasPossibleViewPriceIncrease,
  setHasPossibleEditPriceIncrease,
  setHasPriceIncrease,
  copySelectionsToAllObjectRightsUnderModification,
  setNotifyMessage,
  setSendNotifyMessage,
  setSendPdfLink,
  addCompanyRightsToObjectRights
} = objectRightsSlice.actions;

export const selectObjectRightsById = (state, objectId) => state.objectRights.objectRightsById[objectId];
export const selectStatus = (state) => state.objectRights.status;
export const selectDeleteObjectRightStatusById = (state, objectId) => {
  return state.objectRights.deleteObjectRightStatuses?.[objectId] ?? null;
}
export const selectDeleteObjectRightStatusByObjectRightId = (state, objectRightId) => {
  return state.objectRights.deleteObjectRightStatusesByObjectRightId?.[objectRightId] ?? null;
}
export const selectListStatus = (state) => state.objectRights.listStatus;
export const selectInviteStatusById = (state, objectRightId) => state.objectRights.inviteStatusById[objectRightId];
export const selectInviteStatus = (state) => state.objectRights.inviteStatus;
export const selectNewOrModifiedObjectRights = (state) => {
  return state.objectRights.objectRightsById[state.objectRights.participantsManageModalObjectId] ?
    state.objectRights.objectRightsById[state.objectRights.participantsManageModalObjectId].filter(o => o.isNew || o.isModified) :
    [];
};
export const selectObjectRightsFetchedTs = (state, objectId) => state.objectRights.objectRightsFetchedTs[objectId] || null;
export const selectInvalidSearchValue = (state) => state.objectRights.invalidSearchValue;
export const selectParticipantsManageModalObjectId = (state) => state.objectRights.participantsManageModalObjectId;
export const selectNotifyMessage = (state) => state.objectRights.notifyMessage;
export const selectSendNotifyMessage = (state) => state.objectRights.sendNotifyMessage;
export const selectObjectRightIdsByObjectId = (state, objectId) => state.objectRights.objectRightIdsByObjectId[objectId] ?? {};
export const selectObjectRight = (state,objectId, index) => {
  return state.objectRights.objectRightsById[objectId][index] ?? null 
}
export const selectSendPdfLink = (state) => state.objectRights.sendPdfLink;

export default objectRightsSlice.reducer;
