import { apiCall, API } from '../../utils/APIFunctions';

export function fetchRecycleBin() {
  return apiCall(`${API}/recycle-bin`, "GET");
}

export function deleteObject(id) {
  if (!id) {
    return false;
  }
  return apiCall(`${API}/recycle-bin/${id}`, "DELETE");
}

export function restoreObject(id) {
  if (!id) {
    return false;
  }
  return apiCall(`${API}/recycle-bin/${id}`, "PUT");
}

export function emptyRecycleBin() {
  return apiCall(`${API}/recycle-bin/emptyRecycleBin`, "DELETE");
}