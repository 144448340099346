// Collapsed values:
//   0 = not collapsed
//   1 = visible, children are not
//   2 = not visible

const collapseNode = (state, node) => {
  for (let childNodeId of node.childNodes) {
    state.nodesById[childNodeId].collapsed = 2;
    collapseNode(state, state.nodesById[childNodeId]);
  }
};

const expandNode = (state, node) => {
  for (let childNodeId of node.childNodes) {
    if (state.nodesById[childNodeId].type === 'section') {
      // Set sections visible, but don't expand nodes inside it
      state.nodesById[childNodeId].collapsed = 1;
    } else {
      // For other types of nodes, expand everything
      state.nodesById[childNodeId].collapsed = 0;
      expandNode(state, state.nodesById[childNodeId]);
    }
  }
}

const isInVisibleMarkingNodeIds = (state, nodeId) => {
  if (!state.visibleMarkingNodeIds?.length) {
    return true;
  }

  if (state.visibleMarkingNodeIds.some(nId => nId === nodeId)) {
    return true;
  }

  let node = state.nodesById?.[nodeId];
  if (node?.parentNodeId) {
    while (node && node?.parentNodeId) {
      if (state.visibleMarkingNodeIds.some(nId => nId === node?.parentNodeId)) {
        return true;
      }

      node = state.nodesById?.[node?.parentNodeId];
    }
  }

  return false;
}

const updateVisibleNodeIds = (state) => {
  state.visibleNodeIds = state.nodeIds.filter(id => state.nodesById[id].collapsed !== 2 && isInVisibleMarkingNodeIds(state, id));

  if (state.visibleNodeIds.length > 0) {
    state.firstVisibleNodeId = state.visibleNodeIds[0];
    state.lastVisibleNodeId = state.visibleNodeIds[state.visibleNodeIds.length-1];
  } else {
    state.firstVisibleNodeId = null;
    state.lastVisibleNodeId = null;
  }
}

export const collapseHelpers = {
  collapseNode: collapseNode,
  expandNode: expandNode,

  updateVisibleNodeIds: updateVisibleNodeIds,

  collapseAllSectionNodes: (state) => {
    for (const nodeId of state.nodeIds) {
      const node = state.nodesById[nodeId];
      if (!node || !node.type) continue;

      const parentNode = state.nodesById[node.parentNodeId]

      if (node.type === 'section' &&              // start collapsing only sections
          (node.parentNodeId === 0 ||             // which are in root level
           ( parentNode?.parentNodeId === 0 &&    // or which kind of are in root level
             parentNode?.type === 'macro-section' // (sections of macro-sections are on 2nd level)
           )
          )
      ) {
        console.debug("collapsing "+node.title+" lvl="+node.nodeLevel+" type="+node.type);
        node.collapsed = 1;
        collapseNode(state, node);
      }
    }
  },
  expandAllSectionNodes: (state) => {
    for (const nodeId of state.nodeIds) {
      const node = state.nodesById[nodeId];
      if (!node || !node.type) continue;

      if (node.collapsed) {
        // Expand everything which was collapsed (in any way (1 or 2))
        node.collapsed = 0;
      }
    }
  }
}
