import { apiCall, API } from '../../utils/APIFunctions';

export function fetchTemplatesByType(type, parentId = 0) {
  let url = '';
  if (parentId) {
    url = `${API}/template-list-by-type/${type}/${parentId}`;
  } else {
    url = `${API}/template-list-by-type/${type}`;
  }

  return apiCall(url, "GET");

}

export function fetchOwnTemplates(opts) {
  let params = [];

  if (opts?.compilationTemplates) {
    params.push("compilationTemplates=" + (opts.compilationTemplates ? "1" : "0"));
  }
  else if (opts?.markingTemplates) {
    params.push("markingTemplates=" + (opts.markingTemplates ? "1" : "0"));
  }

  if (opts?.parentId !== undefined) {
    params.push("parentId=" + opts.parentId)
  }

  let parameterText = "?" + params.join("&");

  return apiCall(`${API}/own_document_templates${parameterText}`, "GET");
}

export function fetchTemplate(documentId) {
  return apiCall(`${API}/document_templates/${documentId}`, "GET");
}

export function fetchAvailableTags() {
  return apiCall(`${API}/document_templates_available_tags`, "GET");
}

export function saveTemplate(documentId, documentTemplate, files, fileParams, pastedNodeData, isRequiredNodesSet) {
  let data = new FormData();
  for (let field in documentTemplate) {
    data.append(field, JSON.stringify(documentTemplate[field]));
  }
  for (let i = 0; i < files?.length; i++) {
    data.append('files[]', files[i]);
    data.append("fileParams[]", JSON.stringify(fileParams[i])); // There is parentNodeId (and index) in here
  }

  if (pastedNodeData) {
    data.append("pastedNodeData", JSON.stringify(pastedNodeData));
  }

  data.append("isRequiredNodesSet", isRequiredNodesSet);

  return apiCall(`${API}/document_templates/${documentId}`, "post", data);
}

export function createTemplate(documentTemplate, copyDocumentId, files, fileParams, pastedNodeData) {
  let data = new FormData();
  for (let field in documentTemplate) {
    data.append(field, JSON.stringify(documentTemplate[field]));
  }

  for (let i = 0; i < files?.length; i++) {
    data.append('files[]', files[i]);
    data.append("fileParams[]", JSON.stringify(fileParams[i])); // There is parentNodeId (and index) in here
  }

  if (pastedNodeData) {
    data.append("pastedNodeData", JSON.stringify(pastedNodeData));
  }

  if (copyDocumentId) {
    data.append("copyDocumentId", copyDocumentId);
  }

  return apiCall(`${API}/document_templates2`, "post", data);
}

export function deleteTemplate(documentId) {
  return apiCall(`${API}/document_templates/${documentId}`, "delete");
}


export function toggleTemplateActive(companyId, documentId) {
  const data = { document_id: documentId, toggle_active: true };

  return apiCall(`${API}/companies/${companyId}/document_templates`, "post", JSON.stringify(data));
}

export function saveTemplateOwner(documentId, companyId) {
  const data = { company_id: companyId };

  return apiCall(`${API}/document_templates/${documentId}/owner`, "put", JSON.stringify(data));
}

export function saveTemplateTags(documentId, tags, globalMode) {
  const data = { tags, globalMode };

  return apiCall(`${API}/document_templates/${documentId}/tags`, "post", JSON.stringify(data));
}

export function savePrintSettings(documentId, printSettings) {
  return apiCall(`${API}/document_templates/${documentId}/print_settings`, "put", JSON.stringify(printSettings));
}