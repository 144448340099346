import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './floorPlanAPI';
import * as objectsSlice from '../objects/objectsSlice';
import * as imageSlice from '../image/imageSlice';
import documentAPI from '../document/documentAPI';
import KPConfig from '../../KPConfig.js'
import { sanitizeFilenameInUrl } from '../../utils/StringSanitizer';
import { getGlobalAbortController } from '../../utils/abortController';

const defaultPrintSettings = {
  printCoverPage: false,
  useMarkingFilters: false,
  printMarkingData: false,
  printIndividualMarkingImages: false,
};

const initialState = {
  status: 'idle',
  pageStatus: 'idle',
  downloadFloorPlanStatus: 'idle',
  imageStatus: 'idle',
  partyFolderStatus: 'idle',
  images: {},
  floorPlan: {},
  selectedStatus: null,
  floorPlanPages: [],
  currentPrintSettings: defaultPrintSettings,
  savePrintSettingsStatus: 'idle',

  autoCreateMarkingContent: false,

  pagesCurrentPage: 0,
  pagesShowAll: false,
  floorPlanPDFUrl: ""
};

export const createFloorPlanFolder = createAsyncThunk(
  'floorPlan/createFloorPlanFolder',
  async (params, { dispatch, getState }) => {
    params.floorPlanParams = Object.values(getState().floorPlan.images);
    const response = await api.createFloorPlanFolder(params);
    dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());
    return response.body;
  }
);

export const addFloorPlanPages = createAsyncThunk(
  'floorPlan/addFloorPlanPages',
  async (params, { dispatch, getState }) => {
    params.floorPlanParams = Object.values(getState().floorPlan.images);
    const response = await api.addFloorPlanPages(params);
    dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());
    dispatch(getFloorPlanPages());
    return response.body;
  }
);

export const updateFloorPlanImage = createAsyncThunk(
  'floorPlan/updateFloorPlanImage',
  async (params, {getState}) => {

    const floorPlanImages = getState().floorPlan.images;

    if (floorPlanImages) {
      const floorPlanValues = Object.values(floorPlanImages);
      if (floorPlanValues.length > 0) {

        if (floorPlanValues[0].convertQuality) {
          params.convertQuality = floorPlanValues[0].convertQuality;
        }
      }
    }

    const response = await api.updateFloorPlanImage(params);
    return response.body;
  }
);

export const getFloorPlan = createAsyncThunk(
  'floorPlan/getFloorPlan',
  async (params, { dispatch }) => {
    const response = await api.getFloorPlan(params.floorPlanId);
    if (params.getMarkings) {
      dispatch(imageSlice.getMarkings({ objectId: response.body.object_id }));
    }
    return response.body;
  }
);

export const getFloorPlanPages = createAsyncThunk(
  'floorPlan/getFloorPlanPages',
  async (params, { getState }) => {
    const response = await api.getFloorPlanPages(params?.floorPlanId || getState().floorPlan.floorPlan?.id);
    return response.body;
  }
);

export const downloadFloorPlan = createAsyncThunk(
  'floorPlan/downloadFloorPlan',
  async (opts, { getState }) => {
    /**
       *  response1 contains status_id in normal pdf case.
       *  It contains download_url in aiddocs-case.
       */


    let url = '';
    const ts = Date.now();
    const formattedDate = new Intl.DateTimeFormat('fi-FI', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(ts).replaceAll('.', '-');

    const documentFormat = "pdf";
    const documentName = sanitizeFilenameInUrl(`${opts.floorPlanId} ${opts.objectTitle} ${formattedDate}.${documentFormat}`);

    const markingFilterParams = {};
    Object.entries(getState().image.markingFilters).forEach(([key, value]) => {
      if (value?.value !== "all") {
        if (value?.value) {
          markingFilterParams[key] = value.value;
        } else {
          markingFilterParams[key] = value;
        }
      }
    });

    const globalAbortController = getGlobalAbortController();

    const response1 = await api.getFloorPlanStatusId(opts.floorPlanId, opts.type, markingFilterParams, globalAbortController);

    if (!response1) {
      return;
    }

    if (response1.statusCode !== 200) {
      throw new Error('Error getting floor plan status');
    }
  
    const { status_id } = response1.body || {};

    if (!status_id) {
      throw new Error('No status ID for PDF floor plan');
    }

    const processedDownloadId = await documentAPI.getPdfProcessedDownloadId(status_id, globalAbortController);

    //We don't want to throw error if user has canceled the download
    if (processedDownloadId === "AbortError") {
      return;
    }

    if (!processedDownloadId) {
      throw new Error('No processedDownloadId for PDF floor plan');
    }

    url = `${KPConfig.backendUrl}/pdf/download/${processedDownloadId}/${documentName}`;
    
    return url;
  }
);

export const savePrintSettings = createAsyncThunk(
  'floorPlan/savePrintSettings',
  async (_opts, { getState }) => {
    const response = await api.savePrintSettings(getState().floorPlan.floorPlan.id, getState().floorPlan.currentPrintSettings);

    return response.body;
  }
);

export const rotateImage = createAsyncThunk(
  'floorPlan/rotateImage',
  async (opts, { getState }) => {
    const response = await api.rotateImage(
      getState().floorPlan.floorPlan.object_id,
      {
        filename: getState().floorPlan.floorPlan.filename,
        originalFilename: getState().floorPlan.floorPlan.original_filename,
        rotation: getState().floorPlan.floorPlan.rotation || 0,
        angle: opts.angle
      }
    );

    return response.body;
  }
);

export const createPartyFolder = createAsyncThunk(
  'floorPlan/createPartyFolder',
  async (params, { dispatch }) => {
    const response = await api.createPartyFolder(params);
    dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());
    return response.body;
  }
);

export const saveDrawSettings = createAsyncThunk(
  'floorPlan/saveDrawSettings',
  async (params, { getState }) => {
    const floorPlanId = getState().floorPlan.floorPlan.id;
    const response = await api.saveDrawSettings(floorPlanId, params);
    return response.body;
  }
);

export const floorPlanSlice = createSlice({
  name: 'floorPlan',
  initialState,
  reducers: {
    resetImages: (state) => {
      state.images = {};
    },
    addToImages: (state, action) => {
      state.images[action.payload.id] = action.payload;
    },
    setImageParam: (state, action) => {
      state.images[action.payload.fileId][action.payload.field] = action.payload.value;
    },
    resetFloorPlan: (state) => {
      state.floorPlan = {};
      state.images = {};
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
    setCurrentPrintSettings(state) {
      state.currentPrintSettings = state.floorPlan.print_settings || defaultPrintSettings;
    },
    setPrintSettingValue(state, action) {
      state.currentPrintSettings[action.payload.key] = action.payload.value;
    },
    setPagesCurrentPage(state, action) {
      state.pagesCurrentPage = action.payload;
    },
    setPagesShowAll(state, action) {
      state.pagesShowAll = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFloorPlanFolder.pending, (state) => {
        state.status = 'creating';
      })
      .addCase(createFloorPlanFolder.fulfilled, (state) => {
        state.status = 'idle';
        state.images = {};
      })
      .addCase(createFloorPlanFolder.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(updateFloorPlanImage.pending, (state) => {
        state.imageStatus = 'saving';
      })
      .addCase(updateFloorPlanImage.fulfilled, (state, action) => {
        state.imageStatus = 'idle';
        state.floorPlan.filename = action.payload.img;
        state.floorPlan.original_filename = null;
        state.floorPlan.rotation = 0;
        state.images = {};
      })
      .addCase(updateFloorPlanImage.rejected, (state) => {
        state.imageStatus = 'error';
      })
      .addCase(getFloorPlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFloorPlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.floorPlan = action.payload;
      })
      .addCase(getFloorPlan.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getFloorPlanPages.pending, (state) => {
        state.pageStatus = 'loading';
      })
      .addCase(getFloorPlanPages.fulfilled, (state, action) => {
        state.pageStatus = 'idle';
        state.floorPlanPages = action.payload.results;
      })
      .addCase(getFloorPlanPages.rejected, (state) => {
        state.pageStatus = 'error';
      })
      // downloadPdf
      .addCase(downloadFloorPlan.pending, (state) => {
        state.downloadFloorPlanStatus = 'loading';
        state.floorPlanPDFUrl = "";
      })
      .addCase(downloadFloorPlan.fulfilled, (state, action) => {
        state.downloadFloorPlanStatus = 'idle';
        state.floorPlanPDFUrl = action.payload;

      })
      .addCase(downloadFloorPlan.rejected, (state) => {
        state.downloadFloorPlanStatus = 'error';
      })
      // printSettings
      .addCase(savePrintSettings.pending, (state) => {
        state.savePrintSettingsStatus = 'loading';
      })
      .addCase(savePrintSettings.fulfilled, (state) => {
        state.savePrintSettingsStatus = 'idle';
        state.floorPlan.print_settings = state.currentPrintSettings;
      })
      .addCase(savePrintSettings.rejected, (state) => {
        state.savePrintSettingsStatus = 'error';
      })
      .addCase(rotateImage.pending, (state) => {
        state.imageStatus = 'rotating';
      })
      .addCase(rotateImage.fulfilled, (state, action) => {
        state.imageStatus = 'idle';
        state.floorPlan.filename = action.payload.filename;
        state.floorPlan.original_filename = action.payload.original_filename;
        state.floorPlan.rotation = action.payload.rotation;
      })
      .addCase(rotateImage.rejected, (state) => {
        state.imageStatus = 'error';
      })
      .addCase(addFloorPlanPages.pending, (state) => {
        state.pageStatus = 'saving';
      })
      .addCase(addFloorPlanPages.fulfilled, (state) => {
        state.pageStatus = 'idle';
        state.images = {};
        // getPages is dispatched, no need to set data here
      })
      .addCase(addFloorPlanPages.rejected, (state) => {
        state.pageStatus = 'error';
      })
      .addCase(createPartyFolder.pending, (state) => {
        state.partyFolderStatus = 'creating';
      })
      .addCase(createPartyFolder.fulfilled, (state) => {
        state.partyFolderStatus = 'idle';
      })
      .addCase(createPartyFolder.rejected, (state) => {
        state.partyFolderStatus = 'error';
      });
  },
});

export const {
  resetImages,
  addToImages,
  setImageParam,
  resetFloorPlan,
  setSelectedStatus,
  // Printing
  setCurrentPrintSettings,
  setPrintSettingValue,
  // Pages paging
  setPagesCurrentPage,
  setPagesShowAll
} = floorPlanSlice.actions;

export const selectStatus = (state) => state.floorPlan.status;
export const selectImageStatus = (state) => state.floorPlan.imageStatus;
export const selectImages = (state) => state.floorPlan.images;
export const selectAllImagesHaveTitle = (state, acceptPdf) => {
  return Object.values(state.floorPlan.images)?.length > 0 && Object.values(state.floorPlan.images)?.every(i => {
    if (i.title.length) {
      return true;
    }
    if (i.type === 'application/pdf' && acceptPdf) {
      return true;
    }
    return false;
  });
}
export const selectFloorPlan = (state) => state.floorPlan.floorPlan;
export const selectSelectedStatus = (state) => state.floorPlan.selectedStatus;
export const selectFloorPlanPages = (state) => state.floorPlan.floorPlanPages;
export const selectDownloadFloorPlanStatus = (state) => state.floorPlan.downloadFloorPlanStatus;
export const selectCurrentPrintSettings = (state) => state.floorPlan.currentPrintSettings;
export const selectSavePrintSettingsStatus = (state) => state.floorPlan.savePrintSettingsStatus;
export const selectPageStatus = (state) => state.floorPlan.pageStatus;
export const selectPartyFolderStatus = (state) => state.floorPlan.partyFolderStatus;
export const selectFloorPlanDrawSettings = (state) => state.floorPlan.floorPlan.draw_settings;
export const selectPagesCurrentPage = (state) => state.floorPlan.pagesCurrentPage;
export const selectPagesShowAll = (state) => state.floorPlan.pagesShowAll;
export const selectPagesPageSize = () => {
  let pageSize = 4;
  if (window.innerWidth >= 390) {
    pageSize = 5;
  }
  if (window.innerWidth >= 1024) {
    pageSize = 6;
  }
  if (window.innerWidth >= 1536) {
    pageSize = 7;
  }

  return pageSize;
}

export const selectFloorPlanPDFUrl = (state) => state.floorPlan.floorPlanPDFUrl;

export default floorPlanSlice.reducer;
