import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './superfoldersAPI';

const initialState = {
  status: 'idle',
  fetchStatus: 'idle',
  fetchTagsStatus: 'idle',
  sfTemplateobjectStatus: {},
  tagStatus: {},
  listOrder: { type: 'latest', order: 'asc' },
  superfolderList: [],
  latestSuperfolderList: [],
  selectedSuperfolder: undefined,

  data: {},
  objectsById: {},
  objectIds: [],
  deleteReady: false,
  saveButtonEnabled: false,
  moveToSuperfolderId: null,
  objectAddOpen: {},
  newSfTemplateobject: {},
  newTag: {},
  tagAddOpen: false,
  copySuperfolderId: null
};

const processObjectsRecursively = (objects, objectsById, level) => {
  Object.values(objects).forEach((object) => {
    objectsById[object.id] = {
      ...object,
      level
    };
    if (object.children) {
      const newLevel = level + 1;
      processObjectsRecursively(object.children, objectsById, newLevel);
    }
  });
};

const processObjects = (state, objects) => {
  let objectsById = {};

  processObjectsRecursively(objects, objectsById, 1);

  state.objectsById = objectsById;
  state.objectIds = Object.keys(objects); // This is top level objects
};


export const fetchSuperfolders = createAsyncThunk(
  'superfolders/fetchSuperfolders',
  async () => {
    const response = await api.fetchSuperfolders();
    return response.body;
  }
);

export const fetchSuperfolder = createAsyncThunk(
  'superfolders/fetchSuperfolder',
  async (superfolderId) => {
    const response = await api.fetchSuperfolder(superfolderId);
    return response.body;
  }
);

export const fetchSuperfolderObjects = createAsyncThunk(
  'superfolders/fetchSuperfolderObjects',
  async (superfolderId) => {
    const response = await api.fetchSuperfolderObjects(superfolderId);
    return response.body;
  }
);

export const fetchSuperfolderTags = createAsyncThunk(
  'superfolders/fetchSuperfolderTags',
  async (superfolderId) => {
    const response = await api.fetchSuperfolderTags(superfolderId);
    return response.body;
  }
);

export const deleteSuperfolder = createAsyncThunk(
  'superfolders/deleteSuperfolder',
  async (superfolderId, { dispatch }) => {
    const response = await api.deleteSuperfolder(superfolderId);

    dispatch(fetchSuperfolders());

    return response.body;
  }
);

export const createSuperfolder = createAsyncThunk(
  'superfolders/createSuperfolder',
  async (_, { dispatch, getState }) => {
    const response = await api.createSuperfolder({ name: getState().superfolders.data.name, copySuperfolderId: getState().superfolders.copySuperfolderId });

    dispatch(fetchSuperfolders());

    return response.body;
  }
);

export const saveSuperfolder = createAsyncThunk(
  'superfolders/saveSuperfolder',
  async (_, { getState }) => {
    const response = await api.saveSuperfolder({ name: getState().superfolders.data.name }, getState().superfolders.data.id);

    return response.body;
  }
);

export const saveSuperfolderItemsOrder = createAsyncThunk(
  'superfolders/saveSuperfolderItemsOrder',
  async (_, { getState }) => {
    const response = await api.saveSuperfolder(
      {
        name: getState().superfolders.data.name,
        items_order: getState().superfolders.data.items_order
      },
      getState().superfolders.data.id
    );

    return response.body;
  }
);

export const addSfTemplateobject = createAsyncThunk(
  'superfolders/addSftemplateobject',
  async (parentId, { dispatch, getState }) => {
    const response = await api.addSfTemplateobject({ ...getState().superfolders.newSfTemplateobject, parent_id: parentId }, getState().superfolders.data.id);

    dispatch(fetchSuperfolderObjects(getState().superfolders.data.id));

    return response.body;
  }
);

export const saveSfTemplateobjectTitle = createAsyncThunk(
  'superfolders/saveSfTemplateobjectTitle',
  async (params, { getState }) => {
    const object = getState().superfolders.objectsById[params.id];
    const response = await api.saveSfTemplateobject(object, getState().superfolders.data.id);

    return response.body
  }
);

export const deleteSfTemplateobject = createAsyncThunk(
  'superfolders/deleteSftemplateobject',
  async (id, { dispatch, getState }) => {
    const response = await api.deleteSfTemplateobject(id, getState().superfolders.data.id);

    dispatch(fetchSuperfolderObjects(getState().superfolders.data.id));

    return response.body;
  }
);

export const toggleTagCheckbox = createAsyncThunk(
  'superfolders/toggleTagCheckbox',
  async (params, { getState }) => {
    const tag = getState().superfolders.tags.find((tag) => tag.id === params.id);
    const response = await api.saveTag(tag, getState().superfolders.data.id);

    return response.body
  }
);

export const saveTagName = createAsyncThunk(
  'superfolders/saveTagName',
  async (params, { getState }) => {
    const tag = getState().superfolders.tags.find((tag) => tag.id === params.id);
    const response = await api.saveTag(tag, getState().superfolders.data.id);

    return response.body
  }
);

export const addTag = createAsyncThunk(
  'superfolders/addTag',
  async (_, { dispatch, getState }) => {
    const response = await api.addTag({ ...getState().superfolders.newTag }, getState().superfolders.data.id);

    dispatch(fetchSuperfolderTags(getState().superfolders.data.id));

    return response.body;
  }
);

export const deleteTag = createAsyncThunk(
  'superfolders/deleteTag',
  async (id, { dispatch, getState }) => {
    const response = await api.deleteTag(id, getState().superfolders.data.id);

    dispatch(fetchSuperfolderTags(getState().superfolders.data.id));

    return response.body;
  }
);

export const superfoldersSlice = createSlice({
  name: 'superfolders',
  initialState,
  reducers: {
    selectSuperfolder: (state, action) => {
      state.selectedSuperfolder = action.payload;
    },
    setSelectedSuperfolderTagValue: (state, action) => {
      state.selectedSuperfolder.tags[action.payload.index].value = action.payload.value;
    },
    setListOrder: (state, action) => {
      state.listOrder = action.payload;
    },
    setName: (state, action) => {
      state.data.name = action.payload;
      state.saveButtonEnabled = true;
    },
    resetSuperfolderEditorData: (state) => {
      state.data = {};
      state.objectIds = [];
      state.objectsById = {};
      state.deleteReady = false;
      state.saveButtonEnabled = false;
    },
    initSuperfolderData(state) {
      if (state.copySuperfolderId) {
        state.data = { name: state.data.name };
      } else {
        state.data = {};
      }
      state.objectIds = [];
      state.objectsById = {};
      state.saveButtonEnabled = true;
    },
    setCopySuperfolderId(state, action) {
      state.copySuperfolderId = action.payload;
      state.saveButtonEnabled = false;
    },
    setObjectAddOpen(state, action) {
      state.objectAddOpen[action.payload.openId] = action.payload.value;
      state.newSfTemplateobject = {};
    },
    setNewSfTemplateobjectValue(state, action) {
      state.newSfTemplateobject[action.payload.key] = action.payload.value;
    },
    setNewTagValue(state, action) {
      state.newTag[action.payload.key] = action.payload.value;
    },
    setTagAddOpen(state, action) {
      state.tagAddOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuperfolders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSuperfolders.fulfilled, (state, action) => {
        let originalFolders = [...action.payload.results];
        let newFolderList = [];
        let newFolders = [];

        if (action.payload?.latest && action.payload.latest.length > 0) {
          let latestFolders = action.payload.latest;

          latestFolders.forEach((folder) => {
            // Find index and content of latest folder
            let pos = originalFolders.findIndex(arr => arr["id"] === folder["id"]);
            let row = originalFolders[pos];

            // Remove latest folder from old array and add to a new array
            originalFolders.splice(pos, 1);
            newFolders.push(row);
          })
        }

        originalFolders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

        // Add remaining folders to the end of new array
        newFolderList = newFolders.concat(originalFolders);

        state.status = 'idle';
        state.superfolderList = action.payload.results;
        state.latestSuperfolderList = newFolderList;
      })
      .addCase(fetchSuperfolders.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchSuperfolder.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(fetchSuperfolder.fulfilled, (state, action) => {
        state.fetchStatus = 'idle';
        state.data = action.payload;

        state.saveButtonEnabled = false;
        state.moveToSuperfolderId = null;
      })
      .addCase(fetchSuperfolder.rejected, (state) => {
        state.fetchStatus = 'error';
      })
      .addCase(fetchSuperfolderObjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSuperfolderObjects.fulfilled, (state, action) => {
        state.status = 'idle';

        processObjects(state, action.payload.results);
      })
      .addCase(fetchSuperfolderObjects.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchSuperfolderTags.pending, (state) => {
        state.fetchTagsStatus = 'loading';
      })
      .addCase(fetchSuperfolderTags.fulfilled, (state, action) => {
        state.fetchTagsStatus = 'idle';
        state.tags = action.payload.results;
      })
      .addCase(fetchSuperfolderTags.rejected, (state) => {
        state.fetchTagsStatus = 'error';
      })
      .addCase(deleteSuperfolder.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(deleteSuperfolder.fulfilled, (state) => {
        state.status = 'idle';
        state.data = {};
        state.objectIds = [];
        state.objectsById = {};
        state.deleteReady = true;
      })
      .addCase(deleteSuperfolder.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createSuperfolder.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(createSuperfolder.fulfilled, (state, action) => {
        state.saveButtonEnabled = false;
        state.copySuperfolderId = null;
        state.moveToSuperfolderId = action.payload.id;
        state.status = 'idle';
      })
      .addCase(createSuperfolder.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(saveSuperfolder.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(saveSuperfolder.fulfilled, (state, action) => {
        state.saveButtonEnabled = false;
        state.status = 'idle';

        const superfolder = state.superfolderList.find(sf => sf.id === state.data.id);
        if (superfolder) {
          superfolder.name = action.payload.name;
        }
      })
      .addCase(saveSuperfolder.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(addSfTemplateobject.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(addSfTemplateobject.fulfilled, (state) => {
        state.status = 'idle';
        state.newSfTemplateobject = {};
        state.objectAddOpen = {};
      })
      .addCase(addSfTemplateobject.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(deleteSfTemplateobject.pending, (state, action) => {
        state.sfTemplateobjectStatus[action.meta.arg] = 'deleting';
      })
      .addCase(deleteSfTemplateobject.fulfilled, (state, action) => {
        state.sfTemplateobjectStatus[action.meta.arg] = 'idle';
      })
      .addCase(deleteSfTemplateobject.rejected, (state, action) => {
        state.sfTemplateobjectStatus[action.meta.arg] = 'error';
      })
      .addCase(toggleTagCheckbox.pending, (state, action) => {
        state.status = 'saving';
        const tag = state.tags.find((tag) => tag.id === action.meta.arg.id);
        tag[action.meta.arg.field] = tag[action.meta.arg.field] ? 0 : 1;

        if (action.meta.arg.field === 'is_titlefield' && tag[action.meta.arg.field]) {
          state.tags.forEach((tag) => {
            if (tag.id !== action.meta.arg.id) {
              tag.is_titlefield = 0;
            }
          });
        }
      })
      .addCase(toggleTagCheckbox.fulfilled, (state, action) => {
        state.status = 'idle';

        const tag = state.tags.find((tag) => tag.id === action.meta.arg.id);

        if (action.meta.arg.field === 'is_titlefield' && tag[action.meta.arg.field]) {
          state.data.titletag_id = action.meta.arg.id;
        }
      })
      .addCase(toggleTagCheckbox.rejected, (state, action) => {
        state.status = 'error';
        const tag = state.tags.find((tag) => tag.id === action.meta.arg.id);
        tag[action.meta.arg.field] = tag[action.meta.arg.field] ? 0 : 1;

        if (action.meta.arg.field === 'is_titlefield') {
          const titleTag = state.tags.find((tag) => tag.id === state.data.titletag_id);
          titleTag.is_titlefield = 1;
        }
      })
      .addCase(saveTagName.pending, (state, action) => {
        state.status = 'saving';
        const tag = state.tags.find((tag) => tag.id === action.meta.arg.id);
        tag.name = action.meta.arg.name;
      })
      .addCase(saveTagName.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(saveTagName.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(addTag.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(addTag.fulfilled, (state) => {
        state.status = 'idle';
        state.newTag = {};
      })
      .addCase(addTag.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(deleteTag.pending, (state, action) => {
        state.tagStatus[action.meta.arg] = 'deleting';
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        state.tagStatus[action.meta.arg] = 'idle';
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.tagStatus[action.meta.arg] = 'error';
      })
      .addCase(saveSuperfolderItemsOrder.pending, (state, action) => {
        state.status = 'saving';
        state.data.items_order = action.meta.arg;
      })
      .addCase(saveSuperfolderItemsOrder.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(saveSuperfolderItemsOrder.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(saveSfTemplateobjectTitle.pending, (state, action) => {
        state.status = 'saving';
        const object = state.objectsById[action.meta.arg.id];
        object.title = action.meta.arg.title;
      })
      .addCase(saveSfTemplateobjectTitle.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(saveSfTemplateobjectTitle.rejected, (state) => {
        state.status = 'error';
      })
  },
});

export const {
  selectSuperfolder,
  setSelectedSuperfolderTagValue,
  setListOrder,
  setName,
  resetSuperfolderEditorData,
  initSuperfolderData,
  setCopySuperfolderId,
  setObjectAddOpen,
  setNewSfTemplateobjectValue,
  setNewTagValue,
  setTagAddOpen
} = superfoldersSlice.actions;

export const selectListOrder = (state) => state.superfolders.listOrder;

export const selectHasEditRights = (state) => {
  return state.superfolders.company_id === state.auth.data?.company_id || !state.superfolders.id;
}

export const selectStatus = (state) => state.superfolders.status;
export const selectFetchStatus = (state) => state.superfolders.fetchStatus;
export const selectFetchTagsStatus = (state) => state.superfolders.fetchTagsStatus;
export const selectSfTemplateobjectStatus = (state, objectId) => state.superfolders.sfTemplateobjectStatus[objectId] || 'idle';
export const selectTagStatus = (state, tagId) => state.superfolders.tagStatus[tagId] || 'idle';
export const selectName = (state) => state.superfolders.data.name || "";
export const selectObjectIds = (state) => state.superfolders.objectIds;
export const selectObject = (state, objectId) => state.superfolders.objectsById[objectId];
export const selectDeleteReady = (state) => state.superfolders.deleteReady;
export const selectSaveButtonEnabled = (state) => state.superfolders.saveButtonEnabled;
export const selectMoveToSuperfolderId = (state) => state.superfolders.moveToSuperfolderId;
export const selectObjectAddOpen = (state, openId) => state.superfolders.objectAddOpen[openId];
export const selectNewSfTemplateobject = (state) => state.superfolders.newSfTemplateobject;
export const selectTags = (state) => state.superfolders.tags;
export const selectNewTag = (state) => state.superfolders.newTag;
export const selectTagAddOpen = (state) => state.superfolders.tagAddOpen;
export const selectItemsOrder = (state) => state.superfolders.data?.items_order;
export const selectSuperfolders = (state) => state.superfolders.superfolderList;
export const selectObjectValue = (state, objectId, value) => state.superfolders.objectsById[objectId]?.[value];
export const selectCopySuperfolderId = (state) => state.superfolders.copySuperfolderId;

export const selectCopySuperfolderName = (state) => {
  const superfolder = state.superfolders.superfolderList.find(s => Number(s.id) === Number(state.superfolders.copySuperfolderId));

  return superfolder?.name || "";
}

export default superfoldersSlice.reducer;
