import { apiCall, API } from '../../utils/APIFunctions';

export function fetchTemplatesByType(type, parentId = 0) {
  let url = '';
  if (parentId) {
    url = `${API}/template-list-by-type/${type}/${parentId}`;
  } else {
    url = `${API}/template-list-by-type/${type}`;
  }

  return apiCall(url, "GET");
  
}
