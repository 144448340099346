import { apiCall, API } from '../../utils/APIFunctions';

export function reviewDocument(documentId) {
  return apiCall(`${API}/documents/${documentId}/review`, "PUT");
}

export function fetchDocumentReview(documentId) {
  return apiCall(`${API}/documents/${documentId}/review`, "GET");
}

export function updateTimeline(documentId, reviewId) {
  return apiCall(`${API}/documents/${documentId}/review/${reviewId}/timeline`, "PUT");
}