import { orderBy } from 'lodash';

export const defaultNodeTerm = {
  labelText: "",
  valueText: "",
  valueOperator: null
};

export const sortObjects = (state, sorting) => {
  const field = Object.keys(sorting)[0];
  const order = sorting[field];
  const sorter = item => item[field];
  const objects = Object.values(state.objectsById);

  const orderedObjects = orderBy(objects, sorter, order);
  state.objectIds = orderedObjects.map(o => o.id);
};

export const nodeTermIsValid = (nodeTerm) => {
  if (!nodeTerm) {
    return false;
  }

  if (nodeTerm.type === "section") {
    return (
      nodeTerm.labelText ||
      nodeTerm.searchGroups[0].searchGroups.some(searchGroup => searchGroup.nodeTerms.some(nodeTerm => nodeTermIsValid(nodeTerm)))
    );
  }

  return nodeTerm.labelText;
}

export const setEditModeAttributes = (nodeTerms, value) => {
  return nodeTerms?.map(nodeTerm => {
    return {
      ...nodeTerm,
      editMode: value,
    }
  });
}