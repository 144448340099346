import { apiCall, API } from '../../utils/APIFunctions';

export function get() {
  return apiCall(`${API}/user/company`, "GET");
}

// Damn, I have no idea why this is done so complicated. But do it like this to keep this compatible with old BE code.
// Look accountApi.js: updateCompany(). These functions are similar.
export async function createCompanyAltInfo(params) {
  if (params?.logo?.url) {
    const file = await fetch(params.logo.url).then(r => r.blob()).then(blobFile => new File([blobFile], params.logo.fileName, { type: params.logo.type }))

    URL.revokeObjectURL(params.logo.url); // Revoke url when we don't need it to prevent memory leak

    const data = new FormData();
    data.append("file", file);
    data.append("company_name", params.company_name);
    data.append("company_info", params.company_info);

    return apiCall(`${API}/user/company/alt_infos`, "post", data);
  } else {
    return apiCall(`${API}/user/company/alt_infos`, "post", JSON.stringify(params));
  }
}

export function deleteCompanyAltInfo(altInfoId) {
  return apiCall(`${API}/user/company/alt_infos/${altInfoId}`, "DELETE");
}