import 'resize-observer-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

import './index.css';
import 'react-day-picker/dist/style.css';
import 'leaflet/dist/leaflet.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'tippy.js/dist/tippy.css';
import 'tw-elements';
import { store } from './app/store';
import { folderObjectIdPath, objectIdPaths } from './app/paths';
import { matchPath } from "react-router";
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import * as objectsSlice from './features/objects/objectsSlice';
import * as authSlice from './features/auth/authSlice';
import * as companySlice from './features/company/companySlice';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://5ea781861ac443b1b4a15cc8d5ac2a3b@o70975.ingest.sentry.io/6503992",
    integrations: [
      new BrowserTracing({
        beforeNavigate: context => {
          return {
            ...context,
            // You could use your UI's routing library to find the matching
            // route template here. We don't have one right now, so do some basic
            // parameter replacements.
            name: window.location.pathname
              .replace(/\d+/g, "<digits>")
              .replace(/[a-f0-9]{32}/g, "<hash>"),
          };
        },
      }),
    ],
    tracesSampleRate: 0.02,
  });
}

start();

// Render the React application to the DOM
function start() {
  let folderObjectId = 0;
  let currentObjectId;

  const folderObjectIdMatch = matchPath({ path: folderObjectIdPath }, window.location.pathname);
  let objectIdMatch = null;
  objectIdPaths.find(p => {
    objectIdMatch = matchPath({ path: p }, window.location.pathname);
    return objectIdMatch;
  });

  let baseName = 'start';
  const isSignup = matchPath({ path: "/start/" }, window.location.pathname);
  const isInvitation = matchPath({ path: "/invitation" }, window.location.pathname);
  const isPasswordReset = matchPath({ path: "/passwordReset" }, window.location.pathname);
  const isPdfShare = matchPath({ path: "/pdfShare" }, window.location.pathname);
  const isLogin =
    matchPath({ path: "/login" }, window.location.pathname) ||
    matchPath({ path: "/login/password-reset" }, window.location.pathname);

  if (isLogin) {
    baseName = 'login';
  } else if (isInvitation) {
    baseName = 'invitation';
  } else if (isPasswordReset) {
    // Password reset with a code
    baseName = 'passwordReset';
  } else if (isPdfShare) {
    baseName = 'pdfShare';
  }

  if (folderObjectIdMatch && folderObjectIdMatch.params) {
    const p = folderObjectIdMatch.params;
    if (p.folderObjectId) {
      folderObjectId = p.folderObjectId;
    }
  }

  if (objectIdMatch && objectIdMatch.params) {
    const p = objectIdMatch.params;
    if (p.objectId) {
      currentObjectId = p.objectId;
    }
  }
  console.debug("folderObjectId=" + folderObjectId + ", currentObjectId=" + currentObjectId);

  const skipFetch = isSignup || isLogin || isInvitation || isPasswordReset || isPdfShare;

  /**
   * Promise.all seems to work even with createAsyncThunk. Not sure why.
   * So .then() after it is really loaded when all "promises" are finished.
   */
  Promise.all([
    import('./Root'),
    import('./SignupRoot'),
    store.dispatch(authSlice.getAuth({ skipFetch })),
    store.dispatch(companySlice.getUserCompany({ skipFetch })),
    store.dispatch(objectsSlice.fetchObjects({ partOne: folderObjectId, currentObjectId: currentObjectId, skipFetch }))
  ])
    .then(([{ default: Root }, { default: SignupRoot }]) => {
      store.dispatch(objectsSlice.fetchObjects({ skipFetch }));

      const root = createRoot(document.getElementById('root'));

      if (isSignup || isLogin || isInvitation || isPasswordReset || isPdfShare) {

        root.render(
          <Provider store={store}>
            <SignupRoot baseName={baseName} />
          </Provider>
        );
      } else {
        root.render(
          <Provider store={store}>
            <Root />
          </Provider>
        );
      }

      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA

      // NOTE: I have no idea should we use this. -Joona, 20.1.22
      // UPDATE 6.10.22: ServiceWorker is needed to make this installable PWA
      serviceWorkerRegistration.register();
    });
}
